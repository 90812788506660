// import React, { useState } from 'react';
// import {
//   AppBar,
//   Box,
//   Typography,
//   TextField,
//   Autocomplete,
//   Popper,
//   styled,
//   Button,
//   Divider as MuiDivider,
// } from '@mui/material';
// import {
//   DashboardHeaderContainer,
//   FlexBox,
//   Dot,
//   SearchBox,
// } from "./DashboardHeader.style";
// import LoginModal from './LoginModal';

// const Divider = styled(MuiDivider)({
//   height: '24px',
//   alignSelf: 'center',
//   backgroundColor: '#A2A2A2',
// });

// const Header = () => {
//   const locationSuggestions = [
//     'Bangalore', 'Bannerghatta Road, Bangalore', 'Banashankari 3rd Stage, Bangalore',
//     'Banashankari, Bangalore', 'Basaveshwaranagar, Bangalore', 'BTM Layout, Bangalore',
//     'Bellandur, Bangalore', 'Brigade Road, Bangalore','Bangalore', 'Bannerghatta Road, Bangalore', 'Banashankari 3rd Stage, Bangalore',
//     'Banashankari, Bangalore', 'Basaveshwaranagar, Bangalore', 'BTM Layout, Bangalore',
//     'Bellandur, Bangalore', 'Brigade Road, Bangalore',
//   ];

//   const serviceSuggestions = [
//     'Electrician', 'Plumber', 'House Cleaning', 'Carpenter', 'Gardening',
//     'Pest Control', 'Home Repair', 'Beauty Services', 'AC Repair',
//   ];

//   const CustomPopper = styled(Popper)({
//     width: '350px !important',
//     zIndex: 1300,
//     overflowY: 'auto',
//     padding: '20px',
//     whiteSpace: 'nowrap',
//     scrollbarWidth: 'none',
//     '&::-webkit-scrollbar': {
//       display: 'none',
//     },
//   });

//   const [location, setLocation] = useState('');
//   const [service, setService] = useState('');
//   const [isDialogOpen, setDialogOpen] = useState(false);

//   const handleLocationChange = (event, newValue) => {
//     setLocation(newValue);
//   };

//   const handleServiceChange = (event, newValue) => {
//     setService(newValue);
//   };

//   const handleLoginClick = () => {
//     setDialogOpen(true);
//   };

//   const handleCloseDialog = () => {
//     setDialogOpen(false);
//   };

//   return (
//     <DashboardHeaderContainer>
//       <FlexBox sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
//         <Box
//           component="img"
//           src="/./assets/images/logo.jpg"
//           alt="Local Pro"
//           width={122}
//           height={58}
//           sx={{ objectFit: "contain" }}
//         />

//         {/* Search Box Section */}
//         <SearchBox sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, maxWidth: '600px', mx: 2 }}>
//           {/* Dot */}
//           <Dot sx={{ width: '8px', height: '8px', backgroundColor: '#A2A2A2', borderRadius: '50%' }} />

//           {/* Location Autocomplete */}
//           <Autocomplete
//             freeSolo
//             options={locationSuggestions}
//             value={location}
//             onChange={handleLocationChange}
//             PopperComponent={(props) => <CustomPopper {...props} />}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 variant="outlined"
//                 placeholder="Bangalore"
//                 size="small"
//                 sx={{
//                   width: '150px',
//                   '& .MuiOutlinedInput-root': {
//                     borderRadius: 0,
//                     '& fieldset': {
//                       borderColor: 'transparent',
//                     },
//                     '&:hover fieldset': {
//                       borderColor: 'transparent',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: 'transparent',
//                     },
//                   },
//                 }}
//               />
//             )}
//           />

//           {/* Divider */}
//           <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

//           {/* Second Dot */}
//           <Dot sx={{ width: '8px', height: '8px', backgroundColor: '#A2A2A2', borderRadius: '50%' }} />

//           {/* Services Autocomplete */}
//           <Autocomplete
//             freeSolo
//             options={serviceSuggestions}
//             value={service}
//             onChange={handleServiceChange}
//             PopperComponent={(props) => <CustomPopper {...props} />}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 variant="outlined"
//                 placeholder="Search for Local Pro"
//                 size="small"
//                 sx={{
//                   width: '250px',
//                   '& .MuiOutlinedInput-root': {
//                     borderRadius: 0,
//                     '& fieldset': {
//                       borderColor: 'transparent',
//                     },
//                     '&:hover fieldset': {
//                       borderColor: 'transparent',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: 'transparent',
//                     },
//                   },
//                 }}
//               />
//             )}
//           />
//         </SearchBox>

//         {/* Login Button and Modal */}
//         <FlexBox sx={{ alignItems: 'center', marginLeft: 2 }}>
//           <Button variant="contained" color="primary" onClick={handleLoginClick}>
//             Login / Sign Up
//           </Button>
//           <LoginModal open={isDialogOpen} onClose={handleCloseDialog} />
//         </FlexBox>
//       </FlexBox>
//     </DashboardHeaderContainer>
//   );
// };

// export default Header;
import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Autocomplete,
  Popper,
  styled,
  Button,
  Divider as MuiDivider,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  InputAdornment,
  ListSubheader,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import BookIcon from "@mui/icons-material/Book";
import SearchIcon from "@mui/icons-material/Search";
import { getCategoryDataApi } from "../../../services/api_calls/auth/AuthApi";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import axios from "axios";
import {
  DashboardHeaderContainer,
  FlexBox,
  Dot,
  SearchBox,
} from "./DashboardHeader.style";
import LoginDialog from "./LoginModal";
import { createUrlSlug } from "../../../utils/urlUtils";
import { getCityDataApi } from "../../../services/api_calls/auth/AuthApi";
// import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
// import PlumbingIcon from "@mui/icons-material/Plumbing";
// import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
// import CarpenterIcon from "@mui/icons-material/Build";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
// import GardeningIcon from "@mui/icons-material/LocalFlorist";
// import PestControlIcon from "@mui/icons-material/BugReport";
// import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
// import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
// import BeautyIcon from "@mui/icons-material/Face";
// import AcUnitIcon from "@mui/icons-material/AcUnit";
// import ConstructionIcon from "@mui/icons-material/Construction";
import { useNavigate, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import LocationSearchButton from "./locationSearchButton";
// Custom divider styling
const Divider = styled(MuiDivider)({
  height: "24px",
  alignSelf: "center",
  backgroundColor: "#A2A2A2",
});

const LocationPopper = styled(Popper)(({ width }) => ({
  width: "400px !important",
  minWidth: "400px !important",
  maxWidth: "500px !important",
  padding: "80px 0 0 0",
  maxHeight: "300px",
  overflowY: "auto",
  zIndex: 1300,
  // padding: '0px',
  borderRadius: "8px",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  transform: "translateX(463px)!important",
  // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
}));
const ServicePopper = styled(Popper)({
  width: "300px",
  maxHeight: "300px",
  overflowY: "auto",
  zIndex: 1300,
  borderRadius: "8px",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
});
const CustomButton = styled(Button)({
  backgroundColor: "#226090",
  "&:hover": {
    backgroundColor: "#226090",
  },
});
// // Styled input for location and service search fields
// const StyledTextField = styled(TextField)({
//   width: "200px",
//   "& .MuiOutlinedInput-root": {
//     borderRadius: "25px", // Rounded corners
//     backgroundColor: "#fff",
//     borderColor: "#ccc",
//     boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
//     "& fieldset": {
//       borderColor: "#ddd",
//     },
//     "&:hover fieldset": {
//       borderColor: "#bbb",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#226090",
//     },
//   },
// });
const Header = () => {
  const { citySlug } = useParams();
  const locationInputRef = useRef(null);
  const serviceInputRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));
  const locationSuggestions = cities.map((city) => ({
    label: city,
    type: "city",
  }));
  const [location, setLocation] = useState(() => {
    // Initialize location from localStorage or empty string
    return localStorage.getItem("selectedCity") || "";
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuLoading, setIsMenuLoading] = useState({
    profile: false,
    bookings: false,
    logout: false,
  });
  const [detectedLocation, setDetectedLocation] = useState("Detect Location"); // Initial state for "Detect Location"
  const [isDetecting, setIsDetecting] = useState(false); // New state for loading indicator
  const [service, setService] = useState("");
  const [recentSearches, setRecentSearches] = useState(() => {
    const stored = localStorage.getItem("recentLocations");
    return stored ? JSON.parse(stored) : [];
  });
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isAutocompleteOpen, setAutocompleteOpen] = useState(false); // Control dropdown visibility
  const [locationRecentSearches, setLocationRecentSearches] = useState([
    "Bangalore City College Kalyan Nagar",
    "KalasiPalayam",
  ]);
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem("authToken")
  );
  const [menuAnchor, setMenuAnchor] = useState(null); // Anchor for the menu
  const navigate = useNavigate();
  useEffect(() => {
    const storedRecentSearches = JSON.parse(
      localStorage.getItem("recentLocations")
    );
    if (storedRecentSearches) {
      setRecentSearches(storedRecentSearches);
    }
  }, []);
  // Add event listener for city changes
  useEffect(() => {
    const handleCityChange = (event) => {
      const { city } = event.detail;
      setLocation(city);
      localStorage.setItem("selectedCity", city);
    };
  
    window.addEventListener("cityChanged", handleCityChange);
    return () => window.removeEventListener("cityChanged", handleCityChange);
  }, []);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategoryDataApi();
        if (response && response.categories) {
          const subservices = response.categories.flatMap((category) =>
            category.subCategory.map((sub) => ({
              label: sub.name,
              type: "subservice",
            }))
          );
          setServiceOptions(subservices);
          setCategories(subservices);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await getCityDataApi();
        if (response?.cities) {
          // Transform city data to match Autocomplete requirements
          const formattedCities = response.cities.map((city) => ({
            label: city.name,
            id: city.id,
            type: "city",
          }));
          setCities(formattedCities);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };
    fetchCities();
  }, []);
  useEffect(() => {
    if (citySlug && cities.length > 0) {
      // Only proceed if we have cities data
      const cityName = cities.find((city) => {
        // Add null check before calling createUrlSlug
        if (!city || !city.name) return false;
        return createUrlSlug(city.name) === citySlug;
      })?.name;

      if (cityName) {
        setLocation(cityName);
        localStorage.setItem("selectedCity", cityName);
      }
    }
  }, [citySlug, cities]);
  const handleServiceChange = (event, newValue) => {
    if (newValue && newValue.type === "subservice") {
      const serviceSlug = createUrlSlug(newValue.label);
      const citySlug = location ? createUrlSlug(location) : '';
      if (citySlug) {
        navigate(`/${citySlug}/${serviceSlug}`, {
          state: {
            selectedService: newValue.label,
            selectedServiceId: newValue.id,
            selectedLocation: location
          },
        });
      } else {
        setAutocompleteOpen(true);
        locationInputRef.current?.focus();
      }
    }
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (!value) {
      setServiceOptions(categories);
    } else {
      const filteredOptions = categories.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase())
      );
      setServiceOptions(filteredOptions);
    }
  };
  const handleLocationChange = (event, newValue) => {
    if (!newValue) return;

    if (typeof newValue === "string") {
      setLocation(newValue);
      localStorage.setItem("selectedCity", newValue);
      navigateToCity(newValue);
    } else if (newValue.type === "city") {
      setLocation(newValue.label);
      localStorage.setItem("selectedCity", newValue.label);
      navigateToCity(newValue.label);
      updateRecentSearches(newValue.label);
    }
  };
  const createSafeUrlSlug = (str) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .replace(/[^\w\s-]/g, "")
      .replace(/\s+/g, "")
      .replace(/-+/g, "");
  };
  const navigateToCity = (cityName) => {
    if (!cityName) return;

    const citySlug = createSafeUrlSlug(cityName);
    localStorage.setItem("selectedCity", cityName);

    // Dispatch event to notify other components
    const event = new CustomEvent("cityChanged", {
      detail: { city: cityName, citySlug },
    });
    window.dispatchEvent(event);

    const pathSegments = window.location.pathname.split("/").filter(Boolean);
    if (pathSegments.length > 1) {
      const serviceSlug = pathSegments[1];
      navigate(`/${citySlug}/${serviceSlug}`);
    } else {
      navigate(`/${citySlug}`);
    }
  };
  const updateRecentSearches = (cityName) => {
    const updated = [
      cityName,
      ...recentSearches.filter((item) => item !== cityName),
    ].slice(0, 5);
    setRecentSearches(updated);
    localStorage.setItem("recentLocations", JSON.stringify(updated));
  };

  // // Handle service changes
  // const handleServiceChange = (event, newValue) => {
  //   setService(newValue);
  // };

  // Handle login modal open/close

  const handleLoginClick = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleClearLocationSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem("recentLocations");
  };

  const filterOptions = (options, state) => {
    return options.filter((option) =>
      option.label.toLowerCase().startsWith(state.inputValue.toLowerCase())
    );
  };

  // Function to fetch the location using IP-based or geolocation
  const fetchLocation = async () => {
    setIsDetecting(true);
    try {
      const response = await axios.get("http://ip-api.com/json");
      if (response.data.city) {
        const detectedCity = response.data.city;
        setLocation(detectedCity);
        navigateToCity(detectedCity);
        updateRecentSearches(detectedCity);
      }
    } catch (error) {
      console.error("Error detecting location:", error);
    } finally {
      setIsDetecting(false);
      setAutocompleteOpen(false);
    }
  };
  const getOptions = () => {
    return [
      {
        label: isDetecting ? "Detecting Location..." : "Detect Location",
        type: "detect",
      },
      ...recentSearches.map((city) => ({ label: city, type: "recent" })),
      ...cities,
    ];
  };

  // Handle clear recent searches
  const handleClearRecentSearches = () => {
    setRecentSearches([]); // Clear the recent searches
  };

  const handleLoginSuccess = (token) => {
    // console.log("Login Success - Token:", token);
    if (token) {
      setIsLoading(true);
      localStorage.setItem("authToken", token);
      setAuthToken(token);
      setIsLoggedIn(true);
      setDialogOpen(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem("authToken");
      setAuthToken(token);
      setIsLoggedIn(!!token);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("token");
    setAuthToken(null);
    setIsLoggedIn(false);
    setMenuAnchor(null);
    setIsMenuLoading((prevState) => ({ ...prevState, logout: false }));
    navigate("/");
  };
  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleCompleteRegistration = () => {
    // Simulate login completion and close modal
    setIsLoggedIn(true);
    setDialogOpen(false);
  };
  const handleProfileClick = () => {
    setIsMenuLoading((prevState) => ({ ...prevState, profile: true }));
    navigate("/profile");
    setIsMenuLoading((prevState) => ({ ...prevState, profile: false }));
  };

  const handleBookingsClick = () => {
    setIsMenuLoading((prevState) => ({ ...prevState, bookings: true }));
    navigate("/bookings");
    setIsMenuLoading((prevState) => ({ ...prevState, bookings: false }));
  };

  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation);
    localStorage.setItem("selectedCity", selectedLocation);
  };
  return (
    <DashboardHeaderContainer>
      <FlexBox sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Box
            component="img"
            src="/./assets/images/logo.jpg"
            alt="Local Pro"
            width={122}
            height={58}
            sx={{ objectFit: "contain" }}
          />
        </Link>

        {/* Search Box Section */}
        <SearchBox>
          <LocationSearchButton
            location={location}
            onLocationSelect={handleLocationSelect}
          />
          {/* Divider */}
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

          <Autocomplete
            freeSolo
            options={serviceOptions}
            getOptionLabel={(option) => option.label}
            onChange={handleServiceChange}
            onInputChange={handleInputChange}
            PopperComponent={(props) => (
              <ServicePopper
                {...props}
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, 15],
                    },
                  },
                ]}
                placement="bottom-start"
              />
            )}
            renderOption={(props, option) => (
              <ListItem {...props}>
                {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                <ListItemText primary={option.label} />
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={serviceInputRef}
                variant="outlined"
                placeholder="Search for Services"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "350px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 0,
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                }}
              />
            )}
          />
        </SearchBox>

        {/* Dynamic Login/Logout Section */}
        <FlexBox sx={{ alignItems: "center", marginLeft: 2 }}>
          {isLoggedIn ? (
            <>
              <IconButton onClick={handleMenuClick}>
                <AccountCircleIcon fontSize="large" />
              </IconButton>
              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
              >
                <MenuItem onClick={handleProfileClick}>
                  {isMenuLoading.profile ? (
                    <CircularProgress size={24} sx={{ marginRight: 1 }} />
                  ) : (
                    <AccountCircleIcon sx={{ marginRight: 1 }} />
                  )}
                  Profile
                </MenuItem>
                <MenuItem onClick={handleBookingsClick}>
                  {isMenuLoading.bookings ? (
                    <CircularProgress size={24} sx={{ marginRight: 1 }} />
                  ) : (
                    <BookIcon sx={{ marginRight: 1 }} />
                  )}
                  Bookings
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  {isMenuLoading.logout ? (
                    <CircularProgress size={24} sx={{ marginRight: 1 }} />
                  ) : (
                    <ExitToAppIcon sx={{ marginRight: 1 }} />
                  )}
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <CustomButton
                variant="contained"
                onClick={() => setDialogOpen(true)}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login / Sign Up"
                )}
              </CustomButton>
              <LoginDialog
                open={isDialogOpen}
                onClose={() => setDialogOpen(false)}
                onLoginSuccess={handleLoginSuccess}
              />
            </>
          )}
        </FlexBox>
      </FlexBox>
    </DashboardHeaderContainer>
  );
};

export default Header;
