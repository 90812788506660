import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { COLORS } from "../../styles/Theme";
import styled from "@emotion/styled";
import { LOCATIONS } from "../../utils/Data";
import SelectInput from "../../components/UI/Select/SelectInput";
import LocationSearchInput from "../../components/UI/Input/LocationSearchInput";
import SearchIcon from "@mui/icons-material/Search";

function LocationModalContent({ handleReset, handleClose }) {
  const [location, setLocation] = useState("Bangalore");
  const [locationSearch, setLocationSearch] = useState('')

  return (
    <Box>
      <StyledModalHeader>
        <Button onClick={handleReset}>RESET</Button>

        <Button
          onClick={handleClose}
          endIcon={<CloseRoundedIcon />}
          sx={{ color: COLORS.black, textTransform: "none" }}
        >
          Location
        </Button>
      </StyledModalHeader>

      <Box m={2}>
        <SelectInput
          menuList={LOCATIONS}
          value={location}
          onValueChange={(value) => {
            setLocation(value);
          }}
          onlyBorderBottom
        />

        <LocationSearchInput
          value={locationSearch}
          onChange={({ target }) => {
            setLocationSearch(target.value);
          }}
          placeholder="Search Your Location"
          endIcon={<SearchIcon />}
          onlyBorderBottom
        />
      </Box>
    </Box>
  );
}

export default LocationModalContent;

const StyledModalHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "6px 10px",
  borderBottom: "1px solid #C2C2C2",
});
