// BASE URL
// export const BASE_URL = 'https://jsonplaceholder.typicode.com/todos/1'
// export const BASE_URL = 'http://ec2-43-204-229-119.ap-south-1.compute.amazonaws.com:8001'
    export const BASE_URL = 'https://api.localpro.app/'
// ENDPOINTS

export const GET_TEST_DATA = 'users'

// Auth Endpoints
export const REGISTER_USER = 'v1/users/register'
export const USER_OTP = 'v1/users/sendOtp'
export const VERIFY_OTP = 'v1/users/verifyOtp'
export const GET_CITY = 'v1/city/listCity' 

// Profile 
export const GET_PROFILE = 'v1/users/getProfile'
export const ADD_BUSINESS_PROFILE = 'v1/vendor/register'
// export const GET_CATEGORY = 'v1/mobile/getCategory'
export const GET_CATEGORY = 'v1/category/listCategory'
export const GET_SUB_CATEGORY = 'v1/mobile/getSubCategory'
export const ENQUIRY = 'v1/enquiry/EnquiryUserList'
export const ENQUIRY_VENDOR = 'v1/enquiry/getEnquiryList'
export const GET_VENDOR = 'v1/home/getVendorsByCategory'
export const GET_VENDOR_DETAIL = 'v1/home/getVendorDetails'
export const RATING = 'v1/ratings/submit-rating'
export const GET_CONTACTED_VENDORS = 'v1/enquiry/getContactedVendors'
export const CREATE_CALL = 'v1/call/createCall'
export const CREATE_COMPLAINT = 'v1/complaints/complaints';
export const GET_COMPLAINTS = 'v1/complaints/complaint';
export const STORE_ENQUIRY = 'v1/number/store-enquiry';
export const CONTACT_CREATE = 'v1/contact/createcontact';
// S3 BUCKET
export const S3_URL = 'v1/s3/signedUrl'