import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { COLORS } from "../../styles/Theme";
import styled from "@emotion/styled";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { SERVICE_DATA_LIST2, SERVICE_LIST_HOME } from "../../utils/Data";
import CategoryCardSmall from "../../components/Cards/CategoryCardSmall";

function CategoryModalContent({ handleClose }) {
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleCategorySelect = (value) => {
    setSelectedCategory(value);
  };

  return (
    <Box>
      <StyledModalHeader>
        <Typography variant="h6" fontSize={16} fontWeight={500}>
          Select Your Required Service
        </Typography>

        <IconButton onClick={handleClose}>
          <CloseRoundedIcon />
        </IconButton>
      </StyledModalHeader>

      <ContentBox>
        <Grid container>
          <Grid item xs={2.4}>
            <ContentBox sx={{ borderRight: "1px solid #C2C2C2" }}>
              {SERVICE_LIST_HOME?.map((item, index) => {
                return (
                  <CategoryCardSmall
                    key={"category_" + index}
                    imgUrl={item.imgUrl}
                    title={item.name}
                    selected={selectedCategory === item.name}
                    onClick={() => handleCategorySelect(item?.name)}
                  />
                );
              })}
            </ContentBox>
          </Grid>

          <Grid item xs>
            {SERVICE_DATA_LIST2?.map((item, index) => (
              <CategoryMenuItem
                key={"service_cat" + index}
                icon={item?.icon}
                label={item?.label}
              />
            ))}
          </Grid>
        </Grid>
      </ContentBox>
    </Box>
  );
}

export default CategoryModalContent;

const CategoryMenuItem = ({ icon, label, onClick }) => {
  return (
    <MenuItem onClick={onClick} sx={{ borderBottom: "1px solid #C2C2C2" }}>
      <ListItemIcon>
        <Box
          component="img"
          src={icon}
          sx={{ width: 20, height: 20, objectFit: "contain" }}
        />
      </ListItemIcon>

      <ListItemText>
        <Typography variant="body2" fontSize={14}>
          {label}
        </Typography>
      </ListItemText>

      <KeyboardArrowRightRoundedIcon sx={{ color: COLORS.greyShade3 }} />
    </MenuItem>
  );
};

const StyledModalHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "6px 10px",
  borderBottom: "1px solid #C2C2C2",
  backgroundColor: COLORS.white,
});

const ContentBox = styled(Box)({
  height: "calc(70vh - 53px)",
  overflow: "scroll",

  "&::-webkit-scrollbar": {
    width: "3px",
    backgroundColor: "#F5F5F5",
  },

  "&::-webkit-scrollbar-thumb": {
    borderRadius: "6px",
    backgroundColor: COLORS.primaryColor,
  },
});
