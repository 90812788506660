import styled from "@emotion/styled";

export const FormStyled = styled.form({
  padding: "25px",
});





