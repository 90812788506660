import { getApiInstance } from "../../ApiInstance";
import {
  ADD_BUSINESS_PROFILE,
  GET_CATEGORY,
  GET_PROFILE,
  GET_SUB_CATEGORY,
  S3_URL,
} from "../../Constant";

export const registerBusinessApi = async (postData) => {
  const token = localStorage.getItem("token");

  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });

    const response = await instance.post(ADD_BUSINESS_PROFILE, {
      bname: postData?.businessname,
      bemail: postData?.email,
      bmobile: postData?.mobileNumber,
      bphotos: postData?.businessImgUrl,
      category_id: postData?.category,
      subcategory_id: postData?.sub,
      adhar_front: postData?.aadharFrontUrl,
      adhar_back: postData?.aadharBackUrl,
      pan_front: postData?.panCardUrl,
      pan_back: postData?.panCardUrl,
      other_type: postData?.otherDocFrontUrl,
      other_front: postData?.otherDocFrontUrl,
      other_back: postData?.otherDocBackUrl,
      // type: "Service Provider",
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getS3Url = async (postData) => {
  const token = localStorage.getItem("token");

  let formData = new FormData();
  formData.append("file", postData?.img);
  console.log(">> formData >> ", formData);

  try {
    const instance = getApiInstance({
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });

    const response = await instance.post(S3_URL, formData);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCityDataApi = async () => {
  const token = localStorage.getItem("token");

  try {
    // const instance = getApiInstance();
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const response = await instance.get(GET_PROFILE);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCatagoryDataApi = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_CATEGORY);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSubCatagoryDataApi = async (postData) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(GET_SUB_CATEGORY, {
      category_id: postData?.id,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
