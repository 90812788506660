import styled from "@emotion/styled";
import { Box, IconButton, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { textOneLine } from "../../styles/Global";
import { COLORS } from "../../styles/Theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import ShareIcon from "@mui/icons-material/Share";
// import NotVerifiedIcon from "@mui/icons-material/Cancel";
import NotVerifiedIcon from "@mui/icons-material/Block";
import DirectionsBikeRoundedIcon from "@mui/icons-material/DirectionsBikeRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { createCallApi } from "../../services/api_calls/auth/AuthApi";

function ProfessionalInfoTile({
  imgUrl,
  name,
  title,
  rating,
  reviewCount,
  caterogy,
  timings,
  address,
  isNotAvailable,
  handleCall: parentHandleCall,
  handleShare,
  isVerified,
  isRatingInline,
  distance,
  handleEnquiryClick,
  isSubscribed,
  // handleCall,
  vendor_id,
  service_id,
  addToWishlistApi,
  isWishlisted: initialWishlistState,
  onWishlistUpdate,
}) {
  const [isWishlisted, setIsWishlisted] = useState(initialWishlistState);
  const [isCallLoading, setIsCallLoading] = useState(false);

  const handleWishlist = async (e) => {
    e.stopPropagation();
    try {
      const response = await addToWishlistApi({
        vendor_id: vendor_id,
        service_id: service_id,
      });
      if (response.status) {
        setIsWishlisted(!isWishlisted);
      }
      if (onWishlistUpdate) {
        await onWishlistUpdate();
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
    }
  };
  const handleCallClick = async (e) => {
    e.stopPropagation();
    if (isCallLoading) return;

    setIsCallLoading(true);
    try {
      const response = await createCallApi({
        vendor_id: vendor_id,
        service_id: service_id,
      });
      if (response.success !== false) {
        parentHandleCall();
      }
    } catch (error) {
      console.error("Error making call:", error);
    } finally {
      setIsCallLoading(false);
    }
  };
  return (
    <StyledCard>
      <CardContent>
        <StyledProfileBox>
          <Box
            component="img"
            src={imgUrl}
            alt="professional img"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/images/home-banner.png";
            }}
          />
        </StyledProfileBox>

        <Box flex={1}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="8px"
          >
            <Box display="flex" alignItems="center" gap="5px">
              <Typography
                variant="boby1"
                fontSize={13}
                fontWeight={500}
                color={COLORS.black}
                sx={{ ...textOneLine }}
              >
                {title}
              </Typography>
            </Box>
            {isVerified ? (
              <VerifiedBadge>
                <CheckCircleIcon sx={{ fontSize: 18, color: "green" }} />
                <Typography
                  variant="body2"
                  fontSize={10}
                  fontWeight={600}
                  sx={{ color: "green", ml: 0.5 }}
                >
                  Verified
                </Typography>
              </VerifiedBadge>
            ) : (
              <NotVerifiedBadge>
                <NotVerifiedIcon sx={{ fontSize: 18, color: "red" }} />
                <Typography
                  variant="body2"
                  fontSize={10}
                  fontWeight={600}
                  sx={{ color: "red", ml: 0.5 }}
                >
                  Not Verified
                </Typography>
              </NotVerifiedBadge>
            )}
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="4px"
            mr={0.5}
          >
            <Box>
              {isRatingInline && (
                <Box mb="-3px">
                  <Typography
                    variant="boby1"
                    fontSize={11}
                    fontWeight={600}
                    color={COLORS.black}
                    display="inline"
                  >
                    <StarRoundedIcon
                      sx={{
                        fontSize: 16,
                        color: COLORS.secondaryColor,
                        m: "0 3px -3px 0",
                      }}
                    />
                    {rating}
                  </Typography>

                  <Typography
                    variant="boby1"
                    fontSize={11}
                    fontWeight={500}
                    color={COLORS.blackShade1}
                    display="inline"
                    ml={1}
                  >
                    {reviewCount} Reviews
                  </Typography>
                </Box>
              )}
              {!isRatingInline && (
                <Typography
                  variant="boby1"
                  fontSize={11}
                  fontWeight={600}
                  color={COLORS.black}
                  display="inline"
                  mr={0.3}
                >
                  <StarRoundedIcon
                    sx={{
                      fontSize: 16,
                      color: COLORS.secondaryColor,
                      m: "0 3px -3px 0",
                    }}
                  />
                  {rating}
                  <Typography
                    variant="boby1"
                    fontSize={11}
                    fontWeight={500}
                    color={COLORS.blackShade1}
                    display="inline"
                    ml={1}
                  >
                    ({reviewCount} Reviews)
                  </Typography>
                </Typography>
              )}

              <Typography
                variant="boby1"
                fontSize={11}
                fontWeight={500}
                color={COLORS.blackShade1}
                display="inline"
                sx={{ ...textOneLine, m: "4px 0" }}
              >
                <LocationOnIcon
                  sx={{
                    fontSize: 14,
                    color: COLORS.primaryColor,
                    m: "0 3px -4px 0",
                  }}
                />
                {address}
              </Typography>

              {distance && (
                <Typography
                  variant="boby1"
                  fontSize={11}
                  fontWeight={600}
                  color={COLORS.primaryColor}
                  display="inline"
                  sx={{ ...textOneLine, m: "4px 0" }}
                >
                  <DirectionsBikeRoundedIcon
                    sx={{
                      fontSize: 16,
                      color: COLORS.blackShade1,
                      m: "0 3px -4px 0",
                    }}
                  />
                  {distance} Kms away
                </Typography>
              )}
            </Box>
            {/* Wishlist and Share Icons */}
            {isVerified && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                justifyContent="center"
                gap="8px"
                position="relative"
                sx={{
                  marginTop: "0px",
                  marginRight: "10px",
                }}
              >
                <WishlistButton size="small" onClick={handleWishlist}>
                  {isWishlisted ? (
                    <FavoriteRoundedIcon sx={{ fontSize: 18, color: "red" }} />
                  ) : (
                    <FavoriteBorderRoundedIcon sx={{ fontSize: 18 }} />
                  )}
                </WishlistButton>
                {/* <ShareButton size="small">
                  <ShareIcon sx={{ fontSize: 14 }} />
                </ShareButton> */}
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
      {isVerified && (
        <Box sx={{ borderTop: "0.3px dashed " + COLORS.greyShade1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "8px",
            }}
          >
            <Box>
              {isSubscribed && (
                <Button>
                  <Box
                    component="img"
                    src="/assets/images/Group 5261.svg"
                    alt="Premium Icon"
                    sx={{
                      width: {
                        xs: "120px",
                        sm: "100px",
                        md: "130px",
                      },
                      height: "auto",
                    }}
                  />
                </Button>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                "@media (max-width: 600px)": {
                  gap: "5px",
                },
              }}
            >
              {/* 
              <Button
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  sx={{
                    borderColor: "#21608F",
                    color: "#21608F",
                    borderRadius: "10px",
                    padding: "8px 16px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    minWidth: "auto",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    "@media (max-width: 600px)": {
                      padding: "6px 12px",
                      fontSize: "12px",
                    },
                  }}
                >
                  <ShareIcon sx={{ fontSize: 16 }} />
                  Share
                </Button> */}
              {/* <Button
                variant="outlined"
                onClick={handleEnquiryClick}
                sx={{
                  borderColor: "#21608F",
                  color: "#21608F",
                  borderRadius: "10px",
                  padding: "8px 16px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  "@media (max-width: 600px)": {
                    padding: "5px",
                    fontSize: "10px",
                  },
                }}
              >
                Send Enquiry
              </Button> */}

              <Button
                variant="contained"
                onClick={handleCallClick}
                disabled={isCallLoading}
                sx={{
                  backgroundColor: "#21608F",
                  color: "#fff",
                  borderRadius: "10px",
                  padding: "8px 16px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  "@media (max-width: 600px)": {
                    padding: "5px",
                    fontSize: "10px",
                  },
                  "&:hover": {
                    backgroundColor: "#21608F",
                  },
                  opacity: isCallLoading ? 0.7 : 1,
                }}
              >
                {isCallLoading ? "Processing..." : "Call Now"}
                {!isCallLoading && (
                  <img
                    src="/assets/images/Icon ionic-ios-call.svg"
                    alt="Phone Icon"
                    style={{ marginLeft: "8px", width: "16px" }}
                  />
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </StyledCard>
  );
}

export default ProfessionalInfoTile;

const CallButton = ({ onClick }) => (
  <StyledCallButton onClick={onClick}>
    <StylesIcon>
      <PhoneRoundedIcon fontSize="small" />
    </StylesIcon>

    <Typography
      variant="h4"
      fontSize={10}
      fontWeight={500}
      color="primary"
      textAlign="center"
      mt={1}
    >
      Call
    </Typography>
  </StyledCallButton>
);

const StyledCard = styled(Box)({
  paddingBottom: "8px",
  backgroundColor: COLORS.greyShade8,
  borderBottom: "1px dashed " + COLORS.greyShade3,
  position: "relative",
});

const CardContent = styled(Box)({
  display: "flex",
  gap: "10px",
  padding: "5px",
});

const StyledProfileBox = styled(Box)({
  width: 110,
  height: 110,

  borderRadius: "6px",
  overflow: "hidden",
  boxShadow: "0px 1px 3px 1px rgba(0,0,0,0.13)",
  position: "relative",
});

export const StylesIcon = styled(Box)({
  width: 29,
  height: 29,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: COLORS.white,
  backgroundColor: COLORS.green,
  border: "1px solid " + COLORS.primaryColor,
  borderRadius: 25,
});

export const StyledCallButton = styled.button({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: COLORS.greyShade8,
  border: "none",
  cursor: "pointer",
});

export const WishlistButton = styled(IconButton)({
  width: 30,
  height: 30,
  color: COLORS.secondaryColor,
  backgroundColor: COLORS.white,
  // position: "absolute",
  // top: 5,
  // left: 5,
});

export const ShareIconButton = styled(IconButton)({
  width: 30,
  height: 30,
  color: COLORS.secondaryColor,
  backgroundColor: COLORS.greyShade8,
  border: "1px solid " + COLORS.secondaryColor,
  marginTop: "8px",
});

export const NotAvailabelTag = styled(Box)({
  position: "absolute",
  top: -6,
  right: 0,
});
const VerifiedBadge = styled(Box)({
  // position: "absolute",
  // top: "5px",
  // right: "5px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#E6FFED",
  padding: "2px 6px",
  borderRadius: "5px",
});

const NotVerifiedBadge = styled(Box)({
  // position: "absolute",
  // top: "5px",
  // right: "5px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#FFE6E6",
  padding: "2px 6px",
  borderRadius: "5px",
});
export const ShareButton = styled(IconButton)({
  width: 30,
  height: 30,
  color: COLORS.primaryColor,
  backgroundColor: COLORS.white,
  boxShadow: "0px 1px 3px 1px rgba(0,0,0,0.13)",
});
