import styled from "@emotion/styled";
import { COLORS } from "../../styles/Theme";

export const SplashWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.grey};

  background: url(./assets/images/splash-bg.png);
  background-repeat: repeat;
  background-size: contain;

  &:after,
  &:before {
    content: " ";
    width: 100%;
    height: 8px;
    background-color: ${COLORS.primaryColor};
    position: absolute;
  }

  &:after {
    top: 0;
  }

  &:before {
    background-color: ${COLORS.secondaryColor};
    bottom: 0;
  }
`;
