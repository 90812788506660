import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React from "react";
import { textOneLine } from "../../styles/Global";
import { COLORS } from "../../styles/Theme";

function TransactionHistoryMinimal({ price, datetime, status }) {
  const getStatusColor = (status) => {
    switch (status) {
      case "Sucess":
        return COLORS.green;
      case "Failed":
        return COLORS.red;
      case "Pending":
        return COLORS.primaryColor;

      default:
        return COLORS.secondaryColor;
    }
  };

  return (
    <StyledCard>
      <Box>
        <Typography
          variant="body1"
          fontSize={19}
          fontWeight={500}
          color={COLORS.black}
        >
          ₹ {price}
        </Typography>

        <Typography
          variant="body2"
          fontSize={10}
          fontWeight={400}
          color={COLORS.black}
          sx={{ ...textOneLine }}
        >
          {datetime}
        </Typography>
      </Box>

      <Typography
        variant="body2"
        fontSize={15}
        fontWeight={500}
        color={getStatusColor(status)}
        textAlign="center"
      >
        {status}
      </Typography>
    </StyledCard>
  );
}

export default TransactionHistoryMinimal;

const StyledCard = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent:'space-between',
  gap: "12px",
  backgroundColor: COLORS.white,
  borderRadius: "8px",
  marginBottom: "16px",
  padding:'10px',
  boxShadow: "0px 1px 20px -1px rgba(0,0,0,0.13)",
  overflow: "hidden",
});
