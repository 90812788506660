import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { COLORS } from "../../../styles/Theme";

export const StyledFormButton = styled(Button)(
  ({ width, textColor, bgColor, hoverBGColor }) => ({
    width: width || "100%",
    border: "none",
    color: textColor || COLORS.white,
    backgroundColor: bgColor || COLORS.primaryColor,
    padding: "8px",
    textTransform: "none",

    "&:hover": {
      backgroundColor: hoverBGColor || COLORS.primaryDark,
    },

    "&:disabled": {
      color: textColor || COLORS.white,
      opacity: 0.4
    },
  })
);
