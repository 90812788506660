import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import LogoFullWidth from "../../../components/Logo/LogoFullWidth";
import BgVideo from "./BgVideo";
import {
  ContinueBtn,
  H2,
  H3,
  HeadingContent,
  InfoSpan,
  Wrapper,
} from "./Welcome.styles";

function Welcome() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <BgVideo />

      <HeadingContent>
        <LogoFullWidth width="170px" height="70px" />
        <H2>WELCOME TO LOCALPRO</H2>
      </HeadingContent>

      <Box display='flex' alignItems="center" justifyContent="center" flexDirection="column">
        <H3>
          Search Nearby <br />
          Home Appliance Services
        </H3>

        <InfoSpan>
          50+ Home Appliances Repair Services <br />
          available in Bangalore
        </InfoSpan>

        <ContinueBtn
          onClick={() => {
            navigate("/chooseLocation");
          }}
        >
          Continue
        </ContinueBtn>
      </Box>
    </Wrapper>
  );
}

export default Welcome;
