import styled from "@emotion/styled";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";

function SelectInputStyled({
  value,
  onValueChange,
  menuList,
  valueSelector,
  disabled,
}) {
  return (
    <SelectContainer
      sx={{
        mb: 2,
        backgroundColor: COLORS.white,
      }}
    >
      <StyledFormControl>
        <Select
          value={value}
          // onChange={(event) => {
          //   onValueChange(event.target.value);
          // }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          fullWidth
          disabled={disabled}
        >
          {menuList.map((item, index) => {
            return (
              <MenuItem
                value={valueSelector ? item[valueSelector] : item.label}
                key={"Menu_Item_" + index}
                onClick={() => {
                  onValueChange(item);
                }}
              >
                {valueSelector ? item[valueSelector] : item.label}
              </MenuItem>
            );
          })}
        </Select>
      </StyledFormControl>
    </SelectContainer>
  );
}

export default SelectInputStyled;

const SelectContainer = styled(Box)({
  boxShadow: "0px 0px 35px -15px rgba(0,0,0,0.13)",
  position: "relative",

  "&:before": {
    content: '" "',
    width: "5px",
    height: "100%",
    backgroundColor: COLORS.secondaryColor,
    position: "absolute",
  },
});

const StyledFormControl = styled(FormControl)({
  width: "calc(100% - 15px)",
  paddingLeft:'10px',

  "& .css-hdw1oc": {
    display: "none",
  },

  "& .MuiOutlinedInput-notchedOutline, .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
    {
      border: "none",
    },
});
