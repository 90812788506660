import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../../../components/UI/Header";
import {
  FormStyled,
  ImgContainer,
  ProfileImg,
  StyledIconButton,
} from "./AddPersonalDetails.style";
import EditIcon from "@mui/icons-material/Edit";
import InputText from "../../../components/UI/Input/InputText";
import SelectInputStyled from "../../../components/UI/Select/SelectInputStyled";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GENDERS } from "../../../utils/Data";
import { getProfileData } from "../../../redux/profile/profileSlice";

function AddPersonalDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [gender, setGender] = useState("Male");


  const {
    profileContent,
    profileContentLoader,
  } = useSelector((state) => state.profile);


  useEffect(() => {
    dispatch(getProfileData());
    if(profileContent){
      setValues(profileContent)
    }
   
    return () => { };
  }, []);

  const setValues = (profileContent) =>{
     setName(profileContent.user[0].name)
    setEmail(profileContent.user[0].email)
    setGender(profileContent.user[0].gender)
    setMobileNumber(profileContent.user[0].mobile)
  }

  

  return (
    <Box>
      <Header title="Personal Details" />
      <FormStyled>
        <ImgContainer>
          <Box position="relative">
            <ProfileImg
              src="/./assets/images/profile-avatar.png"
              alt="Profile Img"
            />

            <StyledIconButton size="small" onClick={() => {}}>
              <EditIcon />
            </StyledIconButton>
          </Box>
        </ImgContainer>

        <InputText
          value={name}
          onChange={({ target }) => {
            setName(target.value);
          }}
          placeholder="Enter Your Full Name"
        />

        <InputText
          value={email}
          onChange={({ target }) => {
            setEmail(target.value);
          }}
          placeholder="Enter Your Email ID"
        />

        <InputText
          value={mobileNumber}
          onChange={({ target }) => {
            setMobileNumber(target.value);
          }}
          placeholder="Enter Your Mobile Number"
          inputType="number"
        />

        <SelectInputStyled
          value={gender}
          onValueChange={(text) => {
            setGender(text);
          }}
          menuList={GENDERS}
        />

        <StyledFormButton
          onClick={() => {
            navigate("/addReferDetails");
          }}
          sx={{ mt: 2 }}
          type="submit"
        >
          Submit
        </StyledFormButton>
      </FormStyled>
    </Box>
  );
}

export default AddPersonalDetails;
