import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Header from "../../../components/UI/Header";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import FileUploadPreviewBox from "../../../components/UI/FileInput/FileUploadPreviewBox";
import { getS3Url, registerBusinessApi } from "../../../services/api_calls/profile/profileApi";

function AddDocuments() {
  const navigate = useNavigate();
  const [aadharFront, setAadharFront] = useState('');
  const [aadharFrontUrl, setAadharFrontUrl] = useState('');
  const [aadharBack, setAadharBack] = useState('');
  const [aadharBackUrl, setAadharBackUrl] = useState('');
  const [panCard, setPanCard] = useState('');
  const [panCardUrl, setPanCardUrl] = useState('')
  const [gstInfo, setGstInfo] = useState('')
  const [gstInfoUrl, setGstInfoUrl] = useState('')
  const [otherDocFront, setOtherDocFront] = useState()
  const [otherDocFrontUrl, setOtherDocFrontUrl] = useState()
  const [otherDocBack, setOtherDocBack] = useState()
  const [otherDocBackUrl, setOtherDocBackUrl] = useState()


  const handleClick = async (event) => {
    event.preventDefault()
    if (aadharFrontUrl && aadharBackUrl && panCardUrl && gstInfoUrl &&  otherDocFrontUrl && otherDocBackUrl ) {
      const response = await registerBusinessApi({ aadharFrontUrl, aadharBackUrl,panCardUrl, gstInfoUrl , otherDocFrontUrl, otherDocBackUrl });
      console.log(response.percentage);
      localStorage.setItem('profile', 4);
      navigate("/editProfile");
    }
    else{
      alert("Fill All details")
    }
  };


  // const onchangeS3 = async (img) =>{
  //   const response = await getS3Url({ img });
  //   console.log('url:', response.data)
  //   setAadharFrontUrl(response.data)

  // }

  const getAadharS3Url = async (img) =>{
    const response = await getS3Url({ img });
    setAadharFrontUrl(response.data)
  }

  const getAadharBackUrl = async (img) =>{
    const response = await getS3Url({ img });
    setAadharFrontUrl(response.data) 
  }


  const getPanCardUrl = async (img) =>{
    const response = await getS3Url({ img });
    setPanCardUrl(response.data) 
  }

  const getGstInfoUrl = async (img) =>{
    const response = await getS3Url({ img });
    setGstInfoUrl(response.data) 
  }

  const getOtherDocFront = async (img) =>{
    const response = await getS3Url({ img });
    setOtherDocFrontUrl(response.data) 
  }

  const getOtherDocBackUrl = async (img) =>{
    const response = await getS3Url({ img });
    setOtherDocBackUrl(response.data) 
  }






  return (
    <Box>
      <Header title="Add Your Documents" />

      <FormStyled>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FileUploadPreviewBox
              label="Aadhar Card Front*"
              image={aadharFront}
              setImage={setAadharFront}
              api={getAadharS3Url}
            />
          </Grid>

          <Grid item xs={6}>
            <FileUploadPreviewBox
              label="Aadhar Card Back*" image={aadharBack}
              setImage={setAadharBack}
              api={getAadharBackUrl}
            />
          </Grid>

          <Grid item xs={12}>
            <FileUploadPreviewBox
              label="Pan Card (optional)" image={panCard}
              setImage={setPanCard}
              api={getPanCardUrl}
            />
          </Grid>

          <Grid item xs={12}>
            <FileUploadPreviewBox
              label="GST (optional)" image={gstInfo}
              setImage={setGstInfo}
              api={getGstInfoUrl}
            />
          </Grid>
        </Grid>

        <Typography
          variant="body2"
          fontSize={13}
          fontWeight={500}
          color="#656565"
          mt={1}
        >
          Other Documents (optional)
        </Typography>

        <FormGroup sx={{ flexDirection: "row !important" }}>
          <FormControlLabel
            control={<Checkbox size="small" defaultChecked />}
            label="Driving License"
          />
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="Voter ID"
          />
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="Passport"
          />
        </FormGroup>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FileUploadPreviewBox label="Front" image={otherDocFront} setImage={setOtherDocFront} api={getOtherDocFront}/>
          </Grid>

          <Grid item xs={6}>
            <FileUploadPreviewBox label="Back" image={otherDocBack} setImage={setOtherDocBack} api={getOtherDocBackUrl} />
          </Grid>
        </Grid>

        <StyledFormButton
          onClick={handleClick}
          sx={{ mt: 2 }}
          type="submit"
        >
          Submit
        </StyledFormButton>
      </FormStyled>
    </Box>
  );
}

export default AddDocuments;

const FormStyled = styled.form({
  padding: "25px",
});
