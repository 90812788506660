import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./auth/AuthSlice";
import { homeReducer } from "./home/HomeSlice";
import { profileReducer } from "./profile/profileSlice";

const store = configureStore({
  reducer: {
    home: homeReducer,
    auth: authReducer,
    profile: profileReducer,
    
  },
});

export default store;
