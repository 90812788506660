
import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import { COLORS } from "../../../styles/Theme";

export const Wrapper = styled(Box)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const HeadingContent = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
`;

export const H2 = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: ${COLORS.black};
  margin-top: 6px;
`;

export const H3 = styled.h3`
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: ${COLORS.white};
  margin-bottom: 6px;
`;

export const InfoSpan = styled.span`
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  color: ${COLORS.white};
  margin-bottom: 10px;
`;

export const ContinueBtn = styled(Button)`
  width: 300px;
  border: none;
  margin-bottom: 20px;
  color: ${COLORS.white};
  background-color: ${COLORS.primaryColor};
  padding: 8px;

  &:hover {
    background-color: ${COLORS.primaryColor};
  }
`;
