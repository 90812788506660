import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React from "react";
import { textOneLine } from "../../styles/Global";
import { COLORS } from "../../styles/Theme";

function TransactionHistoryCard({ price, contacts, datetime, status }) {
  const getStatusColor = (status) => {
    switch (status) {
      case "Sucess":
        return COLORS.ratingFill;
      case "Failed":
        return COLORS.red;
      case "Pending":
        return COLORS.secondaryColor;

      default:
        return COLORS.primaryColor;
    }
  };

  return (
    <StyledCard>
      <PriceBox>
        <Typography
          variant="body1"
          fontSize={20}
          fontWeight={500}
          color={COLORS.black}
        >
          ₹ {price}
        </Typography>
      </PriceBox>

      <Box flex={1}>
        <Typography
          variant="body1"
          fontSize={16}
          fontWeight={600}
          color={COLORS.black}
          sx={{ ...textOneLine }}
        >
          {contacts}
        </Typography>

        <Typography
          variant="body2"
          fontSize={10}
          fontWeight={400}
          color={COLORS.black}
          sx={{ ...textOneLine }}
        >
          {datetime}
        </Typography>
      </Box>

      <Typography
        variant="body2"
        fontSize={15}
        fontWeight={500}
        color={getStatusColor(status)}
        width="80px"
        textAlign="center"
      >
        {status}
      </Typography>
    </StyledCard>
  );
}

export default TransactionHistoryCard;

const StyledCard = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  backgroundColor: COLORS.white,
  borderRadius: "8px",
  marginBottom: "16px",
  boxShadow: "0px 1px 20px -1px rgba(0,0,0,0.13)",
  overflow: "hidden",
});

const PriceBox = styled(Box)({
  width: "65px",
  padding: "18px 10px",
  backgroundColor: COLORS.greyShade4,
});
