import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Box,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { userSentOtp, userVerifyOtp } from '../../../services/api_calls/auth/AuthApi';

const OTPDialog = ({ open, onClose, phoneNumber, onEditPhone, onBack, onSuccess }) => {
  const [otp, setOtp] = useState(['', '', '', '']);
  const [timer, setTimer] = useState(54);
  const [resendSuccess, setResendSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const handleOtpChange = (index, value) => {
    if (isNaN(value) || value.length > 1) return;

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    if (value !== '' && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      if (otp[index] === '' && index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  const handleResend = async () => {
    setOtp(['', '', '', '']);
    setTimer(54);
    setResendSuccess(true);
    setError("");

    const response = await userSentOtp({ mobileNumber: phoneNumber });

    if (response?.error) {
      setError(response.error);
    }

    setTimeout(() => {
      setResendSuccess(false);
    }, 3000);
  };

  const handleVerifyOtp = async () => {
    const enteredOtp = otp.join('');
    if (enteredOtp === '') {
      setError("Please enter the OTP!");
      return;
    }

    const postData = {
      mobilenumber: phoneNumber,
      otp: enteredOtp,
    };

    try {
      const response = await userVerifyOtp(postData);
      if (response?.status === true && response?.access_token) {
        setError(""); 
        // console.log("OTP Verified Successfully!");
        localStorage.setItem('authToken', response.access_token);
        // console.log('Token saved:', response.access_token);
        onSuccess(response);
      } else {
        setError("Invalid OTP! Please try again.");
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
      console.error("OTP Verification Error:", err);
    }
  };

  const handleBack = () => {
    setOtp(['', '', '', '']);
    onBack();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box display="flex" alignItems="center" gap={1}>
          <img src="./assets/images/logo.jpg" alt="Logo" style={{ marginRight: 8, width: '100px' }} />
          <Typography variant="h6">Welcome</Typography>
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center" gap={1} mt={2}>
          <Typography variant="subtitle1">Enter the code sent to</Typography>
          <Typography variant="body2">
            <strong>+91 {phoneNumber}</strong>
          </Typography>
          <IconButton onClick={onEditPhone} size="small" sx={{ padding: 0 }}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box display="flex" justifyContent="center" gap={1} my={2}>
          {otp.map((digit, index) => (
            <TextField
              key={index}
              id={`otp-${index}`}
              value={digit}
              onChange={(e) => handleOtpChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              variant="outlined"
              inputProps={{ maxLength: 1, style: { textAlign: 'center', fontSize: '24px' } }}
              sx={{ width: '50px', height: '50px' }}
            />
          ))}
        </Box>

        {error && (
          <Typography color="error" align="center" variant="caption" mb={2}>
            {error}
          </Typography>
        )}

        {resendSuccess && (
          <Typography color="success" align="center" mb={2}>
            Code resent successfully!
          </Typography>
        )}

        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="caption">
            Didn't Receive the OTP? Retry in 00:{timer < 10 ? `0${timer}` : timer}
          </Typography>
          <Button onClick={handleResend} disabled={timer > 0} variant="text">
            Resend OTP
          </Button>
        </Box>

        <Button
          variant="contained"
          sx={{
            mb: 2,
            backgroundColor: '#226090',
            '&:hover': {
              backgroundColor: '#226090',
            },
          }}
          fullWidth
          onClick={handleVerifyOtp}
        >
          Continue
        </Button>

        <Button variant="text" color="primary" onClick={handleBack} fullWidth>
          Back
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default OTPDialog;