import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

function FileUploadPreviewBox({ label, image, setImage, api }) {
  const inputRef = useRef(null);

  const triggerFile = () => inputRef.current.click();

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        setImage(reader.result);
        api(event.target.files[0]);
      };
    }
  };

  return (
    <Box sx={{ mb: 2, cursor: "pointer" }} onClick={triggerFile}>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={onSelectFile}
        style={{ display: "none" }}
      />

      <Typography
        variant="body2"
        fontSize={13}
        fontWeight={500}
        color="#656565"
      >
        {label}
      </Typography>

      {!image ? (
        <StyledBox>
          <FileUploadOutlinedIcon fontSize="large" />
        </StyledBox>
      ) : (
        <StyledImage src={image} alt={label} />
      )}
    </Box>
  );
}

export default FileUploadPreviewBox;

const StyledBox = styled(Box)({
  height: "135px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "6px",
  marginTop: "10px",
  boxShadow: "0px 0px 35px -10px rgba(0,0,0,0.13)",
});

const StyledImage = styled.img({
  width: "100%",
  height: "135px",
  objectFit: "contain",
  borderRadius: "6px",
  marginTop: "10px",
  boxShadow: "0px 0px 35px -10px rgba(0,0,0,0.13)",
});
