import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Container,
  styled,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Link
} from "@mui/material";
import Header from "../../../components/UI/DashboardHeader/Header";
import Footer from "../../../components/UI/Footer/Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import BottomTabs from "../../../components/UI/BottomTabs";
import BusinessListingSteps from "./BusinessListingSteps";
import { userSentOtp, userVerifyOtp, storeEnquiryNumber } from '../../../services/api_calls/auth/AuthApi';


const MainContainer = styled(Box)(({ theme }) => ({
  flex: "1 0 auto",
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(6),
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "1200px",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}));

const PageContainer = styled(Container)`
  padding: 80px;
  
  @media (max-width: 600px) {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
`;

const PhoneInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  maxWidth: 480,
  width: "100%",
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: "40px",
  padding: "4px",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: "#fff",
  "& .MuiTextField-root": {
    margin: "0 8px",
  },
}));

const FlagContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "0 12px",
  borderRight: "1px solid #ccc",
  "& img": {
    width: 24,
    height: 16,
    marginRight: 8,
  },
});

const StyledButton = styled("button")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  border: "none",
  borderRadius: "20px",
  padding: "12px 16px",
  fontSize: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  transition: "all 0.3s ease",
  marginLeft: "auto",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "& .MuiSvgIcon-root": {
    marginLeft: "8px",
  },
}));

const FeatureItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  marginBottom: "16px",
});

const BreadcrumbText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 14px Poppins",
  color: theme.palette.text.secondary,
  "& span": {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textDecoration: "none",
  },
  "& a": {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: "1200px",
        },
      },
    },
  },
});
const OTPPopup = ({ open, handleClose, otp, setOtp, handleOtpSubmit, phoneNumber, handleResendOtp }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: {
          padding: '30px',
          width: '1920px',
        }
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" px={2} pt={2}>
        <DialogTitle sx={{ font: 'normal normal bold 20px Poppins', padding: 0 }}>Enter OTP Here</DialogTitle>
        <IconButton onClick={handleClose} sx={{ marginLeft: 'auto' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            label=""
            fullWidth
            name="otp"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            sx={{
              background: '#F7F7F7',
              borderRadius: '4px',
              boxShadow: 'inset 0px 3px 6px #00000029',
              border: 'none'
            }}
          />
          <Typography variant="body2" sx={{ textAlign: 'center', marginTop: '8px' }}>
            OTP sent to "<b>{phoneNumber}</b>" <Link href="#" onClick={handleClose} underline="hover">Edit Number</Link>
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', marginTop: '8px' }}>
            <Link href="#" onClick={handleResendOtp} underline="hover">Resend OTP</Link>
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleOtpSubmit}
          sx={{
            bgcolor: '#226090',
            color: 'white',
            '&:hover': { bgcolor: '#226090' },
            width: '100%',
            py: 1
          }}
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DownloadLinksPopup = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Download Our App</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
          <Typography variant="body1" textAlign="center">
            Thank you for your interest! Download our app to list your business and reach more customers.
          </Typography>
          
          <Box display="flex" gap={2} justifyContent="center" flexWrap="wrap">
            <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
              <img 
                src="/assets/images/Image 13.png" 
                alt="Google Play Store"
                style={{ width: '200px', height: 'auto' }}
              />
            </a>
            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
              <img 
                src="/assets/images/Image 14.png" 
                alt="Apple App Store" 
                style={{ width: '200px', height: 'auto' }}
              />
            </a>
          </Box>
        </Box>
      </DialogContent>
      
      <DialogActions>
        <Button 
          variant="contained"
          onClick={onClose}
          sx={{
            bgcolor: '#226090',
            '&:hover': { bgcolor: '#226090' },
            mx: 'auto',
            mb: 2,
            px: 4
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const ListBusinessPage = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const features = [
    "Boost Your Digital Presence",
    "Connect with Targeted Customers",
    "Streamline Business Operations",
    "Enhance Customer Engagement",
    "Access Powerful Analytics",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phoneNumber || phoneNumber.length !== 10) {
      setError('Please enter a valid 10-digit phone number');
      return;
    }

    try {
      const response = await userSentOtp({ mobileNumber: phoneNumber });
      if (response?.status) {
        setShowOtpPopup(true);
        setError('');
      } else {
        setError(response?.message || 'Failed to send OTP');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError('Something went wrong. Please try again.');
    }
  };

  const handleOtpSubmit = async () => {
    if (!otp || otp.length !== 4) {
      setError('Please enter a valid OTP');
      return;
    }

    try {
      const response = await userVerifyOtp({
        mobilenumber: phoneNumber,
        otp: otp
      });

      if (response?.status) {
        await storeEnquiryNumber({ mobileNumber: phoneNumber });
        setShowOtpPopup(false);
        setShowDownloadPopup(true);
        setOtp('');
      } else {
        setError(response?.message || 'Invalid OTP');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('Something went wrong. Please try again.');
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await userSentOtp({ mobileNumber: phoneNumber });
      if (response?.status) {
        // Optional: Show a success message for OTP resend
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <MainContainer>
        <PageContainer maxWidth="lg">
          {/* Your existing JSX for the page layout */}
          <Box mb={2}>
            <BreadcrumbText>
              <RouterLink to="/">Home</RouterLink> {">"} List Your Business
            </BreadcrumbText>
          </Box>

          <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={4} mb={4}>
            <Box flex={1} minWidth={isMobile ? "100%" : 300}>
              {/* Form section */}
              <form onSubmit={handleSubmit}>
                <PhoneInputContainer>
                  <FlagContainer>
                    <img src="/assets/images/Flag.svg" alt="India" />
                    <Typography sx={{ fontSize: "15px", color: "#333" }}>+91</Typography>
                  </FlagContainer>
                  <TextField
                    fullWidth
                    placeholder="Enter Mobile No."
                    variant="standard"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, '').slice(0, 10))}
                    error={Boolean(error)}
                    helperText={error}
                    InputProps={{
                      disableUnderline: true,
                      style: { fontSize: "16px", padding: "8px" },
                    }}
                  />
                  <StyledButton type="submit">
                    Start Now
                    <ArrowForwardIcon />
                  </StyledButton>
                </PhoneInputContainer>
              </form>

              {/* Features section */}
              <Box mt={5}>
                {features.map((feature, index) => (
                  <FeatureItem key={index}>
                    <CheckIcon color="success" sx={{ bgcolor: "#e8f5e9", borderRadius: "50%", p: 0.5 }} />
                    <Typography sx={{ font: "normal normal normal 15px Poppins" }}>
                      {feature}
                    </Typography>
                  </FeatureItem>
                ))}
              </Box>
            </Box>

            {/* Image section */}
            <Box flex={1} minWidth={isMobile ? "100%" : 300} display="flex" justifyContent="center" alignItems="center">
              <Box
                component="img"
                src="/assets/images/business-preview.jpg"
                alt="Business Preview"
                sx={{
                  width: isMobile ? "100%" : "80%",
                  maxWidth: "280px",
                  height: "auto",
                }}
              />
            </Box>
          </Box>

          <BusinessListingSteps />
        </PageContainer>

        {/* Popups */}
        <OTPPopup
          open={showOtpPopup}
          handleClose={() => setShowOtpPopup(false)}
          otp={otp}
          setOtp={setOtp}
          handleOtpSubmit={handleOtpSubmit}
          phoneNumber={phoneNumber}
          handleResendOtp={handleResendOtp}
        />

        <DownloadLinksPopup
          open={showDownloadPopup}
          onClose={() => setShowDownloadPopup(false)}
        />
      </MainContainer>
      <Footer />
      {isMobile && <BottomTabs activeTab="list-your-business" />}
    </ThemeProvider>
  );
};

export default ListBusinessPage;