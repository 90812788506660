// Footer.js
import React from 'react';
import { Grid } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
  MainFooter,
  FooterContainer,
  LogoContainer,
  FooterLogo,
  SectionHeading,
  FooterLink,
  SocialIconsContainer,
  CircularIconButton,
  AppStoreContainer,
  CopyrightContainer,
  CopyrightText
} from './Footer.style';

const Footer = () => {
  return (
    <MainFooter>
      <FooterContainer>
        <LogoContainer>
        <FooterLogo src="/assets/images/logo.jpg" alt="Local Pro" />
        </LogoContainer>

        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <SectionHeading>Company</SectionHeading>
            <FooterLink to="/about-us">About us</FooterLink>
            <FooterLink to="/privacy">Privacy policy</FooterLink>
            <FooterLink to="/terms">Terms & conditions</FooterLink>
          </Grid>

          <Grid item xs={12} md={3}>
            <SectionHeading>Quick Links</SectionHeading>
            <FooterLink to="/blog">Blog</FooterLink>
            <FooterLink to="/services">Services</FooterLink>
            <FooterLink to="/support">Support</FooterLink>
            <FooterLink to="/contactus">Contact us</FooterLink>
          </Grid>

          <Grid item xs={12} md={3}>
            <SectionHeading>For Business</SectionHeading>
            <FooterLink to="/list-your-business">Register for a Business</FooterLink>
          </Grid>

          <Grid item xs={12} md={3}>
            <SectionHeading>Social links</SectionHeading>
            <SocialIconsContainer>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <CircularIconButton>
                  <TwitterIcon />
                </CircularIconButton>
              </a>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <CircularIconButton>
                  <FacebookIcon />
                </CircularIconButton>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <CircularIconButton>
                  <InstagramIcon />
                </CircularIconButton>
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                <CircularIconButton>
                  <LinkedInIcon />
                </CircularIconButton>
              </a>
            </SocialIconsContainer>

            <AppStoreContainer>
            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                <img src="/assets/images/Image 14.png" alt="Apple App Store" />
              </a>
            <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                <img src="/assets/images/Image 13.png" alt="Google Play Store" />
              </a>
            </AppStoreContainer>
          </Grid>
        </Grid>

        <CopyrightContainer>
          <CopyrightText>
            © Copyright 2024 LocalPro. All rights reserved.
          </CopyrightText>
        </CopyrightContainer>
      </FooterContainer>
    </MainFooter>
  );
};

export default Footer;