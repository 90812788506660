import {
  Box,
  // Button,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { COLORS } from "../../styles/Theme";
import {
  // ActionBtnBox,
  // BackButton,
  // DistanceCard,
  // DistanceContent,
  // HeaderContainer,
  // MapBox,
  ServiceItem,
  SideHeading,
  StyledContainer,
  StyledSection,
  // WishlistButton,
} from "./ProfessionalDetails.style";
import ProfessionalInfoTile from "./ProfessionalInfoTile";
// import EditIcon from "@mui/icons-material/Edit";
// import ReviewCard from "../../components/Cards/ReviewCard";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
// import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { useNavigate, useLocation, useParams } from "react-router-dom";
// import LogoSmall from "../../components/Logo/LogoSmall";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
import Header from "../../components/UI/DashboardHeader/Header";
import RightSection from "../Dashboard/Home/RightSection";
// import IMAGES from "../../../utils/Data";
import ReviewModal from "./ReviewModal";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { getVendorDetailsApi } from "../../services/api_calls/auth/AuthApi";
import Loader from "../../components/Loader/Loader";
function NotVerified() {
  // const images = [
  //   "./assets/images/washing_machine.png",
  //   "./assets/images/plumber-img.jpg",
  //   "./assets/images/home-banner.png",
  // ];
  const navigate = useNavigate();
  const { serviceSlug, businessSlug } = useParams();
  const location = useLocation();
  const { serviceName, businessName } = location.state || {};
  const handleAddReview = () => {};
  const isDesktop = useMediaQuery("(min-width:960px)");

  const [currentIndex, setCurrentIndex] = useState(0);
  // const [open, setOpen] = useState(false);
  const [vendorDetails, setVendorDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const getBusinessPhotos = () => {
    if (!vendorDetails) return [];
    return vendorDetails.bphotos || [];
  };
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    const fetchVendorDetails = async () => {
      setLoading(true);

      if (!location.state || !location.state.vendor_id) {
        setError("Please select a professional from the list");
        setLoading(false);
        return;
      }

      try {
        const response = await getVendorDetailsApi({
          vendor_id: location.state.vendor_id,
        });

        if (response && response.status) {
          setVendorDetails(response.data);
          setLanguages(
            response.data.languages || ["English", "Hindi", "Kannada"]
          );
        } else {
          setError("Failed to fetch vendor details");
        }
      } catch (err) {
        console.error("Error fetching vendor details:", err);
        setError("An error occurred while fetching vendor details");
      } finally {
        setLoading(false);
      }
    };

    fetchVendorDetails();
  }, [location.state]);
  useEffect(() => {
    if (serviceName && businessName) {
      document.title = `${businessName} - ${serviceName}`; // Combine both names
    } else if (businessName) {
      document.title = businessName;
    } else if (serviceName) {
      document.title = serviceName;
    }
  }, [serviceName, businessName]);
  const handlePrevImage = () => {
    const allImages = getBusinessPhotos();
    const newIndex =
      currentIndex === 0 ? allImages.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const handleNextImage = () => {
    const allImages = getBusinessPhotos();
    const newIndex =
      currentIndex === allImages.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }
  const theme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            maxWidth: "1200px",
          },
        },
      },
    },
  });
  const formatTimings = (timings) => {
    try {
      if (!timings?.default || !timings.default["1"]) {
        return "Not available";
      }

      const dayTiming = timings.default["1"];

      if (
        !dayTiming.start ||
        !dayTiming.end ||
        !dayTiming.start.includes(":") ||
        !dayTiming.end.includes(":")
      ) {
        return "Not available";
      }

      const formatTime = (timeStr) => {
        try {
          const [hoursStr, minutesStr] = timeStr.split(":");

          const hours = parseInt(hoursStr, 10);
          const minutes = parseInt(minutesStr, 10);

          if (
            isNaN(hours) ||
            isNaN(minutes) ||
            hours < 0 ||
            hours > 23 ||
            minutes < 0 ||
            minutes > 59
          ) {
            throw new Error("Invalid time format");
          }
          const period = hours >= 12 ? "PM" : "AM";
          let hours12 = hours % 12;
          hours12 = hours12 === 0 ? 12 : hours12;

          const paddedMinutes = minutes.toString().padStart(2, "0");

          return `${hours12}:${paddedMinutes} ${period}`;
        } catch (error) {
          return "N/A";
        }
      };

      const startTime = formatTime(dayTiming.start);
      const endTime = formatTime(dayTiming.end);
      if (startTime === "N/A" || endTime === "N/A") {
        return "Not available";
      }

      return `${startTime} to ${endTime}`;
    } catch (error) {
      return "Not available";
    }
  };

  const formatAddress = (addressArray) => {
    if (!addressArray || addressArray.length === 0) return "";
    const address = addressArray[0];

    const addressParts = [
      address.buildingName,
      address.baddress,
      address.bcity,
      address.bstate,
      address.pincode,
    ].filter(Boolean);

    return addressParts.join(", ");
  };
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Grid
        container
        spacing={3}
        sx={{
          marginTop: { xs: "0", md: "30px" },
          padding: { xs: "0", md: "80px" },
        }}
      >
        {/* Left Section */}
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <StyledContainer>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {/* Only show navigation buttons if there are multiple business photos */}
              {getBusinessPhotos().length > 1 && (
                <IconButton
                  onClick={handlePrevImage}
                  sx={{
                    position: "absolute",
                    left: 10,
                    zIndex: 1,
                    backgroundColor: "#f0f0f0",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10%",
                    color: "black",
                    "&:hover": {
                      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  <ArrowBackIosNewRoundedIcon />
                </IconButton>
              )}

              {/* Business Photos Carousel */}
              {getBusinessPhotos().length > 0 ? (
                <Box
                  sx={{
                    width: "100%",
                    height: {
                      xs: "250px", // Mobile height
                      sm: "350px", // Tablet height
                      md: "450px", // Desktop height
                    },
                    overflow: "hidden",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <img
                    src={getBusinessPhotos()[currentIndex]}
                    alt="Business"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: {
                      xs: "250px",
                      sm: "350px",
                      md: "450px",
                    },
                    backgroundColor: "#f5f5f5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography color="textSecondary">
                    No business photos available
                  </Typography>
                </Box>
              )}

              {getBusinessPhotos().length > 1 && (
                <IconButton
                  onClick={handleNextImage}
                  sx={{
                    position: "absolute",
                    right: 10,
                    zIndex: 1,
                    backgroundColor: "#f0f0f0",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10%",
                    color: "black",
                    "&:hover": {
                      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  <ArrowForwardIosRoundedIcon />
                </IconButton>
              )}
            </Box>

            {/* <Button
        onClick={() => navigate(-1)}
        sx={{ color: 'black' }}
      >
        <ArrowBackIosNewRoundedIcon fontSize="small" />
      </Button>
      <Box>
      <img
        src="./assets/images/washing_machine.png"
        alt="Technician"
        style={{
          width: '100%',
        }}
      />
      </Box> */}

            <Box m={1}>
              <ProfessionalInfoTile
                imgUrl={vendorDetails?.profileImages?.[0]}
                name={vendorDetails?.bname}
                title={vendorDetails?.bname}
                rating={vendorDetails?.averageRating}
                reviewCount={vendorDetails?.totalReviews}
                address={vendorDetails?.address?.[0].baddress}
                distance={vendorDetails?.distance || ""}
                isNotAvailable={true}
                handleCall={() => {}}
                handleShare={() => {}}
              />

              {/* Address */}
              <StyledSection>
                <Box display="flex" alignItems="center" gap="8px">
                  <Box flex={1}>
                    <Typography
                      variant="body2"
                      fontSize={16}
                      fontWeight={400}
                      lineHeight="20px"
                      color="#5B5B5B"
                      fontFamily={"Poppins, Regular"}
                    >
                      {formatAddress(vendorDetails?.address)}
                    </Typography>
                  </Box>

                  {/* <DistanceCard>
              <MapBox>
                <LogoSmall width={20} height={20} />
              </MapBox>

              <DistanceContent>
                <Typography
                  variant="body2"
                  fontSize={9}
                  fontWeight={400}
                  lineHeight="20px"
                  color={COLORS.white}
                  textAlign="center"
                >
                  <LocationOnIcon
                    sx={{
                      fontSize: 13,
                      color: COLORS.white,
                      m: "0 3px -3px 0",
                    }}
                  />
                  0.5 KM
                </Typography>
              </DistanceContent>
            </DistanceCard> */}
                </Box>
              </StyledSection>
              {/* About US */}
              <StyledSection>
                <SideHeading variant="h6">About Us</SideHeading>

                <Typography
                  variant="body2"
                  fontSize={12}
                  fontWeight={400}
                  lineHeight="20px"
                  color={COLORS.greyShade9}
                >
                  {vendorDetails?.babout || "Information not available"}
                </Typography>
              </StyledSection>
              {/*Timings*/}
              <StyledSection>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "#333",
                          marginBottom: "8px",
                        }}
                      >
                        Working Hours
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          color: "#666",
                          fontWeight: 400,
                        }}
                      >
                        {formatTimings(vendorDetails?.timings)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "#333",
                          marginBottom: "8px",
                        }}
                      >
                        Holiday
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          color: "#666",
                          fontWeight: 400,
                        }}
                      >
                        {vendorDetails?.holiday?.join(", ")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </StyledSection>
              {/* Services */}
              <StyledSection>
                <SideHeading variant="h6">Services</SideHeading>

                <Box m={1}>
                  <Grid container spacing={2}>
                    {vendorDetails?.categories?.map((service, index) => (
                      <Grid item xs={6} key={service.id}>
                        <ServiceItem variant="body2">
                          {service.name}
                        </ServiceItem>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Typography variant="h6" fontWeight="bold" mb={1}>
                    Languages Known
                  </Typography>
                  <LanguageBox>
                    {languages.map((language, index) => (
                      <LanguageText key={index} variant="body2">
                        {language}
                        {index < languages.length - 1 && ", "}
                      </LanguageText>
                    ))}
                  </LanguageBox>
                </Box>
              </StyledSection>
            </Box>
          </StyledContainer>
        </Grid>
        {isDesktop && (
          <RightSection
          // showContactPopup={showContactPopup}
          // setShowContactPopup={setShowContactPopup}
          // handleContactSubmit={handleContactSubmit}
          // showOTPPopup={showOTPPopup}
          // setShowOTPPopup={setShowOTPPopup}
          // otp={otp}
          // setOtp={setOtp}
          // handleOtpSubmit={handleOtpSubmit}
          // showSuccessPopup={showSuccessPopup}
          // setShowSuccessPopup={setShowSuccessPopup}
          />
        )}
      </Grid>
    </ThemeProvider>
  );
}

export default NotVerified;
// const languages = [
//   "Kannada",
//   "English",
//   "Hindi",
//   "Telugu",
//   "Tamil",
//   "Malayalam",
// ];

const LanguageBox = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",
  marginTop: "4px",
});

const LanguageText = styled(Typography)({
  color: "#6B7280", // Light gray color for languages
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline", // Optional: add underline on hover
  },
});
