import styled from "@emotion/styled";
import { Box, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";

function InputText({
  placeholder,
  name,
  value,
  required,
  onChange,
  startIcon,
  endIcon,
  maxLength,
  inputType,
  disabled,
}) {
  return (
    <Box sx={{ mb: 2 }}>
      <StyledTextField
        variant="standard"
        required={required}
        fullWidth
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              {startIcon}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start" sx={{ cursor: "pointer" }}>
              {endIcon}
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        inputProps={{ maxLength: maxLength }}
        type={inputType || "text"}
        maxLength={maxLength}
      />
    </Box>
  );
}

export default InputText;

const StyledTextField = styled(TextField)(
  ({ disabled }) => ({
    boxShadow: "0px 0px 35px -15px rgba(0,0,0,0.13)",

    "&:before": {
      content: '" "',
      width: "5px",
      height: "100%",
      backgroundColor: COLORS.secondaryColor,
      position: "absolute",
      opacity: disabled ? '0.2' : '1'
    },

    "& .MuiInputBase-input": {
      color: COLORS.black,
      fontSize: 14,
      fontWeight: 400,
      padding: "15px",
    },
  })
);
