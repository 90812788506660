import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  IconButton,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled, keyframes } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const slideUp = keyframes`
  from {
    transform: translate(-50%, 100%);
  }
  to {
    transform: translate(-50%, -50%);
  }
`;

const slideDown = keyframes`
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%, 100%);
  }
`;

// Add a global style for background blur
const BlurOverlay = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: "blur(5px)",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  zIndex: theme.zIndex.modal - 1,
  transition: "opacity 0.15s ease",
  opacity: 1,
  "&.fade-out": {
    opacity: 0,
  },
}));

const StyledDialog = styled(Dialog)(({ theme, isexpanded, closing }) => ({
  "& .MuiDialog-container": {
    alignItems: "center",
    zIndex: theme.zIndex.modal + 1,
    position: "relative",
  },
  "& .MuiDialog-paper": {
    animation: closing
      ? `${slideDown} 0.8s ease-out forwards`
      : `${slideUp} 0.3s ease-out`,
    width: "90%",
    maxWidth: "600px",
    margin: "0 auto",
    borderRadius: "16px",
    overflow: "visible",
    maxHeight: isexpanded ? "80vh" : "250px",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
  },
}));

const ExternalCloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "260%",
  left: "calc(50% + 260px)",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  border: "1px solid white",
  width: 40,
  height: 40,
  borderRadius: "50%",
  zIndex: theme.zIndex.modal + 2,
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 24,
  },
}));

const GOOGLE_MAPS_API_KEY = "AIzaSyDXXczVbYGv1aHHQ_o4bL4ptBoj1-0VjDA";

const LocationAutocomplete = ({ open, onClose, onLocationSelect, cities }) => {
  const [inputValue, setInputValue] = useState("");
  const [isDetecting, setIsDetecting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [filteredCities, setFilteredCities] = useState([]);
  const [isClosing, setIsClosing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    if (inputValue) {
      const filtered = cities.filter((city) =>
        city.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredCities(filtered);
      setIsExpanded(filtered.length > 0);
    } else {
      setFilteredCities([]);
      setIsExpanded(false);
    }
  }, [inputValue, cities]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleLocationSelect = (selectedCity) => {
    onLocationSelect(selectedCity.label);
    handleClose();
  };

  const handleClose = () => {
    setIsClosing(true);
    const blurOverlay = document.querySelector(".blur-overlay");
    if (blurOverlay) {
      blurOverlay.classList.add("fade-out");
    }
    onClose();
    setIsClosing(false);
  };

  const handleLocationDetection = () => {
    setIsDetecting(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`
            );
            const data = await response.json();

            if (data.status === "OK" && data.results.length > 0) {
              const fullAddress = data.results[0].formatted_address;
              const addressComponents = data.results[0].address_components;
              const locationDetails = {
                fullAddress,
                area:
                  addressComponents.find(
                    (comp) =>
                      comp.types.includes("sublocality_level_1") ||
                      comp.types.includes("locality")
                  )?.long_name || "",
                city:
                  addressComponents.find((comp) =>
                    comp.types.includes("administrative_area_level_2")
                  )?.long_name || "",
                state:
                  addressComponents.find((comp) =>
                    comp.types.includes("administrative_area_level_1")
                  )?.long_name || "",
                postalCode:
                  addressComponents.find((comp) =>
                    comp.types.includes("postal_code")
                  )?.long_name || "",
              };

              onLocationSelect(fullAddress);
              handleClose();
            }
          } catch (error) {
            console.error("Error fetching location:", error);
          } finally {
            setIsDetecting(false);
          }
        },
        (error) => {
          console.error("Geolocation error:", error);
          setIsDetecting(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      setIsDetecting(false);
    }
  };

  return (
    <>
      {open && <BlurOverlay className="blur-overlay" />}
      <StyledDialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        isexpanded={isExpanded ? 1 : 0}
        closing={isClosing ? 1 : 0}
      >
        <DialogContent
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            position: "relative",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            sx={{ border: "1px solid #e0e0e0", borderRadius: "4px", p: 1 }}
          >
            <IconButton edge="start" color="inherit" aria-label="back">
              <ArrowBackIcon />
            </IconButton>
            <TextField
              inputRef={inputRef}
              autoFocus
              fullWidth
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Search for your location/society/apartment"
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{ ml: 1, "& input": { fontSize: "1rem" } }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            onClick={handleLocationDetection}
            sx={{ cursor: "pointer", py: 1, color: "primary.main" }}
          >
            <MyLocationIcon color="primary" />
            <Typography variant="body1" color="primary" sx={{ ml: 1 }}>
              {isDetecting ? "Detecting location..." : "Use current location"}
            </Typography>
          </Box>
          {isExpanded && (
            <List sx={{ flexGrow: 1, overflowY: "auto", mt: 2 }}>
              {filteredCities.map((city) => (
                <ListItem
                  key={city.id}
                  onClick={() => handleLocationSelect(city)}
                  button
                >
                  <ListItemIcon>
                    <LocationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary={city.label} />
                </ListItem>
              ))}
            </List>
          )}
          <Box mt={2} alignSelf="center">
            <Typography variant="caption" color="text.secondary">
              powered by Google
            </Typography>
          </Box>
        </DialogContent>
      </StyledDialog>
      {open && (
        <ExternalCloseButton
          onClick={handleClose}
          size="medium"
          closing={isClosing ? 1 : 0}
        >
          <CloseIcon />
        </ExternalCloseButton>
      )}
    </>
  );
};

export default LocationAutocomplete;
