import styled from "@emotion/styled";
import { Box, TextareaAutosize } from "@mui/material";
import React from "react";

function TextareaInput({
  placeholder,
  name,
  value,
  required,
  onChange,
  maxLength,
  disabled,
}) {
  return (
    <Box p="5px">
      <StyledTextarea
        name={name}
        placeholder={placeholder}
        value={value}
        required={required}
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
      />
    </Box>
  );
}

export default TextareaInput;

const StyledTextarea = styled(TextareaAutosize)({
  width: "calc(100% - 20px)",
  minHeight: "112px",
  padding: "10px",
  border: "0.5px solid #707070",
  borderRadius: "4px",
  outline: "none",
});
