import styled from "@emotion/styled";
import { Box, Radio, Typography } from "@mui/material";
import React from "react";
import { textOneLine } from "../../styles/Global";
import { COLORS } from "../../styles/Theme";

function ContactPlanCard({ title, subtitle, price, plan, isChecked, onClick }) {
  const getBGColor = (plan) => {
    switch (plan) {
      case "Basic Plan":
        return COLORS.white;
      case "Silver Plan":
        return COLORS.greyShade3;
      case "Gold Plan":
        return COLORS.secondaryColor;
      case "Diamond Plan":
        return COLORS.primaryColor;
      case "Platinum Plan":
        return COLORS.green;

      default:
        return COLORS.primaryColor;
    }
  };

  const getTextColor = (plan) => {
    return plan === "Silver Plan" || plan === "Basic Plan"
      ? COLORS.black
      : COLORS.white;
  };

  return (
    <StyledCard
      checked={isChecked}
      onClick={() => {
        onClick(plan);
      }}
    >
      <Box flex={1}>
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={600}
          color={COLORS.black}
          sx={{ ...textOneLine }}
        >
          {title}
        </Typography>

        <Typography
          variant="body2"
          fontSize={10}
          fontWeight={400}
          color={COLORS.black}
          sx={{ ...textOneLine }}
        >
          {subtitle}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography
          variant="body1"
          fontSize={18}
          fontWeight={600}
          color={COLORS.black}
        >
          ₹ {price}
        </Typography>
        <Radio size="small" checked={isChecked} color="secondary" />
      </Box>

      <PlanTag bgcolor={getBGColor(plan)} checked={isChecked}>
        <Typography
          variant="body1"
          fontSize={9}
          fontWeight={600}
          color={getTextColor(plan)}
        >
          {plan}
        </Typography>
      </PlanTag>
    </StyledCard>
  );
}

export default ContactPlanCard;

const StyledCard = styled(Box)(({ checked }) => ({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  borderRadius: "10px",
  marginBottom: "24px",
  padding: "14px 0px 10px 14px",
  backgroundColor: COLORS.white,
  border: `${
    checked
      ? "1px solid " + COLORS.secondaryColor
      : "1px solid " + COLORS.greyShade5
  }`,
  boxShadow: "0px 1px 20px -5px rgba(0,0,0,0.13)",
  position: "relative",
  cursor:'pointer'
}));

const PlanTag = styled(Box)(({ bgcolor, checked }) => ({
  borderRadius: "5px",
  padding: "0px 15px",
  backgroundColor: bgcolor,
  border: `${
    checked
      ? "1px solid " + COLORS.secondaryColor
      : "1px solid " + COLORS.greyShade5
  }`,
  boxShadow: "0px 1px 20px -5px rgba(0,0,0,0.13)",
  position: "absolute",
  top: -14,
  left: 14,
}));
