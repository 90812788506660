import styled from "@emotion/styled";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";

function SelectInput({
  value,
  onValueChange,
  menuList,
  valueSelector,
  disabled,
  onlyBorderBottom
}) {
  return (
    <Box
      sx={{
        mb:2,
        backgroundColor: COLORS.white,
      }}
    >
      <StyledFormControl borderbottom={onlyBorderBottom}>
        <Select
          value={value}
          onChange={(event) => {
            onValueChange(event.target.value);
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          fullWidth
          disabled={disabled}
        >
          {menuList.map((item, index) => {
            return (
              <MenuItem
                value={valueSelector ? item[valueSelector] : item.name}
                key={"Menu_Item_" + index}
              >
                {valueSelector ? item[valueSelector] : item.name}
              </MenuItem>
            );
          })}
        </Select>
      </StyledFormControl>
    </Box>
  );
}

export default SelectInput;

const StyledFormControl = styled(FormControl)(({borderbottom})=>({
  width: "100%",
  height: '50px',
  borderRadius: borderbottom ? "0px" : "8px",
  border: borderbottom ? "none" : "1px solid #707070",
  borderBottom : borderbottom ? '2px solid #DCDCDC' : '',
  "& .css-hdw1oc": {
    display: "none",
  },

  "& .MuiOutlinedInput-notchedOutline, .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
    {
      border: "none",
    },
}));