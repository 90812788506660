import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  useMediaQuery,
  styled
} from "@mui/material";
import Header from "../../../components/UI/DashboardHeader/Header";
import BottomTabs from "../../../components/UI/BottomTabs";

// Styled Components
const PageContainer = styled(Container)`
 padding: 100px;
  
  @media (max-width: 600px) {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 24px;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }
`;

const SectionTitle = styled(Typography)`
  color: #21608F;
  margin-bottom: 24px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 60px;
    height: 3px;
    background-color: #21608F;
  }
`;

function AboutLocalPro() {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Header />
      <PageContainer maxWidth="lg">
        {/* Hero Section */}
        <Box mb={6} textAlign="center">
          <Box
            component="img"
            src="./assets/images/logo.jpg"
            alt="Local Pro Logo"
            sx={{ 
              width: { xs: 80, sm: 100 },
              mb: 3,
              mt: 2
            }}
          />
          <Typography variant="h3" component="h1" gutterBottom>
            About Local Pro
          </Typography>
          <Typography variant="h6" color="textSecondary" paragraph>
            Connecting Communities with Trusted Local Services
          </Typography>
        </Box>

        {/* Main Content Section */}
        <Grid container spacing={4} mb={6}>
          <Grid item xs={12} md={6}>
            <StyledPaper elevation={3}>
              <SectionTitle variant="h5" gutterBottom>
                Who We Are
              </SectionTitle>
              <Typography paragraph align="justify" color="textSecondary">
                Local Pro is a leading platform that connects skilled local service providers with customers seeking quality services. We believe in empowering local professionals while ensuring customers receive reliable, professional service for all their needs. Our platform serves as a bridge between service excellence and community requirements.
              </Typography>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledPaper elevation={3}>
              <SectionTitle variant="h5" gutterBottom>
                Our Mission
              </SectionTitle>
              <Typography paragraph align="justify" color="textSecondary">
                Our mission is to revolutionize the local services industry by creating a trustworthy ecosystem where skilled professionals can thrive and customers can easily access quality services. We aim to simplify the process of finding and booking reliable local services while supporting the growth of local businesses.
              </Typography>
            </StyledPaper>
          </Grid>
        </Grid>

        {/* Features Section */}
        <Box mb={6}>
          <SectionTitle variant="h4" gutterBottom align="center">
            Why Choose Local Pro
          </SectionTitle>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <StyledPaper elevation={3}>
                <Typography variant="h6" gutterBottom align="center">
                  Verified Professionals
                </Typography>
                <Typography color="textSecondary" align="center">
                  All our service providers undergo thorough verification and quality checks to ensure you receive service from trusted professionals only.
                </Typography>
              </StyledPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledPaper elevation={3}>
                <Typography variant="h6" gutterBottom align="center">
                  Quality Service
                </Typography>
                <Typography color="textSecondary" align="center">
                  We maintain high service standards through regular monitoring, customer feedback, and professional training programs for our service providers.
                </Typography>
              </StyledPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledPaper elevation={3}>
                <Typography variant="h6" gutterBottom align="center">
                  24/7 Support
                </Typography>
                <Typography color="textSecondary" align="center">
                  Our dedicated customer support team is available round the clock to assist you with any queries or concerns about our services.
                </Typography>
              </StyledPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledPaper elevation={3}>
                <Typography variant="h6" gutterBottom align="center">
                  Secure Platform
                </Typography>
                <Typography color="textSecondary" align="center">
                  Experience worry-free service booking with our secure platform, transparent pricing, and protected user information system.
                </Typography>
              </StyledPaper>
            </Grid>
          </Grid>
        </Box>

        {/* Additional Information */}
        <StyledPaper elevation={3}>
          <Typography variant="h5" gutterBottom color="#21608F">
            Join Our Growing Community
          </Typography>
          <Typography paragraph color="textSecondary">
            Whether you're a skilled professional looking to grow your business or a customer seeking reliable services, Local Pro provides the perfect platform to connect, engage, and succeed. Join our growing community today and experience the difference of professional local services.
          </Typography>
        </StyledPaper>
      </PageContainer>
      {isMobile && <BottomTabs activeTab="about" />}
    </>
  );
}

export default AboutLocalPro;