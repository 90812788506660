import styled from "@emotion/styled";
import { Box, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { COLORS } from "../../styles/Theme";

function MessageCard({ title, subtitle, text, datetime, replyMessage, replyDate }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const MESSAGE_LIMIT = 80;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).replace(',', ' at').toLowerCase();
  };

  const truncateText = (text, limit) => {
    if (!text) return '';
    if (text.length <= limit) return text;
    return isExpanded ? text : `${text.substring(0, limit)}...`;
  };

  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const getStatusStyle = (status) => {
    const styles = {
      RESOLVED: {
        backgroundColor: '#E8F5E9',
        color: '#2E7D32',
        textTransform: 'capitalize'
      },
      IN_PROGRESS: {
        backgroundColor: '#F5F5F5',
        color: '#333333',
        textTransform: 'capitalize'
      },
      // Add more status styles as needed
    };
    
    return styles[status] || styles.IN_PROGRESS;
  };

  return (
    <StyledCard>
      <Box p="10px 12px">
        <HeaderSection>
          <Box>
            <Typography
              variant="h3"
              fontSize={15}
              fontWeight={600}
              color={COLORS.primaryColor}
              mb={0.5}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              fontSize={11}
              fontWeight={400}
              color={COLORS.grey}
            >
              {text}
            </Typography>
          </Box>

          <Box sx={{ textAlign: 'right' }}>
            <StatusButton
              variant="contained"
              size="small"
              style={getStatusStyle(subtitle)}
            >
              {subtitle?.toLowerCase()}
            </StatusButton>
            <Typography
              variant="body1"
              fontSize={10}
              fontWeight={500}
              color={COLORS.black}
              mt={0.5}
            >
              {formatDate(datetime)}
            </Typography>
          </Box>
        </HeaderSection>
      </Box>

      <CardContent>
        <ReplySection>
          <Typography
            variant="body2"
            lineHeight="14px"
            sx={{ fontSize: 11, fontWeight: 500 }}
          >
            Replied On 
          </Typography>
          <Typography
            variant="body1"
            fontSize={10}
            fontWeight={400}
            lineHeight="12px"
            color={COLORS.grey}
            flex={1}
            ml={1}
          >
            {truncateText(replyMessage, MESSAGE_LIMIT)}
            {replyMessage && replyMessage.length > MESSAGE_LIMIT && (
              <Typography
                variant="body1"
                component="span"
                onClick={handleReadMore}
                sx={{ 
                  fontSize: 9, 
                  fontWeight: 600, 
                  cursor: 'pointer',
                  ml: 0.5
                }}
              >
                {isExpanded ? 'Show Less' : 'Read More'}
              </Typography>
            )}
          </Typography>
        </ReplySection>

        <Typography
          variant="body2"
          fontSize={9}
          fontWeight={500}
          color={COLORS.grey}
        >
          {formatDate(replyDate)}
        </Typography>
      </CardContent>
    </StyledCard>
  );
}

export default MessageCard;

const StyledCard = styled(Box)({
  borderRadius: "5px",
  backgroundColor: COLORS.white,
  overflow: "hidden",
  boxShadow: "0px 0px 8px 0px rgb(0 0 0 / 18%)",
  marginBottom: '16px'
});

const HeaderSection = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
});

const CardContent = styled(Box)({
  backgroundColor: COLORS.greyShade10,
  padding: "8px 12px",
});

const ReplySection = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '4px'
});

const StatusButton = styled(Button)({
  minWidth: 'unset',
  padding: '2px 8px',
  borderRadius: '4px',
  fontSize: '10px',
  fontWeight: 500,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none'
  }
});