import styled from "@emotion/styled";
import { Box, IconButton } from "@mui/material";
import { COLORS } from "../../../styles/Theme";

export const ProfileContainer = styled(Box)({
  height: "280px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: COLORS.primaryColor,
});

export const CloseButton = styled(IconButton)({
  position: "absolute",
  top: 20,
  right: 20,
  borderRadius: "6px",
  color: COLORS.black,
  backgroundColor: COLORS.greyShade2,

  "&:focus": {
    backgroundColor: COLORS.white,
  },
});

export const ProfileMenu = styled(Box)({
  borderRadius: "15px 15px 0 0",
  backgroundColor: COLORS.white,
  // marginTop: -30
});
