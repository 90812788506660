export const homeSliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  variableWidth: true,
};

// export const serviceSliderSettings = {
//   dots: false,
//   arrows: false,
//   infinite: false,
//   speed: 300,
//   slidesToShow: 4.2,
//   slidesToScroll: 1,
//   swipeToSlide: true,
//   variableWidth: true,
// };
// export const serviceSliderSettings = {
//   dots: false,
//   arrows: false,
//   infinite: false,
//   speed: 300,
//   slidesToShow: 4.2,
//   // slidesToShow: window.innerWidth <= 768 ? 3.2 : 4.2,
//   slidesToScroll: 1,  
//   swipeToSlide: true, 
//   variableWidth: true, 
// };
const slidesToShow = window.innerWidth <= 768 ? 3 : 4;
export const serviceSliderSettings = {
  dots: false,
  infinite: true,
  speed: 700,
  slidesToShow: slidesToShow, 
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4.2, 
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4.2, 
      },
    },
  ],
};
export const servicelist = {
  dots: false,
  infinite: true,
  speed: 700,
  slidesToShow: slidesToShow, 
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4.2, 
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4.2, 
      },
    },
  ],
};