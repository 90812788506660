import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import { COLORS } from "../../../styles/Theme";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

function FileInput({
  label,
  setImage,
  api,
}) {
  const inputRef = useRef(null);

  const triggerFile = () => inputRef.current.click();

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        setImage(reader.result);
        api(event.target.files[0]);
      };
    }
  };

  return (
    <Box sx={{ mb: 2, cursor: "pointer" }} onClick={triggerFile}>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={onSelectFile}
        style={{ display: "none" }}
      />

      <StyledBox>
        <Typography
          variant="body2"
          fontSize={14}
          color="#656565"
          sx={{ ml: 3 }}
        >
          {label}
        </Typography>

        <UploadBox>
          <FileUploadOutlinedIcon />
          <Typography
            variant="body2"
            fontSize={14}
            fontWeight={600}
            color="#656565"
            sx={{ ml: 1 }}
          >
            Upload
          </Typography>
        </UploadBox>
      </StyledBox>
    </Box>
  );
}

export default FileInput;

const StyledBox = styled(Box)({
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 0px 35px -15px rgba(0,0,0,0.13)",

  "&:before": {
    content: '" "',
    width: "5px",
    height: "50px",
    backgroundColor: COLORS.secondaryColor,
    position: "absolute",
  },
});

const UploadBox = styled(Box)({
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#E5E5E5",
  padding: "0 20px",
});
