// import { Box, Typography,Grid,createTheme,ThemeProvider,} from "@mui/material";
// import React, { useState } from "react";
// import {
//   ActionMenuBox,
//   BackIconButton,
//   MapContainer,
//   MenuButton,
//   MessageBox,
// } from "./ServiceDetails.style";
// import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
// import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
// import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
// import { NEARBY_PROFESSIONALS, SERVICE_DATA_LIST } from "../../utils/Data";
// import ServiceTileMinimal from "./ServiceTileMinimal";
// import Slider from "react-slick";
// import { serviceSliderSettings } from "../../utils/SlickConfigs";
// import { useNavigate } from "react-router-dom";
// import ProfessionalInfoCard from "../../components/Cards/ProfessionalInfoCard";
// import ModalCard from "../../components/UI/Modal/ModalCard";
// import LocationModalContent from "./LocationModalContent";
// import FilterModalContent from "./FilterModalContent";
// import CategoryModalContent from "./CategoryModalContent";
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import { COLORS } from "../../styles/Theme";
// import GoogleMapReact from "google-map-react";
// import Header from "../../components/UI/DashboardHeader/Header";
// import RightSection from "../Dashboard/Home/RightSection";

// function ServiceDetails() {
//   const navigate = useNavigate();
//   const [selectedService, setSelectedService] = useState("All");

//   const [openLocationMenu, setOpenLocationMenu] = useState(false);
//   const [openCategoryMenu, setOpenCategoryMenu] = useState(false);
//   const [openFilterMenu, setOpenFilterMenu] = useState(false);

//   const handleBack = () => {
//     navigate(-1);
//   };

//   const handleLocation = () => {
//     setOpenLocationMenu(true);
//   };

//   const handleCategory = () => {
//     setOpenCategoryMenu(true);
//   };

//   const handleFilter = () => {
//     setOpenFilterMenu(true);
//   };

//   const handleServiceChange = (value) => {
//     setSelectedService(value);
//   };

//   const handleViewProfessional = () => {
//     navigate("/professionalDetails");
//   };

//   const defaultProps = {
//     center: {
//       lat: 12.9716,
//       lng: 77.5946,
//     },
//     zoom: 11,
//   };
//   const theme = createTheme({
//     components: {
//       MuiContainer: {
//         styleOverrides: {
//           maxWidthLg: {
//             maxWidth: '1200px',

//            },
//         },
//       },
//     },
//   });
//   return (
//     <ThemeProvider theme={theme}>
//     <Header/>
//     <Grid
//       container
//       spacing={3}
//       sx={{
//         marginTop: { xs: '0', md: '30px' },
//         padding: { xs: '0', md: '80px' },
//       }}
//     >
//         <Grid item xs={12} md={6}>
//     <Box>
//       <MapContainer>
//         <Box sx={{ width: "450px", height: "300px" }}>
//           {/* key: "AIzaSyDWkNTFYErrmPTv7VwC22Zl7gY14uU4k7g" }}*/}

//           <GoogleMapReact
//             bootstrapURLKeys={{
//               key: "AIzaSyCjcIh4rzaoDV5pWhuGUjmpxuSLEF1YLtc",
//             }}
//             defaultCenter={defaultProps.center}
//             defaultZoom={defaultProps.zoom}
//           >
//             <LocationMarker lat={12.9716} lng={77.5946} text="My Marker" />
//             <LocationMarker lat={12.9916} lng={77.5706} text="My Marker" />
//             <LocationMarker lat={12.9616} lng={77.5406} text="My Marker" />
//           </GoogleMapReact>
//         </Box>

//         <ActionMenuBox>
//           <BackIconButton onClick={handleBack}>
//             <ArrowBackIosNewRoundedIcon sx={{ fontSize: "20px" }} />
//           </BackIconButton>

//           <Box display="flex" alignItems="center" gap="10px">
//             <MenuButton
//               size="small"
//               onClick={handleLocation}
//               endIcon={<ExpandMoreRoundedIcon />}
//             >
//               Location
//             </MenuButton>

//             <MenuButton
//               size="small"
//               onClick={handleCategory}
//               endIcon={<ExpandMoreRoundedIcon />}
//             >
//               Category
//             </MenuButton>

//             <MenuButton
//               size="small"
//               onClick={handleFilter}
//               startIcon={<TuneRoundedIcon />}
//             >
//               Filter
//             </MenuButton>
//           </Box>
//         </ActionMenuBox>
//       </MapContainer>

//       <MessageBox>
//         <Typography
//           variant="body2"
//           fontSize={13}
//           fontWeight={400}
//           textAlign="center"
//         >
//           Nearby Home Appliance Service Pro's
//         </Typography>
//       </MessageBox>

//       <Box>
//         <Slider {...serviceSliderSettings}>
//           {SERVICE_DATA_LIST?.map((item, index) => (
//             <ServiceTileMinimal
//               key={"service_" + index}
//               imgUrl={item?.icon}
//               name={item?.label}
//               isSelected={selectedService === item?.label}
//               onClick={() => handleServiceChange(item?.label)}
//             />
//           ))}
//         </Slider>
//       </Box>

//       <Box m={1.5}>
//         {NEARBY_PROFESSIONALS.map((item, index) => {
//           return (
//             <ProfessionalInfoCard
//               key={"nearby_prof_" + index}
//               imgUrl={item?.profileImg}
//               name={item?.name}
//               title={item?.businessName}
//               rating={item?.rating}
//               reviewCount={item?.reviewCount}
//               caterogy={item?.caterogy}
//               timings={item?.timings}
//               address={item?.address}
//               distance={item?.distance}
//               username="Basavaraj"
//               userRating={3}
//               isNotAvailable={index === 1}
//               showReviewContent
//               onContantClick={handleViewProfessional}
//               spacing="0 0 16px"
//             />
//           );
//         })}
//       </Box>

//       <ModalCard open={openLocationMenu}>
//         <LocationModalContent
//           handleReset={() => {}}
//           handleClose={() => {
//             setOpenLocationMenu(false);
//           }}
//         />
//       </ModalCard>

//       <ModalCard open={openFilterMenu}>
//         <FilterModalContent
//           handleReset={() => {}}
//           handleClose={() => {
//             setOpenFilterMenu(false);
//           }}
//         />
//       </ModalCard>

//       <ModalCard open={openCategoryMenu}>
//         <CategoryModalContent
//           handleClose={() => {
//             setOpenCategoryMenu(false);
//           }}
//         />
//       </ModalCard>
//     </Box>
//     </Grid>
// </Grid>
// <RightSection
//           // showContactPopup={showContactPopup}
//           // setShowContactPopup={setShowContactPopup}
//           // handleContactSubmit={handleContactSubmit}
//           // showOTPPopup={showOTPPopup}
//           // setShowOTPPopup={setShowOTPPopup}
//           // otp={otp}
//           // setOtp={setOtp}
//           // handleOtpSubmit={handleOtpSubmit}
//           // showSuccessPopup={showSuccessPopup}
//           // setShowSuccessPopup={setShowSuccessPopup}
//         />
// </ThemeProvider>
//   );
// }

// export default ServiceDetails;

// const LocationMarker = ()=>{
//   return <LocationOnIcon fontSize="large" sx={{color: COLORS.red}} />
// }
import {
  Box,
  Typography,
  Grid,
  createTheme,
  ThemeProvider,
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
} from "@mui/material";

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { MyLocation } from "@mui/icons-material";

import Header from "../../components/UI/DashboardHeader/Header";
import Footer from "../../components/UI/Footer/Footer";
import RightSection from "../Dashboard/Home/RightSection";
import BottomTabs from "../../components/UI/BottomTabs";
import ServiceTileMinimal from "./ServiceTileMinimal";
import ProfessionalInfoCard from "../../components/Cards/ProfessionalInfoCard";
import ModalCard from "../../components/UI/Modal/ModalCard";
import LocationModalContent from "./LocationModalContent";
import FilterModalContent from "./FilterModalContent";
import CategoryModalContent from "./CategoryModalContent";

import Slider from "react-slick";

import {
  getCategoryDataApi,
  createUserEnquiryApi,
  getVendorListApi,
  getCityDataApi,
  getRegisterApi,
  addToWishlistApi,
  getWishlistApi,
  createCallApi,
} from "../../services/api_calls/auth/AuthApi";

import { serviceSliderSettings } from "../../utils/SlickConfigs";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import { COLORS } from "../../styles/Theme";
// import GoogleMapReact from "google-map-react";

import { useMediaQuery } from "@mui/material";
import {
  createUrlSlug,
  generateSlug,
  createCitySlug,
} from "../../utils/urlUtils";
const generateServiceList = (city, categories, selectedService) => {
  if (!city || !city.subCity || !categories || !selectedService) {
    return [];
  }
  const serviceList = [];
  serviceList.push(`${selectedService} in ${city.name}`);
  city.subCity.forEach((subcity) => {
    if (subcity.name) {
      serviceList.push(`${selectedService} in ${subcity.name}`);
    }
  });
  return serviceList;
};
const extractLocation = (serviceName) => {
  const parts = serviceName.split(" in ");
  return parts.length > 1 ? parts[1] : null;
};

// Helper function to extract base service from service name
const extractBaseService = (serviceName) => {
  const parts = serviceName.split(" in ");
  return parts[0];
};

function ServiceDetails() {
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const { citySlug, serviceSlug, locationSlug } = useParams();
  const location = useLocation();
  const [selectedService, setSelectedService] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [vendorsLoading, setVendorsLoading] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [openLocationMenu, setOpenLocationMenu] = useState(false);
  const [openCategoryMenu, setOpenCategoryMenu] = useState(false);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [otp, setOtp] = useState("");
  const isDesktop = useMediaQuery("(min-width:960px)");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState({});
  const [cities, setCities] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [cityError, setCityError] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [serviceslist, setServicesList] = useState([]);
  const [currentCity, setCurrentCity] = useState(() => {
    return localStorage.getItem("selectedCity") || "Bangalore";
  });
  const serviceName = location.state?.selectedService;
  const [wishlist, setWishlist] = useState([]);
  const handleBack = () => {
    navigate(-1);
  };

  const handleLocation = () => {
    setOpenLocationMenu(true);
  };

  const handleCategory = () => {
    setOpenCategoryMenu(true);
  };

  const handleFilter = () => {
    setOpenFilterMenu(true);
  };

  const handleServiceChange = (serviceName, serviceId) => {
    if (!serviceName) {
      console.error("Invalid service name");
      return;
    }
    const pathParts = window.location.pathname.split("/");
    const citySlug = pathParts[1];

    setSelectedService(serviceName);
    setSelectedServiceId(serviceId);

    try {
      const serviceSlug = createUrlSlug(serviceName);

      const newUrl = `/${citySlug}/${serviceSlug}`;

      navigate(newUrl, {
        replace: true,
        state: {
          selectedService: serviceName,
          selectedServiceId: serviceId,
        },
      });
    } catch (error) {
      console.error("Error creating service slug:", error);
    }
  };

  const filteredVendors = vendors.filter((vendor) => {
    const matchesService = vendor.categories?.some(
      (category) => category.toLowerCase() === selectedService?.toLowerCase()
    );

    // Get location from URL path
    const pathParts = window.location.pathname.split("/");
    const currentLocationSlug = pathParts[3];

    if (!currentLocationSlug) {
      return matchesService;
    }

    const locationName = currentLocationSlug.replace(/_/g, " ").toLowerCase();
    const matchesLocation = vendor.address?.some(
      (addr) =>
        addr.baddress?.toLowerCase().includes(locationName) ||
        addr.bcity?.toLowerCase().includes(locationName) ||
        addr.barea?.toLowerCase().includes(locationName)
    );

    return matchesService && matchesLocation;
  });
  // const handleViewProfessional = () => {
  //   navigate("/professionalDetails");
  // };

  const handleViewProfessional = (professional) => {
    return () => {
      if (!professional || !professional.bname) {
        console.error("Invalid professional data");
        return;
      }

      const vendor_id = professional.id;
      const serviceSlug = createUrlSlug(selectedService);
      const businessSlug = createUrlSlug(professional.bname);
      const businessName = professional.bname;

      const currentPath = window.location.pathname;
      const pathParts = currentPath.split("/");
      const citySlug = pathParts[1];
      const locationSlug = pathParts[3];

      let basePath = `/${citySlug}/${serviceSlug}`;

      if (locationSlug && !locationSlug.startsWith("business")) {
        basePath += `/${locationSlug}`;
      }

      basePath += `/business/${businessSlug}`;

      const commonState = {
        vendor_id,
        businessName,
        serviceName: selectedService,
        citySlug,
        locationSlug:
          locationSlug && locationSlug !== "business" ? locationSlug : null,
        prevPath: currentPath,
      };

      if (professional.status === 4) {
        navigate(basePath, {
          state: {
            ...commonState,
            selectedServiceId,
            isVerified: true,
          },
        });
      } else {
        navigate(`${basePath}/preview`, {
          state: {
            ...commonState,
            isVerified: false,
          },
        });
      }
    };
  };

  const handleOtpSubmit = () => {
    setShowOTPPopup(false);
    setShowSuccessPopup(true);
  };
  const handleContactSubmit = (formValues) => {
    setShowContactPopup(false);
    setShowOTPPopup(true);
  };
  const defaultProps = {
    center: {
      lat: 12.9716,
      lng: 77.5946,
    },
    zoom: 11,
  };

  const theme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            maxWidth: "1200px",
          },
        },
      },
    },
  });
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Parse the JWT token to get user info
      try {
        const tokenPayload = JSON.parse(atob(token.split(".")[1]));
        if (tokenPayload.user && tokenPayload.user[0]) {
          const user = tokenPayload.user[0];
          setName(user.name || "");
          setMobile(user.mobile || "");
          setLocationValue(user.location || "");
        }
      } catch (error) {
        console.error("Error parsing token:", error);
      }
    }
  }, []);
  const handleSubmit = async () => {
    const finalLocation = locationValue || currentCity;
    const postData = {
      name,
      mobile,
      service_id: selectedServiceId,
      location: finalLocation,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await createUserEnquiryApi(postData);
      console.log(response);
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting the enquiry:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (location.state?.selectedService) {
      setSelectedService(location.state.selectedService);
      setSelectedServiceId(location.state.selectedServiceId);
    }
    setLoading(false);
  }, [location.state]);
  const fetchVendors = async (serviceId) => {
    setVendorsLoading(true);
    try {
      const response = await getVendorListApi({ service_id: serviceId });
      if (response?.status && response?.vendors) {
        let filteredVendors = response.vendors;

        // Get location from URL
        const pathParts = window.location.pathname.split("/");
        const currentLocationSlug = pathParts[3];

        if (currentLocationSlug) {
          const locationName = currentLocationSlug
            .replace(/_/g, " ")
            .toLowerCase();
          filteredVendors = filteredVendors.filter((vendor) =>
            vendor.address?.some(
              (addr) =>
                addr.baddress?.toLowerCase().includes(locationName) ||
                addr.barea?.toLowerCase().includes(locationName) ||
                addr.bcity?.toLowerCase().includes(locationName)
            )
          );
        }

        setVendors(filteredVendors);
      }
    } catch (error) {
      console.error("Error fetching vendors:", error);
    } finally {
      setVendorsLoading(false);
    }
  };
  useEffect(() => {
    if (selectedServiceId) {
      fetchVendors(selectedServiceId);
    }
  }, [selectedServiceId]);
  useEffect(() => {}, [vendors]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await getCategoryDataApi();

        if (response?.status && response?.categories) {
          setCategories(response.categories);
        } else {
          setError("Failed to fetch categories");
        }
      } catch (err) {
        setError("Error fetching categories: " + err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  const subCategories = Array.isArray(categories)
    ? categories
        .filter((category) => [1, 2, 3, 4, 5, 6, 7].includes(category.position))
        .flatMap((category) => category.subCategory)
    : [];
  useEffect(() => {
    if (selectedService && sliderRef.current) {
      const selectedIndex = subCategories.findIndex(
        (service) => service.name === selectedService
      );
      if (selectedIndex !== -1) {
        sliderRef.current.slickGoTo(selectedIndex);
      }
    }
  }, [selectedService, subCategories]);
  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      try {
        // Get service name either from state or URL
        if (
          location.state?.selectedService &&
          location.state?.selectedServiceId
        ) {
          setSelectedService(location.state.selectedService);
          setSelectedServiceId(location.state.selectedServiceId);
        } else if (serviceSlug && subCategories.length > 0) {
          const foundService = subCategories.find(
            (service) => createUrlSlug(service.name) === serviceSlug
          );
          if (foundService) {
            setSelectedService(foundService.name);
            setSelectedServiceId(foundService.id);
          }
        }

        // Set city
        if (citySlug) {
          const cityName = citySlug.replace(/_/g, " ");
          setCurrentCity(cityName);
          localStorage.setItem("selectedCity", cityName);
        }
      } catch (error) {
        console.error("Error initializing data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (subCategories.length > 0) {
      initializeData();
    }
  }, [serviceSlug, citySlug, subCategories, location.state]);
  useEffect(() => {
    if (serviceName) {
      document.title = serviceName;
    }
  }, [serviceName]);
  useEffect(() => {
    const fetchCities = async () => {
      setCityLoading(true);
      setCityError(null);
      try {
        const response = await getCityDataApi();

        if (!response) {
          throw new Error("No response received from the server");
        }

        if (response.status !== true) {
          throw new Error(response.message || "Failed to fetch cities");
        }

        if (!response.cities || !Array.isArray(response.cities)) {
          throw new Error("Invalid cities data received");
        }

        const sortedCities = response.cities.sort(
          (a, b) => a.position - b.position
        );
        setCities(sortedCities);
      } catch (err) {
        console.error("Error in fetchCities:", err);
        setCityError(
          err.message || "Failed to fetch cities. Please try again later."
        );
      } finally {
        setCityLoading(false);
      }
    };

    fetchCities();
  }, []);
  const handleCityClick = (city) => {
    const citySlug = createUrlSlug(city.name);
    localStorage.setItem("selectedCity", city.name);
    let navigationPath = `/${citySlug}`;
    if (selectedService) {
      const serviceSlug = createUrlSlug(selectedService);
      navigationPath += `/${serviceSlug}`;
    }
    const event = new CustomEvent("cityChanged", {
      detail: { city: city.name, citySlug },
    });
    window.dispatchEvent(event);

    // Navigate to the new path
    navigate(navigationPath, {
      state: {
        selectedService,
        selectedServiceId,
        cityName: city.name,
      },
    });
  };
  useEffect(() => {
    const urlCity = citySlug
      ? cities.find((city) => createUrlSlug(city.name) === citySlug)?.name
      : null;

    if (urlCity) {
      localStorage.setItem("selectedCity", urlCity);
    }
  }, [citySlug, cities]);
  useEffect(() => {
    const handleCityChange = (event) => {
      const { city } = event.detail;
      setCurrentCity(city);
    };

    window.addEventListener("cityChanged", handleCityChange);
    return () => window.removeEventListener("cityChanged", handleCityChange);
  }, []);

  useEffect(() => {
    if (cities.length > 0 && categories.length > 0) {
      const selectedCity = cities.find(
        (city) => city.name.toLowerCase() === currentCity.toLowerCase()
      );

      if (selectedCity && selectedService) {
        const servicesList = generateServiceList(
          selectedCity,
          categories,
          selectedService
        );
        setServicesList(servicesList);
      }
    }
  }, [cities, categories, currentCity, selectedService]);

  const handleServiceClick = (service) => {
    const parts = service.split(" in ");
    const baseService = parts[0];
    const location = parts.length > 1 ? parts[1] : null;

    const citySlug = createUrlSlug(currentCity);
    const serviceSlug = createUrlSlug(baseService);
    const locationSlug = location ? createUrlSlug(location) : "";

    let url = `/${citySlug}/${serviceSlug}`;
    if (locationSlug) {
      url += `/${locationSlug}`;
    }

    navigate(url, {
      state: {
        selectedService: baseService,
        cityName: currentCity,
        location: location,
      },
    });
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      setLoading(true);
      try {
        const response = await getRegisterApi();
        if (response && response.user) {
          setName(response.user.name || "");
          setMobile(response.user.mobile || "");
        }
      } catch (err) {
        console.error("Error fetching profile:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const token = localStorage.getItem("token");
    if (token) {
      fetchUserProfile();
    }
  }, []);
  useEffect(() => {
    fetchWishlist();
  }, []);

  const fetchWishlist = async () => {
    try {
      const response = await getWishlistApi();
      if (response.status) {
        setWishlist(response.data);
      }
    } catch (error) {
      console.error("Error fetching wishlist:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Grid
        container
        spacing={3}
        sx={{ marginTop: { md: "30px" }, padding: { md: "80px" } }}
      >
        {/* Left Section */}
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <Box>
            {/* <MapContainer>
              <Box sx={{ width: "450px", height: "300px" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyCjcIh4rzaoDV5pWhuGUjmpxuSLEF1YLtc",
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  <LocationMarker lat={12.9716} lng={77.5946} text="My Marker" />
                  <LocationMarker lat={12.9916} lng={77.5706} text="My Marker" />
                  <LocationMarker lat={12.9616} lng={77.5406} text="My Marker" />
                </GoogleMapReact>
              </Box>

              <ActionMenuBox>
                <BackIconButton onClick={handleBack}>
                  <ArrowBackIosNewRoundedIcon sx={{ fontSize: "20px" }} />
                </BackIconButton>

                <Box display="flex" alignItems="center" gap="10px">
                  <MenuButton
                    size="small"
                    onClick={handleLocation}
                    endIcon={<ExpandMoreRoundedIcon />}
                  >
                    Location
                  </MenuButton>

                  <MenuButton
                    size="small"
                    onClick={handleCategory}
                    endIcon={<ExpandMoreRoundedIcon />}
                  >
                    Category
                  </MenuButton>

                  <MenuButton
                    size="small"
                    onClick={handleFilter}
                    startIcon={<TuneRoundedIcon />}
                  >
                    Filter
                  </MenuButton>
                </Box>
              </ActionMenuBox>
            </MapContainer> */}
            {!submitted ? (
              <Box
                sx={{
                  background:
                    "linear-gradient(to bottom, #20608F 0%, #20608F 50%, #103048 100%)",
                  color: "white",
                  padding: { xs: "15px", md: "33px" },
                  maxWidth: "600px",
                  // height:{md:'290px'},
                  height: {
                    xs: "346px",
                    sm: "302px",
                    md: "290px",
                  },
                  margin: "0",
                }}
              >
                <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                  {selectedService ? selectedService : "Select a Service"}
                </Typography>

                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      placeholder="Name"
                      fullWidth
                      sx={{ backgroundColor: "white" }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="/./assets/images/formicons/user.png"
                              alt="user icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        style: { fontSize: "0.685rem" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      placeholder="Mobile Number"
                      fullWidth
                      sx={{ backgroundColor: "white" }}
                      value={mobile}
                      onChange={(e) => {
                        const value = e.target.value;
                        const numericValue = value.replace(/\D/g, "");
                        if (numericValue.length <= 10) {
                          setMobile(numericValue);
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="/./assets/images/formicons/Path 3693.svg"
                              alt="mobile icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        style: { fontSize: "0.685rem" },
                        maxLength: 10, // Enforce a max length of 10 digits
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={6}>
                    <TextField
                      placeholder="Your Location"
                      fullWidth
                      sx={{ backgroundColor: "white" }}
                      value={locationValue || currentCity}
                      onChange={(e) => setLocationValue(e.target.value)}
                      onFocus={() => {
                        if (!locationValue) {
                          setLocationValue(currentCity);
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="/./assets/images/formicons/Group 5374.svg"
                              alt="address icon"
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <MyLocation
                              style={{ color: "green", cursor: "pointer" }}
                              onClick={() => {
                                setLocationValue(currentCity);
                                // Optionally, you can also trigger a re-render or update other state variables
                                // to ensure the new location value is reflected in the UI
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        style: { fontSize: "0.685rem" },
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: "#21608F",
                        color: "white",
                        width: "100%",
                        padding: "10px",
                        height: {
                          md: "50px",
                          xs: "50px",
                        },
                        borderColor: "#fff",
                        "&:hover": {
                          backgroundColor: "#21608F",
                        },
                      }}
                      onClick={handleSubmit}
                    >
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>

                <Box sx={{ marginTop: "30px" }}>
                  <Grid container spacing={2}>
                    {[1, 2, 3].map((number) => (
                      <Grid item xs={4} key={number}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Box
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              border: "1px Solid white",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "5px",
                              fontWeight: "bold",
                            }}
                          >
                            {number}
                          </Box>
                          <Typography variant="body2" textAlign="center">
                            {number === 1 && "Submit Your Details."}
                            {number === 2 &&
                              "Get call from 3 Verified nearby Pro’s."}
                            {number === 3 && "Quickly compare With Others."}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            ) : (
              // Confirmation Screen (after submit)
              <Box
                sx={{
                  background:
                    "linear-gradient(to bottom, #20608F 0%, #103048 100%)",
                  color: "white",
                  padding: "40px",
                  maxWidth: "600px",
                  margin: "0 auto",
                  height: "290px",
                  textAlign: "center",
                  display: "flex", // Use Flexbox for centering
                  flexDirection: "column", // Stack items vertically
                  justifyContent: "center", // Center items vertically
                  alignItems: "center", // Center items horizontally
                }}
              >
                <Box>
                  <img
                    src="/./assets/images/Group 5160.png"
                    alt="Success"
                    style={{
                      width: "80px",
                      height: "80px",
                      marginBottom: "20px",
                    }}
                  />
                </Box>

                <Typography variant="body1">
                  Hi {name}, <br />
                  Your {selectedService} Request has been received. <br />
                  Our Local Pro will get back to you soon.
                </Typography>
              </Box>
            )}
            <Box>
              <Slider ref={sliderRef} {...serviceSliderSettings}>
                {subCategories.map((subCat) => (
                  <ServiceTileMinimal
                    key={subCat?.id}
                    imgUrl={subCat?.image}
                    name={subCat?.name}
                    serviceId={subCat?.id}
                    isSelected={selectedService === subCat?.name}
                    onClick={() =>
                      handleServiceChange(subCat?.name, subCat?.id)
                    }
                  />
                ))}
              </Slider>
            </Box>

            <Box m={1.5}>
              {vendorsLoading ? (
                <Box sx={{ textAlign: "center", py: 3 }}>
                  <CircularProgress />
                </Box>
              ) : filteredVendors.length > 0 ? (
                [...filteredVendors]
                  .sort((a, b) => {
                    // Sort by subscription status and verification status
                    if (
                      a.subscription &&
                      a.status === 4 &&
                      (!b.subscription || b.status !== 4)
                    )
                      return -1;
                    if (
                      (!a.subscription || a.status !== 4) &&
                      b.subscription &&
                      b.status === 4
                    )
                      return 1;
                    if (a.status === 4 && b.status !== 4) return -1;
                    if (a.status !== 4 && b.status === 4) return 1;
                    return 0;
                  })
                  .map((vendor, index) => {
                    const timing = vendor.timings?.default[1];
                    const timingString = timing
                      ? `${timing.start || "N/A"} - ${timing.end || "N/A"} `
                      : "N/A";
                    const address = vendor.address?.[0];
                    const formattedAddress = address
                      ? `${address.baddress || "N/A"}, ${
                          address.bcity || "N/A"
                        }, ${address.bstate || "N/A"} ${
                          address.pincode || "N/A"
                        }`
                      : "N/A";

                    const isVerified = vendor.status === 4;
                    const isSubscribed = vendor.subscription;
                    const imageUrl =
                      vendor.profileImages && vendor.profileImages.length > 0
                        ? typeof vendor.profileImages[0] === "string"
                          ? vendor.profileImages[0]
                          : vendor.profileImages[0]?.url ||
                            vendor.profileImages[0]?.path
                        : "/path/to/default-image.png";

                    const isWishlisted = wishlist.some(
                      (item) => item.id === vendor.id
                    );
                    return (
                      <div key={index} className="professional-tile">
                        {vendor.bname ? (
                          <ProfessionalInfoCard
                            key={`vendor_${index}`}
                            imgUrl={imageUrl}
                            name={vendor.bname || ""}
                            title={vendor.bname || ""}
                            rating={vendor.Rating || ""}
                            reviewCount={vendor.totalReviews || ""}
                            services={vendor.categories || []}
                            timings={timingString}
                            address={formattedAddress}
                            distance={vendor.distance || ""}
                            isVerified={isVerified}
                            isSubscribed={isSubscribed}
                            username="Basavaraj"
                            userRating={3}
                            isNotAvailable={false}
                            showReviewContent={Boolean(vendor.bname)}
                            onContantClick={handleViewProfessional(
                              vendor,
                              isVerified
                            )}
                            spacing="0 0 16px"
                            businessName={vendor.bname || "N/A"}
                            phoneNumber={vendor.bmobile || "N/A"}
                            vendor_id={vendor.id}
                            service_id={selectedServiceId}
                            addToWishlistApi={addToWishlistApi}
                            getWishlistApi={getWishlistApi}
                            isWishlisted={isWishlisted}
                            createCallApi={createCallApi} 
                          />
                        ) : (
                          <img
                            src={vendor.profileImages?.[0] || ""}
                            alt="Vendor"
                            style={{ width: "100%", height: "35vh" }}
                          />
                        )}
                      </div>
                    );
                  })
              ) : (
                <Box sx={{ textAlign: "center", py: 3 }}>
                  <Typography variant="body1">
                    No professionals found for{" "}
                    {selectedService || "this service"}
                  </Typography>
                </Box>
              )}
            </Box>

            <ModalCard open={openLocationMenu}>
              <LocationModalContent
                handleReset={() => {}}
                handleClose={() => {
                  setOpenLocationMenu(false);
                }}
              />
            </ModalCard>
            <ModalCard open={openFilterMenu}>
              <FilterModalContent
                handleReset={() => {}}
                handleClose={() => {
                  setOpenFilterMenu(false);
                }}
              />
            </ModalCard>
            <ModalCard open={openCategoryMenu}>
              <CategoryModalContent
                handleClose={() => {
                  setOpenCategoryMenu(false);
                }}
              />
            </ModalCard>
          </Box>
        </Grid>

        {/* Right Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            position: "sticky",
            top: "80px",
            alignSelf: "flex-start",
          }}
        >
          {isDesktop && (
            <RightSection
              showContactPopup={showContactPopup}
              setShowContactPopup={setShowContactPopup}
              handleContactSubmit={handleContactSubmit}
              showOTPPopup={showOTPPopup}
              setShowOTPPopup={setShowOTPPopup}
              otp={otp}
              setOtp={setOtp}
              handleOtpSubmit={handleOtpSubmit}
              showSuccessPopup={showSuccessPopup}
              setShowSuccessPopup={setShowSuccessPopup}
            />
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          marginTop: "20px",
          padding: { xs: "15px", md: "20px 100px" },
          backgroundColor: "#f7f7f7",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ marginTop: "40px", marginBottom: "20px", fontWeight: "bold" }}
        >
          Popular Cities
        </Typography>

        {cityLoading ? (
          <CircularProgress />
        ) : cityError ? (
          <Typography color="error">{cityError}</Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              color: "#666666",
            }}
          >
            {cities.map((city, index) => (
              <React.Fragment key={city.id || index}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "13px",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#21608F",
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => handleCityClick(city)}
                >
                  {city.name}
                </Typography>
                {index < cities.length - 1 && (
                  <Typography
                    component="span"
                    sx={{ fontSize: "13px", color: "#666666", marginX: "4px" }}
                  >
                    |
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </Box>
        )}

        <Typography
          variant="h6"
          component="div"
          sx={{ marginBottom: "20px", fontWeight: "bold", marginTop: "40px" }}
        >
          Our Services in {currentCity}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            color: "#666666",
          }}
        >
          {serviceslist.map((service, index) => (
            <React.Fragment key={index}>
              <Typography
                component="span"
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#21608F",
                    textDecoration: "underline",
                  },
                }}
                onClick={() => handleServiceClick(service)}
              >
                {service}
              </Typography>
              {index < serviceslist.length - 1 && (
                <Typography
                  component="span"
                  sx={{ fontSize: "13px", color: "#666666", marginX: "4px" }}
                >
                  |
                </Typography>
              )}
            </React.Fragment>
          ))}
        </Box>
      </Box>
      <Footer></Footer>
      {isMobile && <BottomTabs activeTab="home" />}
    </ThemeProvider>
  );
}

export default ServiceDetails;

const LocationMarker = () => {
  return <LocationOnIcon fontSize="large" sx={{ color: COLORS.red }} />;
};
