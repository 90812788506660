import { Box } from "@mui/material";
import React from "react";
import ReviewCard from "../../../components/Cards/ReviewCard";
import Header from "../../../components/UI/Header";

function Reviews() {
  return (
    <Box>
      <Header title="Reviews" />

      <Box m="20px 10px 10px">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 11, 12, 13].map((item, index) => (
          <ReviewCard
            key={"reviews_" + index}
            imgUrl="https://images.pexels.com/photos/3785079/pexels-photo-3785079.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            name="Sharath N"
            date="Dec 12, 2022"
            rating={3.5}
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            onClick={() => {}}
          />
        ))}
      </Box>
    </Box>
  );
}

export default Reviews;
