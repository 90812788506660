import React from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';

function SuccessModal({ open, handleClose,username, serviceName }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: {
            xs: '90%',
            sm: '400px',
          },
          maxWidth: 'none',
        }
      }}
    >
      <Box
        p={{
          xs: 2,
          sm: 3,
        }}
        textAlign="center"
      >
        <img
          src="/./assets/images/check.svg"
          alt="Success Checkmark"
          style={{
            width: '70px',
            height: '70px',
            marginBottom: '16px',
          }}
        />

        <Typography
          mb={2}
          sx={{
            fontSize: {
              xs: '14px',
              sm: '16px',
            },
            color: 'black',
            fontWeight: 'medium',
          }}
        >
          Hi {username || 'User'}, Your {serviceName || 'Service'} Request received. Our Local Pro will Get back to you soon.
        </Typography>

        <Button
          onClick={handleClose}
          variant="contained"
          sx={{
            backgroundColor: '#21608F',
            width: {
              xs: '100%',
              sm: 'auto',
            },
            '&:hover': {
              backgroundColor: '#21608F',
            },
          }}
        >
          OK
        </Button>
      </Box>
    </Dialog>
  );
}

export default SuccessModal;
