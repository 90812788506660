import { Box, Typography } from "@mui/material";
import React from "react";

function ServiceCardMinimal({ imgUrl, title, onClick }) {
  return (
    <Box sx={{cursor:'pointer'}} onClick={onClick}>
      <Box
        component="img"
        src={imgUrl}
        alt="service image"
        sx={{ width: "100%", height: "auto", objectFit: "contain" }}
      />

      <Typography
        variant="h4"
        fontSize={11}
        fontWeight={600}
        lineHeight="18px"
        textAlign="center"
      >
        {title}
      </Typography>
    </Box>
  );
}

export default ServiceCardMinimal;
