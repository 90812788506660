import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Box,
  Button,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { registerUserApi } from "../../../services/api_calls/auth/AuthApi";

const RegistrationDialog = ({ open, onClose,phoneNumber  }) => {
  const initialFormData = {
    name: "",
    email: "",
    mobile: phoneNumber  || "",
    whatsApp: false,
    gender: "Male",
    profilePicture: "photo",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!open) {
      setFormData(initialFormData);
      setError("");
    }
  }, [open]);

  useEffect(() => {
    if (phoneNumber) {
      setFormData((prev) => ({
        ...prev,
        mobile: phoneNumber,
      }));
    }
  }, [phoneNumber]);
  const handleChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
    setError("");
  };

  const handleMobileChange = (field) => (event) => {
    let value = event.target.value;

    value = value.replace(/[^0-9]/g, "");

    if (value.length <= 10) {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
    setError("");
  };

  const handleWhatsAppChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      whatsApp: event.target.checked,
    }));
  };

  const handleSubmit = async () => {
    if (!formData.name || !formData.email || !formData.mobile) {
      setError("Please fill in all required fields");
      return;
    }

    if (!isValidEmail(formData.email)) {
      setError("Please enter a valid email address");
      return;
    }

    setIsLoading(true);
    try {
      const response = await registerUserApi({
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        whatsApp: formData.whatsApp,
        profilePicture: "photo",
        gender: formData.gender,
      });

      if (response?.status) {
        onClose();
      } else {
        setError("Registration failed. Please try again.");
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
      console.error("Registration Error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="flex items-center gap-2">
          <img
            src="./assets/images/logo.jpg"
            alt="Logo"
            style={{ marginRight: 8, width: "100px" }}
          />
          <Typography variant="h6">Welcome</Typography>
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon className="h-5 w-5" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className="flex flex-col items-center mt-4" my={2}>
          <Typography className="text-lg font-semibold text-gray-800">
            PERSONAL DETAILS
          </Typography>
          <Typography className="text-sm text-gray-600 " my={2}>
            Enter Your Personal Details To Continue
          </Typography>
        </Box>

        <Box className="flex flex-col gap-4 mt-6" my={2}>
          <Box my={2} className="flex flex-col">
            <TextField
              fullWidth
              placeholder="Enter Your Full Name"
              value={formData.name}
              onChange={handleChange("name")}
              className="rounded-md"
              required
            />
          </Box>

          <Box my={2} className="flex flex-col">
            <TextField
              fullWidth
              placeholder="Enter Your Email ID"
              value={formData.email}
              onChange={handleChange("email")}
              type="email"
              className="rounded-md"
              required
            />
          </Box>

          <Box my={2} className="flex flex-col">
            <TextField
              fullWidth
              placeholder="Enter Your Mobile Number"
              value={formData.mobile}
              onChange={handleMobileChange("mobile")}
              type="tel"
              className="rounded-md"
              required
              inputProps={{
                maxLength: 10,
                pattern: "[0-9]*",
                inputMode: "numeric",
              }}
            />
          </Box>

          <Box my={2} className="flex flex-col">
            <Select
              value={formData.gender}
              onChange={handleChange("gender")}
              className="rounded-md"
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </Box>

          <Box my={2} className="flex flex-col">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.whatsApp}
                  onChange={handleWhatsAppChange}
                />
              }
              label="Use this number for WhatsApp"
            />
          </Box>
        </Box>

        {error && (
          <Typography className="text-red-500 text-sm text-center mt-4">
            {error}
          </Typography>
        )}
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            variant="contained"
          >
            {isLoading ? "Registering..." : "Submit"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RegistrationDialog;
