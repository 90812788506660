import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ListNowPopup = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <Box sx={{ position: 'relative',padding:'10px' }}>
        {/* Close Button */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Title */}
        <DialogTitle sx={{ textAlign: 'center', marginTop: '20px',fontFamily:'poppins',fontWeight:'bold' }}>
        Download the Application <br/>and List Your Business
        </DialogTitle>

        {/* Content with Buttons */}
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            

            {/* Google Play Button */}
            <Box
              component="img"
              sx={{
                width: '150px',
                marginBottom: '10px',
              }}
              src="./assets/images/Image 13.png"
              alt="Google Play"
            />

            {/* App Store Button */}
            <Box
              component="img"
              sx={{
                width: '150px',
              }}
              src="./assets/images/Image 14.png"
              alt="App Store"
            />
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ListNowPopup;
