import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import Header from "../../../components/UI/Header";
import InputText from "../../../components/UI/Input/InputText";
import { registerUserApi } from "../../../services/api_calls/auth/AuthApi";
import { COLORS } from "../../../styles/Theme";

function RegisterDetails() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");

  const handleClick = async (event) => {
    event.preventDefault()
    if (name && email && number) {
      const response = await registerUserApi({ name, email, number });
      console.log(response);

       navigate("/home");
    }
  };

  return (
    <Box>
      <Header title="" disableShadow />

      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          variant="h2"
          fontSize={18}
          fontWeight={600}
          color={COLORS.blackShade1}
          textAlign="center"
        >
          PERSONAL DETAILS
        </Typography>

        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={400}
          color={COLORS.grey}
          textAlign="center"
          mt={0.8}
        >
          Enter Your Personal Details To Continue
        </Typography>
      </Box>

      <FormStyled>
        <InputText
          placeholder="Enter Your Full Name"
          value={name}
          onChange={({ target }) => {
            setName(target.value);
          }}
        />

        <InputText
          placeholder="Enter Your Email ID"
          value={email}
          onChange={({ target }) => {
            setEmail(target.value);
          }}
        />
         <InputText
          placeholder="Enter Your Mobile number"
          value={number}
          onChange={({ target }) => {
            setNumber(target.value);
          }}
        />

        <StyledFormButton
            // disabled
          onClick={handleClick}
          sx={{ mt: 2 }}
          type="submit"
        >
          Continue
        </StyledFormButton>
      </FormStyled>
    </Box>
  );
}

export default RegisterDetails;

const FormStyled = styled.form({
  padding: "25px",
});
