import { getApiInstance } from "../../ApiInstance";
import { GET_CATEGORY, GET_CITY, REGISTER_USER, USER_OTP, VERIFY_OTP,ENQUIRY,GET_VENDOR,GET_VENDOR_DETAIL,STORE_ENQUIRY,ENQUIRY_VENDOR,RATING, GET_PROFILE,GET_CONTACTED_VENDORS,CREATE_CALL,CREATE_COMPLAINT,GET_COMPLAINTS,CONTACT_CREATE } from "../../Constant";

export const registerUserApi = async (postData) => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const response = await instance.post(REGISTER_USER, {
      name: postData?.name,
      email: postData?.email,
      mobile: postData?.mobile,
      whatsApp: postData?.whatsApp,
      profilePicture: "photo",
      gender: postData?.gender,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const userSentOtp = async (postData) => {
  // const token = "TOKEN_HERE";
    console.log(postData?.mobileNumber)
  try {
    const instance = getApiInstance();
    const response = await instance.post(USER_OTP, {
      mobilenumber: postData?.mobileNumber,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const userVerifyOtp = async (postData) => {
  // const token = "TOKEN_HERE";
  try {
    const instance = getApiInstance();
    const response = await instance.post(VERIFY_OTP, {
      mobilenumber: postData?.mobilenumber,
      otp: postData?.otp,
    });
    localStorage.setItem('token', response.data.access_token);
    console.log(response.data.access_token);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const getCityDataApi = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_CITY);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryDataApi = async () => {
  try{
    const instance = getApiInstance();
    const response = await instance.get(GET_CATEGORY);

    return response.data;
  }catch (error) {
    console.log(error);
  }
};

export const getRegisterApi = async () => {
  const token = localStorage.getItem('token');
  try{
    const instance = getApiInstance({
      Authorization:`Bearer ${token}`,
    });
    const response = await instance.get(GET_PROFILE);
    return response.data;
  }catch (error) {
    console.log(error);
  }
}

export const createUserEnquiryApi = async (postData) => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const location = postData?.location || postData?.currentCity || '';
    const response = await instance.post(ENQUIRY, {
      name:postData?.name,
      mobile:postData?.mobile,
      service_id: postData?.service_id,
      location: location,
    });
    return response.data;
  }catch (error){
    console.log(error);
  }
};

export const createVendorEnquiryApi = async (postData) => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const response = await instance.post(ENQUIRY_VENDOR, {
      name:postData?.name,
      mobile:postData?.mobile,
      service_id: postData?.service_id,
      vendor_id: postData?.vendor_id,
    });
    return response.data;
  }catch (error){
    console.log(error);
  }
};

export const getVendorListApi = async (postData) => {
  try{
    const instance = getApiInstance();
    const response = await instance.post(GET_VENDOR, {
      service_id: postData?.service_id,
    });
    return response.data;
  }catch (error){
    console.log(error);
  }
}

export const getVendorDetailsApi = async (postData) => {
  try{
    const instance = getApiInstance();
    const response = await instance.post(GET_VENDOR_DETAIL, {
      vendor_id: postData?.vendor_id,
    });
    return response.data;
  }catch (error){
    console.log(error);
  }
}

export const createRatingApi = async (postData) => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const response = await instance.post(RATING, {
      service_id: postData?.service_id,
      vendor_id: postData?.vendor_id,
      rating: postData?.rating,
      review: postData?.review,
    });
    return response.data;
  }catch (error){
    console.log(error);
  }
}

export const getContactedVendorsApi = async () => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const response = await instance.get(GET_CONTACTED_VENDORS);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addToWishlistApi = async (postData) => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const response = await instance.post('v1/wishlist/addwishlist', {
      vendor_id: postData?.vendor_id,
      service_id: postData?.service_id,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getWishlistApi = async () => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const response = await instance.get('v1/wishlist/getwishlist');
    return response.data;
  } catch (error) {
    console.error('Error fetching wishlist:', error);
    throw error;
  }
};

export const removeFromWishlistApi = async (postData) => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const response = await instance.post('v1/wishlist/removewishlist', {
      vendor_id: postData?.vendor_id,
      service_id: postData?.service_id,
      categoryId: postData.service_id 
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createCallApi = async (postData) => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    
    console.log('Making call request with data:', {
      vendor_id: postData?.vendor_id,
      service_id: postData?.service_id
    });
    
    const response = await instance.post(CREATE_CALL, {
      vendor_id: postData?.vendor_id,
      service_id: postData?.service_id,
    });
    
    return response.data;
  } catch (error) {
    console.log('API call error details:', {
      status: error.response?.status,
      data: error.response?.data,
      config: error.config
    });
    
    if (error.response?.data) {
      return error.response.data;
    }
    return {
      success: false,
      message: error.message || 'Network error or server not responding'
    };
  }
};

export const createComplaintApi = async (postData) => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const response = await instance.post(CREATE_COMPLAINT, {
      message: postData?.message,
      complaint_type: postData?.complaint_type,
    });
    return response.data;
  } catch (error) {
    console.error('Error in createComplaintApi:', error);
    if (error.response?.data) {
      return error.response.data;
    }
    return {
      success: false,
      message: 'Error submitting complaint'
    };
  }
};

export const getComplaintsApi = async (complaint_type) => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const response = await instance.post(GET_COMPLAINTS, {
      complaint_type: complaint_type
    });
    return response.data;
  } catch (error) {
    console.error('Error in getComplaintsApi:', error);
    if (error.response?.data) {
      return error.response.data;
    }
    return {
      success: false,
      message: 'Error fetching complaints'
    };
  }
};

export const COMPLAINT_TYPES = {
  CUSTOMER_COMPLAINT: 'CUSTOMER_COMPLAINT',
  PAYMENT_CREDITS: 'PAYMENT_CREDITS',
  ENQUIRY: 'ENQUIRY',
  PRO_COMPLAINTS: 'PRO_COMPLAINTS',
  LOCATION: 'LOCATION',
  BUSINESS_LISTING: 'BUSINESS_LISTING'
};

export const getAllComplaintsApi = async () => {
  const token = localStorage.getItem('token');
  try {
    const instance = getApiInstance({
      Authorization: `Bearer ${token}`,
    });
    const response = await instance.get('/v1/complaints/all-complaints');
    return response.data;
  } catch (error) {
    console.error('Error in getAllComplaintsApi:', error);
    if (error.response?.data) {
      return error.response.data;
    }
    return {
      success: false,
      message: 'Error fetching complaints'
    };
  }
};

export const storeEnquiryNumber = async (postData) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(STORE_ENQUIRY, {
      mobileNumber: postData?.mobileNumber,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: "Failed to store enquiry"
    };
  }
};

export const createContactApi = async (postData) => {
  
  try {
    const instance = getApiInstance();
    const response = await instance.post(CONTACT_CREATE, {
      firstName: postData?.firstName,
      lastName: postData?.lastName,
      phone: postData?.phone,
      email: postData?.email,
      message: postData?.message,
      agreeToTerms: postData?.agreeToTerms
    });

    return response.data;
  } catch (error) {
    console.error('Error in createContactApi:', error);
    if (error.response?.data) {
      return error.response.data;
    }
    return {
      success: false,
      message: 'Error submitting contact form'
    };
  }
};


export const getBlogCategories = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.post('/v1/Blog/getCategoryblogs');
    return response.data;
  } catch (error) {
    console.error('Error fetching blog categories:', error);
    return { success: false, categories: [] };
  }
};

export const getBlogPosts = async (categoryId = null, limit = null) => {
  try {
    const instance = getApiInstance();
    const url = '/v1/BlogPost/getPost';
    const response = await instance.post(url, {
      categoryId,
      limit
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    return { success: false, posts: [] };
  }
};

// Add to your AuthApi.js file

export const getMostReadPosts = async (limit = 5) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post('/v1/BlogPost/getreadcounts', {
      limit,
      status: 'published'
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching most read posts:', error);
    return { success: false, posts: [] };
  }
};

export const incrementReadCount = async (postId) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post('/v1/BlogPost/readincrement', {
      postId
    });
    return response.data;
  } catch (error) {
    console.error('Error incrementing read count:', error);
    return { success: false, readCount: 0 };
  }
};