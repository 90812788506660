import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";
import InputText from "./InputText";
import HttpsIcon from "@mui/icons-material/Https";

function OTPInput({
  placeholder,
  name,
  value,
  onChange,
  maxLength,
  disabled,
  resendDisabled,
  handleClick,
  countdownTimer,
}) {
  return (
    <Box>
      <InputText
        placeholder={placeholder}
        startIcon={
          <HttpsIcon
            sx={{
              color: COLORS.primaryColor,
              ml: "8px",
              opacity: disabled ? 0.2 : 1,
            }}
          />
        }
        endIcon={
          countdownTimer === 0 ? (
            <Button onClick={handleClick} disabled={disabled || resendDisabled}>
              RESEND
            </Button>
          ) : (
            <Typography variant="body2">
              00:{countdownTimer < 10 ? `0${countdownTimer}` : countdownTimer}
            </Typography>
          )
        }
        name={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
      />
    </Box>
  );
}

export default OTPInput;
