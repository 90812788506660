import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import Header from "../../../components/UI/Header";
import OpenCloseTimePicker from "../../../components/UI/Input/OpenCloseTimePicker";

function BusinessTiming() {
  const navigate = useNavigate();

  const [mondayOpen, setMondayOpen] = useState("");
  const [mondayClose, setMondayClose] = useState("");


  const handleClick = async (event) => {

      localStorage.setItem('profile', 6);
      navigate("/editProfile");
  
  };


  return (
    <Box>
      <Header title="Business Timing" />

      <Box m={2}>
        <OpenCloseTimePicker
          label="Mon"
          openTime={mondayOpen}
          onOpenTimeChange={(value) => {
            setMondayOpen(value);
          }}
          closeTime={mondayClose}
          onCloseTimeChange={(value) => {
            setMondayClose(value);
          }}
        />
        
        <OpenCloseTimePicker
          label="Tue"
          openTime={mondayOpen}
          onOpenTimeChange={(value) => {
            setMondayOpen(value);
          }}
          closeTime={mondayClose}
          onCloseTimeChange={(value) => {
            setMondayClose(value);
          }}
        />

        <OpenCloseTimePicker
          label="Wed"
          openTime={mondayOpen}
          onOpenTimeChange={(value) => {
            setMondayOpen(value);
          }}
          closeTime={mondayClose}
          onCloseTimeChange={(value) => {
            setMondayClose(value);
          }}
        />

        <OpenCloseTimePicker
          label="Thu"
          openTime={mondayOpen}
          onOpenTimeChange={(value) => {
            setMondayOpen(value);
          }}
          closeTime={mondayClose}
          onCloseTimeChange={(value) => {
            setMondayClose(value);
          }}
        />

        <OpenCloseTimePicker
          label="Fri"
          openTime={mondayOpen}
          onOpenTimeChange={(value) => {
            setMondayOpen(value);
          }}
          closeTime={mondayClose}
          onCloseTimeChange={(value) => {
            setMondayClose(value);
          }}
        />

        <OpenCloseTimePicker
          label="Sat"
          openTime={mondayOpen}
          onOpenTimeChange={(value) => {
            setMondayOpen(value);
          }}
          closeTime={mondayClose}
          onCloseTimeChange={(value) => {
            setMondayClose(value);
          }}
        />

        <OpenCloseTimePicker
          label="Sun"
          openTime={mondayOpen}
          onOpenTimeChange={(value) => {
            setMondayOpen(value);
          }}
          closeTime={mondayClose}
          onCloseTimeChange={(value) => {
            setMondayClose(value);
          }}
          isClosed
        />
      </Box>

      <FormStyled>
        <StyledFormButton
         onClick={handleClick}
          sx={{ mt: 2 }}
          type="submit"
        >
          Submit
        </StyledFormButton>
      </FormStyled>
    </Box>
  );
}

export default BusinessTiming;

const FormStyled = styled.form({
  padding: "25px",
});
