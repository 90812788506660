// src/pages/Services.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import {
  Box,
  Typography,
  Grid,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { getCategoryDataApi } from '../../../services/api_calls/auth/AuthApi';
import Header from "../DashboardHeader/Header";
import BottomTabs from '../BottomTabs';
import { createUrlSlug } from '../../../utils/urlUtils';
import styled from '@emotion/styled';
import Footer from './Footer';

const Services = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [categories, setCategories] = useState([]);

  const theme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            maxWidth: "1200px",
          },
        },
      },
    },
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategoryDataApi();
        if (response?.status && response?.categories) {
          // Sort categories by position
          const sortedCategories = response.categories.sort((a, b) => a.position - b.position);
          setCategories(sortedCategories);
        }
      } catch (err) {
        console.error("Error fetching categories:", err);
      }
    };
    fetchCategories();
  }, []);

  const handleServiceClick = (subCategory) => {
    if (!subCategory?.name) return;

    const serviceSlug = createUrlSlug(subCategory.name);
    const currentCity = localStorage.getItem('selectedCity');
    const currentCitySlug = currentCity ? createUrlSlug(currentCity) : '';

    const navigationPath = currentCitySlug 
      ? `/${currentCitySlug}/${serviceSlug}`
      : `/${serviceSlug}`;

    navigate(navigationPath, {
      state: {
        selectedService: subCategory.name,
        selectedServiceId: subCategory.id,
        cityName: currentCity
      }
    });
  };

  const ServiceContainer = styled(Box)`
  padding: 15px;
  padding-top: 27px;

  @media (min-width: 600px) {
    padding: 40px;
    padding-top: 120px; // Adjust this value based on your desktop header height
  }
`;
  return (
    <ThemeProvider theme={theme}>
      <Header />
      
      <section className="service-main-container">
      <ServiceContainer className="container service-container">
          <Typography 
            variant="h4" 
            className="main-heading text-center fw-bold"
            sx={{ 
              mb: 4,
              color: '#21608F',
              fontWeight: 'bold'
            }}
          >
            Our Services
          </Typography>

          <Grid container spacing={4}>
            {categories.map((category) => (
              <React.Fragment key={category.id}>
                {category.subCategory?.map((service) => (
                  <Grid 
                    item 
                    xs={12} 
                    md={4} 
                    key={service.id}
                    className="work-container-subdiv"
                  >
                    <Box
                      sx={{
                        textAlign: 'center',
                        padding: '20px',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-5px)'
                        }
                      }}
                      onClick={() => handleServiceClick(service)}
                    >
                      <Box
                        component="img"
                        src={service.image}
                        alt={service.name}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "./assets/images/home-banner.png";
                        }}
                        sx={{
                          width: '100px',
                          height: '100px',
                          marginBottom: '15px',
                          borderRadius: '50%',
                          objectFit: 'cover'
                        }}
                      />
                      
                      <Typography 
                        variant="h6" 
                        className="sub-heading"
                        sx={{ 
                          mb: 2,
                          color: '#21608F',
                          fontWeight: '600'
                        }}
                      >
                        {service.name}
                      </Typography>

                      <Typography 
                        className="main-hero-para"
                        sx={{ 
                          color: '#666',
                          fontSize: '0.9rem'
                        }}
                      >
                        {service.description || `Professional ${service.name} services at your doorstep`}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </React.Fragment>
            ))}
          </Grid>
          </ServiceContainer>
      </section>

      {isMobile && <BottomTabs activeTab="services" />}
      <Footer />
    </ThemeProvider>
  );
};

export default Services;