import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { COLORS } from "../../styles/Theme";
import styled from "@emotion/styled";
import DropdownMenu from "../../components/UI/DropdownMenu";

const DUMMY_OPTIONS = [
  {
    label: "Online Classes",
    isCheck: false,
  },
  {
    label: "Travel To Tutor Home",
    isCheck: false,
  },
  {
    label: "Tutor Should Travel To My Home",
    isCheck: false,
  },
];

function FilterModalContent({ handleReset, handleClose }) {
  const [boardType, setBoardType] = useState(DUMMY_OPTIONS);
  const [tutionType, setTutionType] = useState(DUMMY_OPTIONS);
  const [subjects, setSubjects] = useState(DUMMY_OPTIONS);

  return (
    <Box>
      <StyledModalHeader>
        <Button onClick={handleReset}>RESET</Button>

        <Button
          onClick={handleClose}
          endIcon={<CloseRoundedIcon />}
          sx={{ color: COLORS.black, textTransform: "none" }}
        >
          Filter
        </Button>
      </StyledModalHeader>

      <Box m={2}>
        <DropdownMenu
          label="Board Type"
          menuList={boardType}
          setValue={setBoardType}
        />
        <DropdownMenu
          label="Tution Type"
          menuList={tutionType}
          setValue={setTutionType}
        />
        <DropdownMenu
          label="Subject"
          menuList={subjects}
          setValue={setSubjects}
        />
      </Box>
    </Box>
  );
}

export default FilterModalContent;

const StyledModalHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "6px 10px",
  borderBottom: "1px solid #C2C2C2",
});
