

import styled from "@emotion/styled";
import { AppBar,Avatar, Badge, Box } from "@mui/material";
import { COLORS } from "../../../styles/Theme";

export const HeaderContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "12px",
  backgroundColor: COLORS.white,
  boxShadow: "0px 4px 10px 0px rgb(0 0 0 / 13%)",
  position: "sticky",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 10,
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    fontSize: "11px",
    right: 1,
    top: 2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export const StyledAvatar = styled(Avatar)({
  marginLeft: "10px",
  boxShadow: "0px 4px 10px 0px rgb(0 0 0 / 13%)",
});


// Header container for overall layout
export const DashboardHeaderContainer = styled(AppBar)({
  backgroundColor: "#FFFFFF",
  padding: '10px 100px',
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Consistent shadow
  opacity: 1,
});

// Flexbox for layout
export const FlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  // gap: '8px',
});

// SearchBox for the search section with shadow
export const SearchBox = styled(Box)({
  display: 'flex',
  padding:'5px',
  alignItems: 'center',
  // gap: '8px',
  border:'1px solid #EDEDED',
  borderRadius:'5px',
  boxShadow: '0px -3px 6px rgba(0, 0, 0, 0.1)', // Consistent shadow here
});

// Dot for styling elements
export const Dot = styled(Box)({
  width: '10px',
  height: '10px',
  backgroundColor: '#ccc',
  borderRadius: '50%',
  marginLeft: '8px',
});

// Divider between elements
export const Divider = styled(Box)({
  width: '1px',
  height: '24px',
  backgroundColor: '#A2A2A2',
  marginRight: '8px',
});