import styled from "@emotion/styled";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React, { useState } from "react";
import { COLORS } from "../../../styles/Theme";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";

function DropdownMenu({ label, menuList, setValue }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (element, index) => {
    menuList[index] = { ...element, isCheck: !element?.isCheck };
    setValue([...menuList]);
  };

  return (
    <Box mb={2}>
      <StyledDropdownBtn
        endIcon={isOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        fullWidth
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {label}
      </StyledDropdownBtn>

      <Box m="0 10px">
        {isOpen && (
          <FormControl fullWidth component="fieldset" variant="standard">
            <FormGroup>
              {menuList?.map((item, index) => (
                <FormControlLabel
                  key={item?.label + index}
                  sx={{ justifyContent: "space-between", ml: 0 }}
                  control={
                    <Checkbox
                      checked={item?.isCheck}
                      onChange={() => {
                        handleChange(item, index);
                      }}
                    />
                  }
                  label={item?.label}
                  labelPlacement="start"
                />
              ))}
            </FormGroup>
          </FormControl>
        )}
      </Box>
    </Box>
  );
}

export default DropdownMenu;

const StyledDropdownBtn = styled(Button)({
  fontSize: 16,
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  textTransform: "none",
  color: COLORS.black,
  borderRadius: 0,
  borderBottom: "1px solid #C2C2C2",
});
