import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ServiceCardMinimal from "../../../components/Cards/ServiceCardMinimal";
import Header from "../../../components/UI/Header";
import { SERVICE_LIST_HOME } from "../../../utils/Data";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { COLORS } from "../../../styles/Theme";
import styled from "@emotion/styled";
import { textOneLine } from "../../../styles/Global";
import { useLocation, useNavigate } from "react-router-dom";

function Categories() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleCategoryClick = () => {
    navigate("/serviceDetails");
  };

  return (
    <Box pb={10}>
      <Header title="Categories" disableShadow />
      {JSON.stringify(state)}

      <LocationInfo>
        <Typography
          variant="body1"
          fontSize={12}
          fontWeight={500}
          sx={{ ...textOneLine, mb: "-2px" }}
          width="100px"
          textAlign="right"
        >
          Kalyan Nagar
        </Typography>

        <FmdGoodIcon />
      </LocationInfo>

      <Box m="18px">
        <Grid container spacing={3}>
          {SERVICE_LIST_HOME?.map((item, index) => {
            return (
              <Grid item xs={4} key={"category_" + index}>
                <ServiceCardMinimal
                  imgUrl={item.imgUrl}
                  title={item.name}
                  onClick={handleCategoryClick}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

export default Categories;

const LocationInfo = styled(Box)({
  display: "flex",
  alignItems: "flex-end",
  color: COLORS.greyShade7,
  position: "absolute",
  top: 20,
  right: 10,
});
