import { Box } from "@mui/material";
import React, { useState } from "react";
import Header from "../../../components/UI/Header";
import { FormStyled } from "./BusinessAddress.style";
import InputText from "../../../components/UI/Input/InputText";
import SelectInputStyled from "../../../components/UI/Select/SelectInputStyled";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import { useNavigate } from "react-router-dom";
import { BUSINESS_CITY } from "../../../utils/Data";
import StyledLocationInput from "../../../components/UI/Input/StyledLocationInput";

function BusinessAddress() {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [city, setCity] = useState("Business City");

  return (
    <Box>
      <Header title="Business Address" />

      <FormStyled>
        <SelectInputStyled
          value={city}
          onValueChange={(text) => {
            setCity(text);
          }}
          menuList={BUSINESS_CITY}
        />

        <StyledLocationInput
          value={location}
          onChange={({ target }) => {
            setLocation(target.value);
          }}
          placeholder="Business Location"
        />

        <InputText
          value={address}
          onChange={({ target }) => {
            setAddress(target.value);
          }}
          placeholder="Business Address 1"
        />

        <StyledFormButton
          onClick={() => {
            navigate('/editProfile');
          }}
          sx={{ mt: 2 }}
          type="submit"
        >
          Submit
        </StyledFormButton>
      </FormStyled>
    </Box>
  );
}

export default BusinessAddress;
