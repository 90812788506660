export const DETAILS_STEPS = [
  {
    label: "Personal Details",
    link: "/addPersonalDetails",
  },
  {
    label: "Business Name & Address",
    link: "/businessDetails",
  },
  {
    label: "Select Service Category",
    link: "/serviceCatagory",
  },
  {
    label: "Add Your Documents",
    link: "/addDocuments",
  },
  {
    label: "Business Timing",
    link: "/businessTiming",
  },
  {
    label: "Refer Service",
    link: "/addReferDetails",
  },
  {
    label: "Select Term & Conditions",
    link: "/termAndConditions",
  },
  {
    label: "Completed",
  },
];

export const LOCATIONS = [
  { label: "Bangalore", value: "bangalore" },
  { label: "Mysore", value: "mysore" },
  { label: "Chennai", value: "chennai" },
  { label: "Delhi", value: "delhi" },
  { label: "Mumbai", value: "mumbai" },
];

export const GENDERS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const SERVICE_LIST = [
  { label: "Service Type", value: "" },
  { label: "Service 1", value: "service1" },
  { label: "Service 2", value: "service2" },
];

export const TUTION_LIST = [
  { label: "Tution List", value: "" },
  { label: "Tution 1", value: "tution1" },
  { label: "Tution 2", value: "tution2" },
];

export const CLASS_LIST = [
  { label: "Class Type", value: "" },
  { label: "Class 1", value: "class1" },
  { label: "Class 2", value: "class2" },
];

export const BOARD_LIST = [
  { label: "Board Type", value: "" },
  { label: "Board 1", value: "Board1" },
  { label: "Board 2", value: "Board2" },
];

export const TUTION_TYPE = [
  { label: "Tution Type", value: "" },
  { label: "Tution 1", value: "tution1" },
  { label: "Tution 2", value: "tution2" },
];

export const SUBJECT_LIST = [
  { label: "Subject Type", value: "" },
  { label: "Subject 1", value: "Subject1" },
  { label: "Subject 2", value: "Subject2" },
];

export const BUSINESS_CITY = [
  { label: "Business City", value: "none" },
  { label: "Subject 1", value: "Subject1" },
  { label: "Subject 2", value: "Subject2" },
];

export const SERVICE_LIST_HOME = [
  {
    imgUrl: "/./assets/images/services_category/cat_1.png",
    name: "Home Appliance",
  },
  {
    imgUrl: "/./assets/images/services_category/cat_2.png",
    name: "Kitchen Appliance",
  },
  {
    imgUrl: "/./assets/images/services_category/cat_3.png",
    name: "Home Maintenance",
  },
  {
    imgUrl: "/./assets/images/services_category/cat_4.png",
    name: "Cleaning Services",
  },
  {
    imgUrl: "/./assets/images/services_category/cat_5.png",
    name: "Automobile Services",
  },
  {
    imgUrl: "/./assets/images/services_category/cat_6.png",
    name: "Photography Services",
  },
  { imgUrl: "/./assets/images/services_category/cat_7.png", name: "Events" },
  {
    imgUrl: "/./assets/images/services_category/cat_8.png",
    name: "Health & Fitness",
  },
  {
    imgUrl: "/./assets/images/services_category/cat_9.png",
    name: "Laundry Services",
  },
  {
    imgUrl: "/./assets/images/services_category/cat_10.png",
    name: "Document Services",
  },
  { imgUrl: "/./assets/images/services_category/cat_11.png", name: "Tutions" },
  { imgUrl: "/./assets/images/services_category/cat_12.png", name: "Caterers" },
  {
    imgUrl: "/./assets/images/services_category/cat_13.png",
    name: "Software Development",
  },
  {
    imgUrl: "/./assets/images/services_category/cat_14.png",
    name: "Nearby Clinics",
  },
  {
    imgUrl: "/./assets/images/services_category/cat_15.png",
    name: "Real Estate Agencies",
  },
  {
    imgUrl: "/./assets/images/services_category/cat_16.png",
    name: "Coming Soon",
  },
];

export const WISHLISH_DATA = [
  {
    profileImg:
      "https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // name: "Balakrishna Mu...",
    businessName: "S N Enterprises",
    rating: "4.4",
    reviewCount: "25",
    // caterogy: "AC Repair",
    timings: "08:00 AM - 09:00 PM",
    address: "Kalyan Nagar, Bangalore",
  },
  {
    profileImg:
      "https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // name: "Balakrishna Mu...",
    businessName: "S N Enterprises",
    rating: "4.4",
    reviewCount: "25",
    // caterogy: "AC Repair",
    timings: "08:00 AM - 09:00 PM",
    address: "Kalyan Nagar, Bangalore",
  },
  {
    profileImg:
      "https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // name: "Balakrishna Mu...",
    businessName: "S N Enterprises",
    rating: "4.4",
    reviewCount: "25",
    // caterogy: "AC Repair",
    timings: "08:00 AM - 09:00 PM",
    address: "Kalyan Nagar, Bangalore",
  },
  {
    profileImg:
      "https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // name: "Balakrishna Mu...",
    businessName: "S N Enterprises",
    rating: "4.4",
    reviewCount: "25",
    // caterogy: "AC Repair",
    timings: "08:00 AM - 09:00 PM",
    address: "Kalyan Nagar, Bangalore",
  },
  {
    profileImg:
      "https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // name: "Balakrishna Mu...",
    businessName: "S N Enterprises",
    rating: "4.4",
    reviewCount: "25",
    // caterogy: "AC Repair",
    timings: "08:00 AM - 09:00 PM",
    address: "Kalyan Nagar, Bangalore",
  },
  {
    profileImg:
      "https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // name: "Balakrishna Mu...",
    businessName: "S N Enterprises",
    rating: "4.4",
    reviewCount: "25",
    // caterogy: "AC Repair",
    timings: "08:00 AM - 09:00 PM",
    address: "Kalyan Nagar, Bangalore",
  },
];

export const NEARBY_PROFESSIONALS = [
  {
    profileImg:
      "https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // name: "Balakrishna Mu...",
    businessName: "S N Enterprises",
    rating: "4.4",
    reviewCount: "25",
    // caterogy: "AC Repair",
    timings: "08:00 AM - 09:00 PM",
    address: "Kalyan Nagar, Bangalore",
    distance: "59 Mins - 5.9 Kms",
    isVerified: true,
  },
  {
    profileImg:
      "https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // name: "Balakrishna Mu...",
    businessName: "S N Enterprises",
    rating: "4.4",
    reviewCount: "25",
    // caterogy: "AC Repair",
    timings: "08:00 AM - 09:00 PM",
    address: "Kalyan Nagar, Bangalore",
    distance: "59 Mins - 5.9 Kms",
    isVerified: true,
  },
  {
    profileImg:"./assets/images/plumber-img.jpg"
    
  },
  {
    profileImg:
      "https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // name: "Balakrishna Mu...",
    businessName: "S N Enterprises",
    rating: "4.4",
    reviewCount: "25",
    // caterogy: "AC Repair",
    timings: "08:00 AM - 09:00 PM",
    address: "Kalyan Nagar, Bangalore",
    distance: "59 Mins - 5.9 Kms",
    isVerified: false,
  },
  {
    profileImg:
      "https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // name: "Balakrishna Mu...",
    businessName: "S N Enterprises",
    rating: "4.4",
    reviewCount: "25",
    // caterogy: "AC Repair",
    timings: "08:00 AM - 09:00 PM",
    address: "Kalyan Nagar, Bangalore",
    distance: "59 Mins - 5.9 Kms",
    isVerified: true,
  },
];
export const SERVICE_TEXT = ["CCTV Repair", "AC Repair", "Washing Machine Service", "TV Repair", "Washing Machine Service", "TV Repair","TV Repair", "Washing Machine Service", "TV Repair"];

export const NEARBY_SERVICES = [
  {
    imgUrl: "/./assets/images/services/01.png",
    name: "AC Service",
  },
  {
    imgUrl: "/./assets/images/services/02.png",
    name: "Gas Stove Service",
  },
  {
    imgUrl: "/./assets/images/services/03.png",
    name: "House Painters",
  },
  {
    imgUrl: "/./assets/images/services/04.png",
    name: "Pest Control Service",
  },
  {
    imgUrl: "/./assets/images/services/05.png",
    name: "Car Service",
  },
  {
    imgUrl: "/./assets/images/services/06.png",
    name: "Flower Service",
  },
];

export const TRANSACTION_HISTORY = [
  {
    price: "1140",
    contacts: "100 Contacts",
    datetime: "Dec 12, 2022 at 7:05pm",
    status: "Sucess",
  },
  {
    price: "1250",
    contacts: "78 Contacts",
    datetime: "Dec 12, 2022 at 7:05pm",
    status: "Failed",
  },
  {
    price: "750",
    contacts: "42 Contacts",
    datetime: "Dec 12, 2022 at 7:05pm",
    status: "Pending",
  },
  {
    price: "0",
    contacts: "100 Contacts",
    datetime: "Dec 12, 2022 at 7:05pm",
    status: "Welcome Bonus",
  },
];

export const CONTACT_PLANS = [
  {
    price: "1140",
    contacts: "100 Contacts",
    info: "1222+18%GST 220",
    plan: "Basic Plan",
  },
  {
    price: "3540",
    contacts: "300 Contacts",
    info: "1222+18%GST 220",
    plan: "Silver Plan",
  },
  {
    price: "4720",
    contacts: "500 Contacts",
    info: "1222+18%GST 220",
    plan: "Gold Plan",
  },
  {
    price: "7080",
    contacts: "1000 Contacts",
    info: "1222+18%GST 220",
    plan: "Diamond Plan",
  },
  {
    price: "118000",
    contacts: "15% Bonus",
    info: "100000+18%GST 18000",
    plan: "Platinum Plan",
  },
];

export const MESSAGES_DATA = [
  {
    title: "Account Issue",
    subtitle: "Change my mobile number",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum",
    datetime: "Dec 12, 2022 at 7:05pm",
    replyDate: "Dec 12, 2022",
  },
  {
    title: "Login Issue",
    subtitle: "Change my mobile number",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum",
    datetime: "Dec 12, 2022 at 7:05pm",
    replyDate: "Dec 12, 2022",
  },
  {
    title: "Payment Issue",
    subtitle: "Change my mobile number",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum",
    datetime: "Dec 12, 2022 at 7:05pm",
    replyDate: "Dec 12, 2022",
  },
  {
    title: "Account Issue",
    subtitle: "Change my mobile number",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum",
    datetime: "Dec 12, 2022 at 7:05pm",
    replyDate: "Dec 12, 2022",
  },
];

export const SERVICE_DATA_LIST = [
  // {icon:'/./assets/images/service-icons/all.png', label:'All'},
  {icon:'/./assets/images/service-icons/air-conditioner.png', label:'AC Service'},
  {icon:'/./assets/images/service-icons/cctv-camera.png', label:'CCTV Service'},
  {icon:'/./assets/images/service-icons/geyser.png', label:'Geyser Service'},
  {icon:'/./assets/images/service-icons/UPS.png', label:'UPS Repair'},
  {icon:'/./assets/images/service-icons/tv.png', label:'TV Repair'},
  {icon:'/./assets/images/service-icons/washing-machine .png', label:'Washing'},
  {icon:'/./assets/images/service-icons/laptop.png', label:'Laptop'},
  {icon:'/./assets/images/service-icons/gas-geyser.png', label:'Gas Seyser'},
]
export const services=[

]
export const SERVICE_DATA_LIST2 = [
  {icon:'/./assets/images/service-icons/12.png', label:'AC Service'},
  {icon:'/./assets/images/service-icons/13.png', label:'CCTV Service'},
  {icon:'/./assets/images/service-icons/14.png', label:'Geyser Service'},
  {icon:'/./assets/images/service-icons/15.png', label:'UPS Repair'},
  {icon:'/./assets/images/service-icons/16.png', label:'TV Repair'},
  {icon:'/./assets/images/service-icons/17.png', label:'Washing'},
  {icon:'/./assets/images/service-icons/18.png', label:'Laptop'},
  {icon:'/./assets/images/service-icons/19.png', label:'Gas Seyser'},
]

