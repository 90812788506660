import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";
import InputText from "./InputText";

function PhoneNumberInput({
  placeholder,
  name,
  value,
  onChange,
  disabled,
  btnDisabled,
  handleClick,
  handleEditClick,
}) {
  return (
    <Box>
      <InputText
        placeholder={placeholder}
        startIcon={
          <Typography
            variant="boby2"
            fontSize={14}
            fontWeight={500}
            color={COLORS.black}
            sx={{ m: "0 -2px 0 8px", opacity: disabled ? 0.2 : 1 }}
          >
            + 91
          </Typography>
        }
        endIcon={
          !disabled ? (
            <Button onClick={handleClick} disabled={disabled || btnDisabled}>
              GET OTP
            </Button>
          ) : (
            <Button onClick={handleEditClick}>EDIT</Button>
          )
        }
        name={name}
        value={value}
        onChange={onChange}
        maxLength={10}
        disabled={disabled}
      />
    </Box>
  );
}

export default PhoneNumberInput;
