import { Box, Typography } from "@mui/material";
import React from "react";
import Header from "../../../components/UI/Header";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import { COLORS } from "../../../styles/Theme";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import styled from "@emotion/styled";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import TransactionHistoryCard from "../../../components/Cards/TransactionHistoryCard";
import { TRANSACTION_HISTORY } from "../../../utils/Data";
import { useNavigate } from "react-router-dom";

function MySubscriptions() {
  const navigate= useNavigate()

  const handleAddBalance=()=>{
    navigate('/contactPlans')
  }

  return (
    <Box>
      <Header title="My Subscription Plan" disableShadow />

      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <AccountBalanceWalletRoundedIcon
          sx={{ fontSize: 50 }}
          color="secondary"
        />

        <Typography
          variant="h2"
          fontSize={18}
          fontWeight={500}
          color={COLORS.blackShade1}
          textAlign="center"
          mt={2}
        >
          Pro Contact Balance
        </Typography>

        <Typography
          variant="h4"
          fontSize={22}
          fontWeight={500}
          color={COLORS.primaryColor}
          textAlign="center"
          mt={1}
        >
          100
        </Typography>

        <AddButton endIcon={<AddRoundedIcon />} onClick={handleAddBalance}>
          ADD BALANCE
        </AddButton>
      </Box>

      <Box m="25px 16px">
        <Typography
          variant="body2"
          fontSize={13}
          fontWeight={600}
          color={COLORS.black}
          mb={2}
        >
          Your Transactions History
        </Typography>

        {TRANSACTION_HISTORY?.map((item, index) => (
          <TransactionHistoryCard
            key={"transaction_" + index}
            price={item.price}
            contacts={item.contacts}
            datetime={item.datetime}
            status={item.status}
          />
        ))}
      </Box>
    </Box>
  );
}

export default MySubscriptions;

const AddButton = styled(StyledFormButton)({
  width: "auto",
  padding: "10px 20px",
  marginTop: "20px",
  boxShadow: "0px 10px 20px -1px rgba(0,0,0,0.13)",
});
