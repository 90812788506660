import styled from "@emotion/styled";
import { Avatar, Box, Button } from "@mui/material";
import GppGoodRoundedIcon from "@mui/icons-material/GppGoodRounded";
import { COLORS } from "../../../styles/Theme";

export const StyledBusinessInfoCard = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "14px 8px 20px 8px",
  borderRadius: "6px",
  boxShadow: "0px 2px 20px -0px rgba(0,0,0,0.13)",
  position: "relative",
});

export const BusinessLogo = styled(Avatar)({
  width: 83,
  height: 83,
});

export const CheckIconStyled = styled(GppGoodRoundedIcon)(({ iconcolor }) => ({
  fontSize: 15,
  marginBottom: "-4px",
  color: iconcolor,
}));

export const StyledEditButton = styled(Button)({
  fontSize: 11,
  fontWeight: 400,
  borderRadius: "6px",
  padding: "6px",
  textTransform: "none",
  position: "absolute",
  bottom: 10,
  right: 10,
});

export const StyledInfoBox = styled.button(({jc})=>({
  flex: 1,
  height: 108,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: jc || "center",
  backgroundColor:COLORS.white,
  border:'none',
  padding: "8px",
  borderRadius: "6px",
  boxShadow: "0px 2px 20px -0px rgba(0,0,0,0.13)",
  cursor:'pointer'
}));

export const StyledInfoCard = styled(Box)({
  backgroundColor:COLORS.white,
  padding: "8px 16px 16px",
  marginTop:'10px',
  borderRadius: "6px",
  boxShadow: "0px 2px 20px -0px rgba(0,0,0,0.13)",
  cursor:'pointer'
});

export const StyledIconImg = styled.img({
  width: 22,
  height: 22,
  objectFit: "contain",
});
