import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[8],
  },
}));

const StyledReadMore = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
  cursor: 'pointer',
  display: 'inline-block',
  marginLeft: '8px',
}));

const BlogCard = ({ post }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const urlFriendlyTitle = post.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
    navigate(`/blog/${urlFriendlyTitle}`, { state: { postId: post._id } });
  };

  return (
    <StyledCard onClick={handleClick}>
      <CardMedia
        component="img"
        height="240"
        image={post.image || "/assets/images/home-banner.png"}
        alt={post.title}
        onError={(e) => {
          e.target.src = "/assets/images/home-banner.png";
        }}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          {post.title}
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Chip 
            label={post.category?.name}
            size="small"
            color="primary"
            sx={{ mr: 1 }}
          />
        </Box>
        <Typography variant="body2" color="text.secondary" paragraph>
          {post.description}
          <StyledReadMore onClick={handleClick}>Read More</StyledReadMore>
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default BlogCard;