// import { Box, IconButton, Typography,Drawer } from "@mui/material";
// import React, { useState }  from "react";
// import { COLORS } from "../../../styles/Theme";
// import {
//   HeaderContainer,
//   StyledAvatar,
//   StyledBadge,
// } from "./DashboardHeader.style";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import { textOneLine } from "../../../styles/Global";
// import { useNavigate } from "react-router-dom";
// import UserProfile from "../../../pages/Profile/UserProfile";

// function DashboardHeader({
//   onSearchClick,
// }) {
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const navigate = useNavigate();

//   const onNotificationClick = () => {};

//   // const onProfileClick = () => {
//   //   navigate('/profile')
//   // };
//   const onProfileClick = () => {
//     setIsDrawerOpen(true);
//   };

//   const toggleDrawer = (open) => () => {
//     setIsDrawerOpen(open);
//   };
//   return (
//     <HeaderContainer>
//       <Box display="flex" alignItems="center" gap={1}>
//         <Box
//           component="img"
//           src="/./assets/images/lpro-logo.png"
//           alt="Local Pro"
//           width={36}
//           height={36}
//           sx={{ objectFit: "contain" }}
//         />

//         <Box>
//           <Typography variant="body2" fontSize={14} fontWeight={500}>
//             Bangalore
//           </Typography>

//           <Typography
//             variant="body2"
//             fontSize={10}
//             fontWeight={400}
//             color={COLORS.greyShade1}
//             sx={{
//               ...textOneLine,
//             }}
//           >
//             2nd Cross, Babusapalya, Nanjappa
//           </Typography>
//         </Box>
//       </Box>

//       <Box display="flex" alignItems="center">
//         <IconButton onClick={onSearchClick}>
//           <SearchOutlinedIcon />
//         </IconButton>

//         <IconButton onClick={onNotificationClick}>
//           <StyledBadge badgeContent={5} color="secondary">
//             <Box
//               component="img"
//               src="/./assets/images/notification-icon.png"
//               alt="Local Pro"
//               width={22}
//               height={22}
//               sx={{ objectFit: "contain" }}
//             />
//           </StyledBadge>
//         </IconButton>

//         <StyledAvatar
//           alt="user profile img"
//           src="https://images.pexels.com/photos/3785079/pexels-photo-3785079.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
//           onClick={onProfileClick}
//         />
//         <Drawer
//         anchor="right"
//         open={isDrawerOpen}
//         onClose={toggleDrawer(false)}
//       >
//         <Box
//           role="presentation"
//           sx={{ width: 250 }} // Adjust width as needed
//           onClick={toggleDrawer(false)}
//           onKeyDown={toggleDrawer(false)}
//         >
//           <UserProfile /> {/* Display UserProfile component in the drawer */}
//         </Box>
//       </Drawer>
//       </Box>
//     </HeaderContainer>
//   );
// }

// export default DashboardHeader;
// import { Box, IconButton, Typography, Drawer, Modal } from "@mui/material";
// import React, { useState } from "react";
// import { COLORS } from "../../../styles/Theme";
// import {
//   HeaderContainer,
//   StyledAvatar,
//   StyledBadge,
// } from "./DashboardHeader.style";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import { textOneLine } from "../../../styles/Global";
// import UserProfile from "../../../pages/Profile/UserProfile";
// import ListNowModal from "../../../pages/Dashboard/Home/ListNowModal"

// function DashboardHeader({ onSearchClick }) {
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false); // State for modal control

//   const onProfileClick = () => {
//     setIsDrawerOpen(true);
//   };

//   const toggleDrawer = (open) => () => {
//     setIsDrawerOpen(open);
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <HeaderContainer>
//       <Box display="flex" alignItems="center" gap={1}>
//         <Box
//           component="img"
//           src="/./assets/images/lpro-logo.png"
//           alt="Local Pro"
//           width={36}
//           height={36}
//           sx={{ objectFit: "contain" }}
//         />
//         <Box>
//           <Typography variant="body2" fontSize={14} fontWeight={500}>
//             Bangalore
//           </Typography>
//           <Typography
//             variant="body2"
//             fontSize={10}
//             fontWeight={400}
//             color={COLORS.greyShade1}
//             sx={{ ...textOneLine }}
//           >
//             2nd Cross, Babusapalya, Nanjappa
//           </Typography>
//         </Box>
//       </Box>

//       <Box display="flex" alignItems="center">
//         <IconButton onClick={onSearchClick}>
//           <SearchOutlinedIcon />
//         </IconButton>
//         <IconButton>
//           <StyledBadge badgeContent={5} color="secondary">
//             <Box
//               component="img"
//               src="/./assets/images/notification-icon.png"
//               alt="Notification"
//               width={22}
//               height={22}
//               sx={{ objectFit: "contain" }}
//             />
//           </StyledBadge>
//         </IconButton>
//         <StyledAvatar
//           alt="user profile img"
//           src="https://images.pexels.com/photos/3785079/pexels-photo-3785079.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
//           onClick={onProfileClick}
//         />
//         <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
//           <Box
//             role="presentation"
//             sx={{ width: 250 }}
//             onClick={toggleDrawer(false)}
//             onKeyDown={toggleDrawer(false)}
//           >
//             <UserProfile openModal={openModal} /> {/* Pass openModal function */}
//           </Box>
//         </Drawer>
//       </Box>

//       {/* Render ListNowModal outside the Drawer */}
//       <ListNowModal open={isModalOpen} handleClose={closeModal} />
//     </HeaderContainer>
//   );
// }

// export default DashboardHeader;
import { Box, IconButton, Typography, Drawer, Modal, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { COLORS } from "../../../styles/Theme";
import {
  HeaderContainer,
  StyledAvatar,
  StyledBadge,
} from "./DashboardHeader.style";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import UserProfile from "../../../pages/Profile/UserProfile";
import ListNowModal from "../../../pages/Dashboard/Home/ListNowModal";
import { useNavigate, useLocation } from 'react-router-dom';
import { getRegisterApi, getCategoryDataApi } from "../../../services/api_calls/auth/AuthApi";
const createUrlSlug = (str) => {
  if (!str) return '';
  return str
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '_')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '_')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

const createCitySlug = (cityName) => {
  if (!cityName) return '';
  return cityName
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "")
    .replace(/-+/g, "_");
};

const generateServiceUrl = (cityPath, serviceName) => {
  const serviceSlug = createUrlSlug(serviceName);
  return cityPath ? `/${cityPath}/${serviceSlug}` : `/${serviceSlug}`;
};
function DashboardHeader({ onSearchClick }) {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(() => {
    return localStorage.getItem('selectedCity') || 'Bangalore';
  });
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [profileData, setProfileData] = useState({
    name: '',
    profileImage: '',
  });

  // Fetch profile data
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await getRegisterApi();
        if (response?.user) {
          setProfileData({
            name: response.user.name || '',
            profileImage: response.user.profilePicture || '',
          });
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    const token = localStorage.getItem('token');
    if (token) {
      fetchProfileData();
    }
  }, []);

  // Fetch services
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await getCategoryDataApi();
        if (response?.categories) {
          const allServices = response.categories.flatMap(category => 
            category.subCategory.map(sub => ({
              name: sub.name,
              categoryName: category.name,
              id: sub.id
            }))
          );
          setServices(allServices);
          setFilteredServices(allServices);
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  // Handle search
  useEffect(() => {
    if (searchTerm) {
      const filtered = services.filter(
        service => 
          service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          service.categoryName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredServices(filtered);
    } else {
      setFilteredServices(services);
    }
  }, [searchTerm, services]);

  useEffect(() => {
    if (location.state && location.state.selectedLocation) {
      setSelectedLocation(location.state.selectedLocation);
    }
  }, [location.state]);

  const onProfileClick = () => {
    setIsDrawerOpen(true);
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLocationClick = () => {
    navigate('/location');
  };

  const handleSearchClick = () => {
    setIsSearchModalOpen(true);
  };

  const handleServiceClick = (service) => {
    const citySlug = createCitySlug(selectedLocation);
    const serviceSlug = createUrlSlug(service.name);
    const url = generateServiceUrl(citySlug, service.name);
    
    navigate(url, {
      state: { 
        serviceName: service.name,
        categoryName: service.categoryName,
        selectedLocation: selectedLocation
      }
    });
    setIsSearchModalOpen(false);
  };

  return (  
    <HeaderContainer>
      <Box display="flex" alignItems="center" gap={1}>
        <Box
          component="img"
          src="/./assets/images/lpro-logo.png"
          alt="Local Pro"
          width={36}
          height={36}
          sx={{ objectFit: "contain" }}
        />
        <Box onClick={handleLocationClick} sx={{ cursor: 'pointer' }}>
          <Typography variant="body2" fontSize={14} fontWeight={500}>
            {selectedLocation}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <IconButton onClick={handleSearchClick}>
          <SearchOutlinedIcon />
        </IconButton>
        <IconButton>
          <StyledBadge badgeContent={5} color="secondary">
            <Box
              component="img"
              src="/./assets/images/notification-icon.png"
              alt="Notification"
              width={22}
              height={22}
              sx={{ objectFit: "contain" }}
            />
          </StyledBadge>
        </IconButton>
        <StyledAvatar
          alt={profileData.name || 'user profile'}
          src={profileData.profileImage || "https://images.pexels.com/photos/3785079/pexels-photo-3785079.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}
          onClick={onProfileClick}
          sx={{
            width: 40,
            height: 40,
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.8,
            },
          }}
        />
        <Drawer 
          anchor="right" 
          open={isDrawerOpen} 
          onClose={toggleDrawer(false)}
        >
          <Box
            role="presentation"
            sx={{ width: 250 }}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <UserProfile openModal={openModal} /> 
          </Box>
        </Drawer>
      </Box>
      <ListNowModal open={isModalOpen} handleClose={closeModal} />

      {/* Search Modal */}
      <Modal
        open={isSearchModalOpen}
        onClose={() => setIsSearchModalOpen(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '80vh',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" component="h2">
              Search Services
            </Typography>
            <IconButton onClick={() => setIsSearchModalOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search for services..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box sx={{ overflow: 'auto', flex: 1 }}>
            {filteredServices.map((service) => (
              <Box
                key={service.id}
                onClick={() => handleServiceClick(service)}
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.04)',
                  },
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                }}
              >
                <Typography variant="subtitle1">
                  {service.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {service.categoryName}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </HeaderContainer>
  );
}

export default DashboardHeader;