import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import { COLORS } from "../../../styles/Theme";

export const FormStyled = styled.form({
  padding: "25px",
});

export const ImgContainer = styled.div({
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    position: 'relative',
    margin:'20px 0 40px',
});

export const ProfileImg = styled.img({
  width: "127px",
  height: "127px",
  objectFit:'contain',
  borderRadius: "50%",
  backgroundColor: "#EBEBEB",
});

export const StyledIconButton = styled(IconButton)({
  color: COLORS.white,
  backgroundColor: COLORS.secondaryColor,
  position:'absolute',
  top:'5px',
  right:'6px',

  "&:hover": {
    color: COLORS.white,
    backgroundColor: COLORS.secondaryLight,
  },
});
