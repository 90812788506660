import { Box, TableBody, Typography } from "@mui/material";
import React from "react";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import Header from "../../../components/UI/Header";
import { COLORS } from "../../../styles/Theme";
import {
  StyledTable,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  WalletBalanceBox,
} from "./MyServicePlan.style";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import TransactionHistoryMinimal from "../../../components/Cards/TransactionHistoryMinimal";
import { TRANSACTION_HISTORY } from "../../../utils/Data";
import { useNavigate } from "react-router-dom";

function MyServicePlan() {
  const navigate = useNavigate();

  function createData(no, mySelectedService, perEnquiryPrice) {
    return { no, mySelectedService, perEnquiryPrice };
  }

  const rows = [
    createData(1, "Gas Geyser Repair", "₹ 10"),
    createData(2, "AC Service", "₹ . 30"),
    createData(3, "Car Service", "₹ 15"),
    createData(4, "Dry Cleaning", "₹ 05"),
    createData(5, "Flower Decorators", "₹ 50"),
  ];

  const handleAddMoney = () => {
    navigate("/businessProfile/enquiryPlans");
  };

  return (
    <Box>
      <Header title="My Service Plan" />

      <Box m={2}>
        <StyledTableContainer component={Box}>
          <StyledTable aria-label="simple table">
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCell>No.</StyledTableCell>
                <StyledTableCell align="center">
                  My Selected Services
                </StyledTableCell>
                <StyledTableCell align="center">
                  Per Enquiry Price
                </StyledTableCell>
              </StyledTableRow>
            </StyledTableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.no}>
                  <StyledTableCell scope="row">{row.no}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.mySelectedService}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.perEnquiryPrice}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      </Box>

      <WalletBalanceBox>
        <Box>
          <Typography
            variant="body2"
            fontSize={12}
            fontWeight={600}
            color={COLORS.black}
          >
            Your Wallet Balance
          </Typography>

          <Typography
            variant="h5"
            fontSize={28}
            fontWeight={600}
            color={COLORS.black}
          >
            ₹ 250
          </Typography>
        </Box>

        <StyledFormButton
          width={150}
          onClick={handleAddMoney}
          endIcon={<AddRoundedIcon />}
        >
          ADD MONEY
        </StyledFormButton>
      </WalletBalanceBox>

      <Box m={2}>
        <Typography
          variant="h6"
          fontSize={13}
          fontWeight={600}
          color={COLORS.black}
          mb={1.5}
        >
          Your Transactions History
        </Typography>

        {TRANSACTION_HISTORY?.map((item, index) => (
          <TransactionHistoryMinimal
            key={"transaction_" + index}
            price={item.price}
            datetime={item.datetime}
            status={item.status}
          />
        ))}
      </Box>
    </Box>
  );
}

export default MyServicePlan;
