import styled from "@emotion/styled";
import { Box, Modal } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";

export default function ModalCard(props) {
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <ModalWrapper>
        <ModalContentContainer>{props.children}</ModalContentContainer>
      </ModalWrapper>
    </Modal>
  );
}

const ModalWrapper = styled(Box)({
  height: "100vh",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
});

const ModalContentContainer = styled(Box)({
  width: "500px",
  height: "70vh",
  backgroundColor: COLORS.white,
  overflowY: "scroll",
  overflowX: "hidden",

  "&::-webkit-scrollbar": {
    width: "3px",
    backgroundColor: "#F5F5F5",
  },

  "&::-webkit-scrollbar-thumb": {
    borderRadius: "6px",
    backgroundColor: COLORS.primaryColor,
  },

  "@media(max-width: 600px)": {
    width: "100%",
  },
});
