import React from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';

function ThanksModal({ open, handleClose,username }) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            fullWidth
            PaperProps={{
                sx: {
                    margin: { xs: '10px', sm: '20px auto' }, // Margin to center on mobile
                    maxWidth: '400px',
                    width: '100%',
                },
            }}
        >
            <Box 
                p={{ xs: 2, sm: 3 }} 
                textAlign="center"
                sx={{ 
                    width: '100%', 
                    maxWidth: '400px', 
                    boxSizing: 'border-box',
                }}
            >
                <Box display="flex" justifyContent="center" mb={2}>
                    <img 
                        src="/./assets/images/check.svg" 
                        alt="Success Checkmark" 
                        style={{ width: '60px', height: '60px' }} 
                    />
                </Box>

                <Typography 
                    mb={2} 
                    sx={{
                        fontSize: { xs: '14px', sm: '16px' },
                        color: 'black',
                        fontWeight: '500',
                    }}
                >
                    Hi {username || 'User'}, Your Review Submitted Successfully. Thanks for the Review.
                </Typography>

                <Button 
                    onClick={handleClose} 
                    variant="contained" 
                    fullWidth
                    sx={{ 
                        backgroundColor: '#21608F', 
                        alignSelf: 'center',
                        width:'50%' ,
                        '&:hover': { backgroundColor: '#21608F' },
                        fontSize: { xs: '14px', sm: '16px' },
                        padding: { xs: '8px', sm: '10px 16px' },
                    }}
                >
                    OK
                </Button>
            </Box>
        </Dialog>
    );
}

export default ThanksModal;

// import React from 'react';
// import { Box, Button, Dialog, Typography } from '@mui/material';

// function ThanksModal({ open, handleClose }) {
//     return (
//         <Dialog
//             open={open}
//             onClose={handleClose}
//             maxWidth="xs"
//             fullWidth
//             PaperProps={{
//                 sx: {
//                     margin: { xs: '10px', sm: '20px auto' }, // Margin to center on mobile
//                     maxWidth: '400px',
//                     width: '100%',
//                 },
//             }}
//         >
//             <Box 
//                 p={{ xs: 2, sm: 3 }} 
//                 textAlign="center"
//                 sx={{ 
//                     width: '100%', 
//                     maxWidth: '400px', 
//                     boxSizing: 'border-box',
//                 }}
//             >
//                 <Box display="flex" justifyContent="center" mb={2}>
//                     <img 
//                         src="/./assets/images/check.svg" 
//                         alt="Success Checkmark" 
//                         style={{ width: '60px', height: '60px' }} 
//                     />
//                 </Box>

//                 <Typography 
//                     mb={2} 
//                     sx={{
//                         fontSize: { xs: '14px', sm: '16px' },
//                         color: 'black',
//                         fontWeight: '500',
//                     }}
//                 >
//                     Contact Details Submitted Successfuly. Thanks for the Review.
//                 </Typography>

//                 <Button 
//                     onClick={handleClose} 
//                     variant="contained" 
//                     fullWidth
//                     sx={{ 
//                         backgroundColor: '#21608F', 
//                         '&:hover': { backgroundColor: '#21608F' },
//                         fontSize: { xs: '14px', sm: '16px' },
//                         padding: { xs: '8px', sm: '10px 16px' },
//                     }}
//                 >
//                     OK
//                 </Button>
//             </Box>
//         </Dialog>
//     );
// }

// export default ThanksModal;
