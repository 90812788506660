import styled from "@emotion/styled";
import React from "react";

function BgVideo() {
  return (
    <Container>
      <Video autoPlay muted loop>
        <source src="/./assets/video/bg-video-small.mp4" type="video/mp4" />
      </Video>
    </Container>
  );
}

export default BgVideo;

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: fill;
`;

const Video = styled.video`
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;
