import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../components/UI/Header";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import { COLORS } from "../../../styles/Theme";
import VerticalStepper from "../../../components/UI/VerticalStepper/VerticalStepper";
import { DETAILS_STEPS } from "../../../utils/Data";
import { useNavigate } from "react-router-dom";

function EditProfile() {
  const navigate = useNavigate()
  const profile = localStorage.getItem('profile');
  const [activeStep, setActiveStep] = useState(profile);
  const [progress, setProgress] = useState(0);

  const calculateProgress = () => {
    const progressPerc = (activeStep / 8) * 100;
    setProgress(progressPerc);
  };

  const gotoNextStep = () => {
    if (activeStep < 8) {
      setActiveStep(activeStep + 1);
    }else{
      navigate('/home')
    }
  };

  useEffect(() => {
    calculateProgress();
    return () => {};
  }, [activeStep]);

  return (
    <Box>
      <Header title="Your Profile" disableShadow />

      <Typography
        variant="body1"
        fontSize={15}
        fontWeight={600}
        color={COLORS.primaryColor}
        textAlign="center"
      >
        {Math.floor(progress)}% Completed
      </Typography>

      <LinearProgress
        sx={{ height: 5, m: "10px 0" }}
        variant="determinate"
        value={progress}
      />

      <Box sx={{ p: 3.12 }}>
        <VerticalStepper
          steps={DETAILS_STEPS}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />

        <StyledFormButton
          onClick={() => {
            // navigate("/");
            gotoNextStep();
          }}
          sx={{ mt: 3 }}
          type="submit"
        >
          Submit
        </StyledFormButton>
      </Box>
    </Box>
  );
}

export default EditProfile;
