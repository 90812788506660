import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCatagoryDataApi, getCityDataApi, getSubCatagoryDataApi } from "../../services/api_calls/profile/profileApi";

const initialState = {
  profileContent: null,
  categoryContent: null,
  subCategoryContent: null,

  // loader
  profileContentLoader: false,
  categoryContentLoader: false,
  subCategoryContentLoader: false

};

export const getProfileData = createAsyncThunk(
  "getProfileData",
  async (thunkAPI) => {
    const responseData = await getCityDataApi();
    return responseData;
  }
);



export const getCategoryData = createAsyncThunk(
  "getCategoryData",
  async (thunkAPI) => {
    const responseData = await getCatagoryDataApi();
    return responseData;
  }
);


export const getSubCategoryData = createAsyncThunk(
  "getSubCategoryData",
  async (thunkAPI) => {
    const responseData = await getSubCatagoryDataApi();
    return responseData;
  }
);



export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: {
    // just for testing
    [getProfileData.pending]: (state) => {
      state.profileContentLoader = true;
    },
    [getProfileData.fulfilled]: (state, { payload }) => {
      state.profileContentLoader = false;
      state.profileContent = payload;
    },
    [getProfileData.rejected]: (state) => {
      state.profileContentLoader = false;
    },



    [getCategoryData.pending]: (state) => {
      state.categoryContentLoader = true;
    },
    [getCategoryData.fulfilled]: (state, { payload }) => {
      state.categoryContentLoader = false;
      state.categoryContent = payload;
    },
    [getCategoryData.rejected]: (state) => {
      state.categoryContentLoader = false;
    },


    [getSubCategoryData.pending]: (state) => {
      state.subCategoryContentLoader = true;
    },
    [getSubCategoryData.fulfilled]: (state, { payload }) => {
      state.subCategoryContentLoader = false;
      state.subCategoryContent = payload;
    },
    [getSubCategoryData.rejected]: (state) => {
      state.subCategoryContentLoader = false;
    },



  
  },
});

export const profileReducer = profileSlice.reducer;
