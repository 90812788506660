import styled from "@emotion/styled";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MyLocationIcon from '@mui/icons-material/MyLocation';

function StyledLocationInput({
  placeholder,
  name,
  value,
  onChange,
  maxLength,
  inputType,
  disabled,
}) {
  return (
    <Box sx={{ mb: 2, cursor: "pointer" }}>
      <StyledBox>
        <StyledTextField
          variant="standard"
          required={true}
          fullWidth
          name={name}
          disabled={disabled}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          inputProps={{ maxLength: maxLength }}
          type={inputType || "text"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
            // endAdornment: (
            //   <InputAdornment position="start" sx={{ cursor: "pointer" }}>
            //     {endIcon}
            //   </InputAdornment>
            // ),
            disableUnderline: true,
          }}
        />

        <LocationBox>
          <MyLocationIcon />
          <Typography
            variant="body2"
            fontSize={14}
            fontWeight={600}
            color="#656565"
            sx={{ ml: 1 }}
          >
            Location
          </Typography>
        </LocationBox>
      </StyledBox>
    </Box>
  );
}

export default StyledLocationInput;

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 0px 35px -15px rgba(0,0,0,0.13)",

  "&:before": {
    content: '" "',
    width: "5px",
    height: "50px",
    backgroundColor: COLORS.secondaryColor,
    position: "absolute",
  },
});

const LocationBox = styled(Box)({
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#E5E5E5",
  padding: "0 10px",
});

const StyledTextField = styled(TextField)({
    margin: '0 0 0 6px',
  boxShadow: "0px 0px 35px -15px rgba(0,0,0,0.13)",

  "& .MuiInputBase-input": {
    color: COLORS.black,
    fontSize: 14,
    fontWeight: 400,
    padding: "15px 6px",
  },
});
