import styled from "@emotion/styled";
import { Box, Checkbox, TextField, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";

function OpenCloseTimePicker({
  label,
  openTime,
  onOpenTimeChange,
  closeTime,
  onCloseTimeChange,
  isClosed,
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Typography variant="body2" width="32px" fontSize={14} fontWeight={500}>
        {label}
      </Typography>
      <StyledTimeInput
        variant="standard"
        type="time"
        InputProps={{
          disableUnderline: true,
        }}
        value={openTime}
        onChange={({ target }) => onOpenTimeChange(target.value)}
        disabled={isClosed}
      />
      -
      <StyledTimeInput
        variant="standard"
        type="time"
        InputProps={{
          disableUnderline: true,
        }}
        value={closeTime}
        onChange={({ target }) => onCloseTimeChange(target.value)}
        disabled={isClosed}
      />
      <Box>
        <Typography
          variant="body2"
          fontSize={8}
          fontWeight={500}
          textAlign="center"
          color={isClosed ? COLORS.red : COLORS.blackShade1}
        >
          Closed
        </Typography>
        <Checkbox
          checked={isClosed}
          sx={{
            "&.Mui-checked": {
              color: COLORS.red,
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default OpenCloseTimePicker;

const StyledTimeInput = styled(TextField)(({ disabled }) => ({
  boxShadow: "0px 0px 35px -15px rgba(0,0,0,0.13)",

  "&:before": {
    content: '" "',
    width: "5px",
    height: "100%",
    backgroundColor: COLORS.secondaryColor,
    position: "absolute",
    opacity: disabled ? "0.2" : "1",
  },

  "& .MuiInputBase-input": {
    color: COLORS.black,
    fontSize: 14,
    fontWeight: 400,
    padding: "15px",
  },
}));
