import { Box } from "@mui/material";
import React, { useState } from "react";
import Header from "../../../components/UI/Header";
import { FormStyled } from "./BusinessDetails.style";
import InputText from "../../../components/UI/Input/InputText";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import FileInput from "../../../components/UI/FileInput/FileInput";
import SelectedFileImg from "../../../components/UI/FileInput/SelectedFileImg";
import { getS3Url, registerBusinessApi } from "../../../services/api_calls/profile/profileApi";
import { useLocation, useNavigate } from "react-router-dom";

function BusinessDetails() {
  const navigate = useNavigate();

  const [businessname, setBusinessName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [businessImg, setBusinessImg] = useState("");
  const [businessImgUrl, setBusinessImgUrl] = useState("");


  const deleteBusinessImg = () => {
    setBusinessImg("");
  };


  const handleClick = async (event) => {
    event.preventDefault()
    if (businessname && mobileNumber && email && businessImgUrl) {
      const response = await registerBusinessApi({ businessname, mobileNumber, email , businessImgUrl });
      console.log(response.percentage);
      localStorage.setItem('profile', 2);
      navigate("/editProfile");
    }
    else{
      alert("Fill All details")
    }
  };

  const onchangeS3 = async (img) =>{
    const response = await getS3Url({ img });
    console.log('url:', response.data)
    setBusinessImgUrl(response.data)

  }

  return (
    <Box>
      <Header title="Business Name & Address" />

      <FormStyled>
        <InputText
          value={businessname}
          onChange={({ target }) => {
            setBusinessName(target.value);
          }}
          placeholder="Business Name"
        />

        <InputText
          value={mobileNumber}
          onChange={({ target }) => {
            setMobileNumber(target.value);
          }}
          placeholder="Business Contact Number"
        />

        <InputText
          value={email}
          onChange={({ target }) => {
            setEmail(target.value);
          }}
          placeholder="Email ID"
        />

        <FileInput label="Business Image" setImage={setBusinessImg} api={onchangeS3}/>

        {businessImg && (
          <SelectedFileImg imgURL={businessImg}  onDelete={deleteBusinessImg}  />
        )}

        <StyledFormButton
           onClick={handleClick}
          sx={{ mt: 2 }}
          type="submit"
        >
          Submit
        </StyledFormButton>
      </FormStyled>
    </Box>
  );
}

export default BusinessDetails;
