import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const Loader = ({ message = "Fetching data, please wait...", size = 50, color = 'primary' }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
            }}
        >
            <img 
                src={process.env.PUBLIC_URL + "/assets/images/logo.jpg"} 
                alt="Logo" 
                style={{ width: 100, height: 100, marginBottom: 20 }} 
            />
            <CircularProgress size={size} color={color} />
            {/* {message && (
                <Typography
                    mt={2}
                    sx={{ fontSize: '16px', color: '#666', fontWeight: 500 }}
                >
                    {message}
                </Typography>
            )} */}
        </Box>
    );
};

export default Loader;