import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../styles/Theme";

function CategoryCardSmall({ imgUrl, title, onClick, selected }) {
  return (
    <StyledCard selected={selected} onClick={onClick}>
      <Box
        component="img"
        src={imgUrl}
        alt="service image"
        sx={{ width: "100%", height: "auto", objectFit: "contain" }}
      />

      <Typography
        variant="body2"
        fontSize={7}
        fontWeight={600}
        textAlign="center"
      >
        {title}
      </Typography>
    </StyledCard>
  );
}

export default CategoryCardSmall;

const StyledCard = styled(Box)(({ selected }) => ({
  cursor: "pointer",
  padding: "8px",
  backgroundColor: selected ? COLORS.greyShade12 : COLORS.white,
}));
