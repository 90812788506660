import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationAutocomplete from './LocationAutocomplete';
import { getCityDataApi } from '../../../services/api_calls/auth/AuthApi';

const LocationSearchButton = ({ location, onLocationSelect }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await getCityDataApi();
        if (response?.cities) {
          const formattedCities = response.cities.map((city) => ({
            label: city.name,
            id: city.id,
            type: 'city',
          }));
          setCities(formattedCities);
        }
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };
    fetchCities();
  }, []);

  const handleLocationClick = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleLocationSelection = (selectedLocation) => {
    onLocationSelect(selectedLocation);
    setDialogOpen(false);
  };

  return (
    <>
      <TextField
        value={location}
        onClick={handleLocationClick}
        placeholder="Select Location"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnIcon />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        sx={{
          width: "300px",
          "& .MuiOutlinedInput-root": {
            borderRadius: 0,
            "& fieldset": {
              borderColor: "transparent",
            },
            "&:hover fieldset": {
              borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent",
            },
          },
        }}
      />
      <LocationAutocomplete
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onLocationSelect={handleLocationSelection}
        cities={cities}
      />
    </>
  );
};

export default LocationSearchButton;