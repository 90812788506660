import { Box, Typography, Alert, Snackbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { StyledFormButton } from "../../../../components/UI/Button/StyledButtons";
import Header from "../../../../components/UI/Header";
import TextareaInput from "../../../../components/UI/Input/TextareaInput";
import MessageCard from "../../../../components/Cards/MessageCard";
import { getComplaintsApi, COMPLAINT_TYPES, createComplaintApi } from "../../../../services/api_calls/auth/AuthApi";

function LocationIssue() {
  const [message, setMessage] = useState("");
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success"
  });

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const showNotification = (message, severity = "success") => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  const fetchComplaints = async () => {
    try {
      const response = await getComplaintsApi(COMPLAINT_TYPES.LOCATION);
      if (response.success) {
        setComplaints(response.data);
      } else {
        showNotification(response.message || 'Error fetching complaints', 'error');
      }
    } catch (error) {
      console.error('Error fetching complaints:', error);
      showNotification('Failed to load complaints', 'error');
    }
  };

  useEffect(() => {
    fetchComplaints();
  }, []);

  const handleSubmit = async () => {
    if (!message.trim()) {
      showNotification('Please write your message', 'error');
      return;
    }

    setLoading(true);
    try {
      const response = await createComplaintApi({
        message: message.trim(),
        complaint_type: COMPLAINT_TYPES.LOCATION
      });

      if (response.success) {
        showNotification('Complaint submitted successfully');
        setMessage('');
        fetchComplaints();
      } else {
        showNotification(response.message || 'Failed to submit complaint', 'error');
      }
    } catch (error) {
      console.error('Error submitting complaint:', error);
      showNotification('Failed to submit complaint', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Header title="Location" />

      <Box m="30px 16px 16px 16px">
        <Typography
          variant="body2"
          fontSize={12}
          fontWeight={500}
          lineHeight="27px"
          textAlign="center"
        >
  If not getting you searched locations write location
        </Typography>
      </Box>

      <Box m={2}>
        <TextareaInput
          placeholder="Write your message here"
          value={message}
          onChange={({ target }) => {
            setMessage(target.value);
          }}
        />
        <StyledFormButton 
          onClick={handleSubmit}
          disabled={loading || !message.trim()}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </StyledFormButton>
      </Box>

      <Box m={2}>
        {complaints?.map((complaint, index) => (
          <MessageCard
            key={complaint.id || index}
            title="Location Issue"
            subtitle={complaint.status}
            text={complaint.message}
            datetime={complaint.created_at}
            replyMessage={complaint.reply?.message}  // Updated to use the reply message from API
            replyDate={complaint.reply?.replied_on}  // Updated to use the reply date from API
            hasReply={Boolean(complaint.reply)}      // Added to check if reply exists
          />
        ))}
      </Box>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default LocationIssue;