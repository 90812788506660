import styled from '@emotion/styled';
import { Avatar, Box, Rating, Typography } from '@mui/material';
import React from 'react'
import { COLORS } from '../../styles/Theme';
import StarIcon from "@mui/icons-material/Star";

function ReviewCard({ imgUrl, name, date, rating, message, onClick }) {
  return (
    <StyledReviewCard onClick={onClick}>
    <Avatar
      alt="user profile img"
      src={imgUrl}
    />

    <Box flex={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h4"
          fontSize={16}
          fontWeight={500}
          color={COLORS.primaryColor}
        >
          {name}
        </Typography>

        <Typography
          variant="boby1"
          fontSize={11}
          fontWeight={500}
          color={COLORS.black}
        >
          Posted on {date}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap="4px">
        <Typography
          variant="h4"
          fontSize={14}
          fontWeight={500}
          color={COLORS.black}
        >
          {rating}
        </Typography>

        <Box height={22}>
          <Rating
            name="read-only"
            size="small"
            value={rating}
            precision={0.5}
            readOnly
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        </Box>
      </Box>

      <Typography
        variant="body1"
        fontSize={12}
        fontWeight={400}
        color={COLORS.grey}
        lineHeight="20px"
        mt={0.5}
      >
        {message}
      </Typography>
    </Box>
  </StyledReviewCard>
  )
}

export default ReviewCard

const StyledReviewCard = styled(Box)({
  display: "flex",
  gap: "8px",
  padding: "12px",
  marginBottom: "10px",
  borderBottom: "1px solid #e8e8e8",
});
