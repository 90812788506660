import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../../../components/UI/Header";

import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryData,
  getSubCategoryData,
} from "../../../redux/profile/profileSlice";
import SelectInputStyled2 from "../../../components/UI/Select/SelectInputStyled2";
import {
  getSubCatagoryDataApi,
  registerBusinessApi,
} from "../../../services/api_calls/profile/profileApi";
import SelectInputStyled from "../../../components/UI/Select/SelectInputStyled";

function ServiceCatagory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [service, setService] = useState("Subject Type");
  const [category, setCategory] = useState("Select Category");
  const [sub, setCat] = useState("Select Sub Category");
  const [subCategory, setSubCategory] = useState(null);

  const {
    categoryContent,
    categoryContentLoader,
    subCategoryContent,
    subCategoryContentLoader,
  } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getCategoryData());
    return () => {};
  }, []);

  const getSubCatogery = async (id) => {
    const response = await getSubCatagoryDataApi({ id });
    setSubCategory(response);
  };

  const handleClick = async (event) => {
    event.preventDefault();
    if (category && sub) {
      const response = await registerBusinessApi({ category, sub });
      console.log(response.percentage);
      localStorage.setItem("profile", 3);
      navigate("/editProfile");
    } else {
      alert("Fill All details");
    }
  };

  return (
    <Box>
      <Header title="Service Category" />

      <FormStyled>
        {categoryContent ? (
          <SelectInputStyled
            value={category}
            onValueChange={(selectedItem) => {
              setCategory(selectedItem?.name);
              getSubCatogery(selectedItem?.id);
              setCat("Select Sub Category");
            }}
            menuList={[{ name: "Select Category" }, ...categoryContent.data]}
            valueSelector="name"
          />
        ) : (
          <></>
        )}

        {subCategory ? (
          <SelectInputStyled
            value={sub}
            onValueChange={(selectedItem) => {
              setCat(selectedItem?.name);
            }}
            menuList={[{ name: "Select Sub Category" }, ...subCategory.data]}
            valueSelector="name"
          />
        ) : (
          <></>
        )}

        {/* <SelectInputStyled
          value={board}63d40663970974a83b16a045
          onValueChange={(text) => {
            setBoard(text);
          }}
          menuList={BOARD_LIST}
        /> */}

        {/* <SelectInputStyled
          value={tutiontype}
          onValueChange={(text) => {
            setTutiontype(text);
          }}
          menuList={TUTION_TYPE}
        /> */}

        {/* <SelectInputStyled
          value={subject}
          onValueChange={(text) => {
            setSubject(text);
          }}
          menuList={SUBJECT_LIST}
        /> */}

        <StyledFormButton onClick={handleClick} sx={{ mt: 2 }} type="submit">
          Submit
        </StyledFormButton>
      </FormStyled>
    </Box>
  );
}

export default ServiceCatagory;

const FormStyled = styled.form({
  padding: "25px",
});
