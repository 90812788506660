import React from "react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { BackButton, HeaderWrapper } from "./Header.style";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Header({ title, handleBack, disableShadow }) {
  const navigate = useNavigate();
  return (
    <HeaderWrapper shadowdisabled={disableShadow}>
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosNewRoundedIcon sx={{ fontSize: "20px" }} />
      </BackButton>

      <Typography variant="h3" component="h3" fontSize={16}>
        {title}
      </Typography>
    </HeaderWrapper>
  );
}

export default Header;
