// src/components/UI/Footer/Support.js
import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Card,
  CardContent,
  styled,
  useMediaQuery,
  ThemeProvider,
  createTheme
} from '@mui/material';
import {
  ExpandMore,
  Email,
  Phone,
  WhatsApp,
  ContactSupport
} from '@mui/icons-material';
import Header from "../DashboardHeader/Header";
import BottomTabs from '../BottomTabs';
import Footer from './Footer';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '40px',
  margin: '20px 0',
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  '@media (max-width: 600px)': {
    padding: '20px',
    margin: '10px 0',
  },
}));

const StyledAccordion = styled(Accordion)({
  marginBottom: '10px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: '10px 0',
  },
});

const ContactCard = styled(Card)({
  height: '100%',
  transition: 'transform 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
});

const Support = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqs = [
    {
      question: "How do I book a service?",
      answer: "To book a service, simply browse our available services, select the one you need, choose a service provider, and select your preferred time slot. Follow the booking process and confirm your appointment."
    },
    {
      question: "What if I need to cancel my booking?",
      answer: "You can cancel your booking up to 4 hours before the scheduled time through your account dashboard. Navigate to 'My Bookings' and click on the cancel button for the specific service."
    },
    {
      question: "How can I become a service provider?",
      answer: "To become a service provider, click on the 'Join as Professional' button, fill out the application form, submit required documents, and our team will review your application within 48 hours."
    },
    {
      question: "Is there a warranty on services?",
      answer: "Service warranties vary by provider and service type. Check the service details page for specific warranty information, or contact the service provider directly."
    },
    {
      question: "How do I make a payment?",
      answer: "We accept various payment methods including credit/debit cards, UPI, and net banking. Payments are securely processed at the time of booking confirmation."
    }
  ];

  const supportTopics = [
    {
      icon: <Email fontSize="large" />,
      title: "Email Support",
      content: "support@localpro.com",
      action: "mailto:support@localpro.com"
    },
    {
      icon: <Phone fontSize="large" />,
      title: "Phone Support",
      content: "90909 09090",
      action: "tel:9090909090"
    },
    {
      icon: <WhatsApp fontSize="large" />,
      title: "WhatsApp",
      content: "Chat with us on WhatsApp",
      action: "https://wa.me/9090909090"
    },
    {
      icon: <ContactSupport fontSize="large" />,
      title: "Help Center",
      content: "Browse our knowledge base",
      action: "/help-center"
    }
  ];
  const ResponsiveContainer = styled(Container)`
  padding: 120px;
  
  @media (max-width: 600px) {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
`;
  const theme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            maxWidth: "1200px",
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
    <Header />
    <ResponsiveContainer maxWidth="lg">
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 'bold',
            color: '#21608F',
            mb: 3,
            textAlign: 'center'
          }}
        >
          How Can We Help You?
        </Typography>

        {/* Contact Cards */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          {supportTopics.map((topic, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <ContactCard>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Box sx={{ color: '#21608F', mb: 2 }}>
                    {topic.icon}
                  </Box>
                  <Typography variant="h6" gutterBottom>
                    {topic.title}
                  </Typography>
                  <Typography color="textSecondary" sx={{ mb: 2 }}>
                    {topic.content}
                  </Typography>
                  <Button 
                    variant="contained" 
                    href={topic.action}
                    sx={{ 
                      backgroundColor: '#21608F',
                      '&:hover': { backgroundColor: '#184569' }
                    }}
                  >
                    Contact Now
                  </Button>
                </CardContent>
              </ContactCard>
            </Grid>
          ))}
        </Grid>

        {/* FAQs Section */}
        <StyledPaper>
          <Typography variant="h5" sx={{ mb: 3, color: '#21608F', fontWeight: 600 }}>
            Frequently Asked Questions
          </Typography>
          
          {faqs.map((faq, index) => (
            <StyledAccordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Typography sx={{ fontWeight: 500 }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="textSecondary">
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </StyledAccordion>
          ))}
        </StyledPaper>

        {/* Additional Support Section */}
        <StyledPaper>
          <Typography variant="h5" sx={{ mb: 3, color: '#21608F', fontWeight: 600 }}>
            Additional Support Options
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Business Hours
              </Typography>
              <Typography color="textSecondary" paragraph>
                Monday - Saturday: 9:00 AM - 6:00 PM
              </Typography>
              <Typography color="textSecondary" paragraph>
                Sunday: Closed
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Emergency Support
              </Typography>
              <Typography color="textSecondary" paragraph>
                For urgent issues outside business hours, please call our emergency support line:
              </Typography>
              <Button 
                variant="contained"
                startIcon={<Phone />}
                sx={{ 
                  backgroundColor: '#21608F',
                  '&:hover': { backgroundColor: '#184569' }
                }}
              >
                Call Emergency Support
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
    </ResponsiveContainer>
    {isMobile && <BottomTabs activeTab="support" />}
    <Footer />
   </ThemeProvider>
  );
};

export default Support;