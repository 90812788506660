import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { COLORS } from "../../../styles/Theme";

export const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  background-color: ${COLORS.white};
  /* box-shadow: 0px 19px 38px -28px rgba(0,0,0,0.3); */
  box-shadow: ${({ shadowdisabled }) =>
    shadowdisabled ? "none" : "0px 19px 38px -20px rgba(0,0,0,0.13)"};
`;

export const BackButton = styled(Box)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #efeeee;
`;
