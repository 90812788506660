import { Box, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";
import {
  StyledIconImg,
  StyledInfoBox,
} from "./BusinessProfile.style";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

function BusinessInfoQuickLinks({
  onEnquiredClick,
  onReviewsClick,
  onWalletClick,
}) {
  return (
    <Box display="flex" alignItems="center" gap="10px" m="12px 0">
      <StyledInfoBox onClick={onEnquiredClick}>
        <StyledIconImg src="/./assets/images/phone-icon.png" alt="icon" />

        <Typography
          variant="body2"
          fontSize={12}
          fontWeight={500}
          color={COLORS.blackShade1}
          textAlign="center"
          mt={1}
        >
          Total Customer Enquired 25
        </Typography>
      </StyledInfoBox>

      <StyledInfoBox onClick={onReviewsClick}>
        <Box display="flex" alignItems="flex-end" gap="4px">
          <StyledIconImg src="/./assets/images/star-yellow.png" alt="icon" />
          <Typography
            variant="body2"
            fontSize={20}
            fontWeight={600}
            lineHeight="18px"
            color={COLORS.secondaryColor}
          >
            4.5
          </Typography>
        </Box>

        <Typography
          variant="body2"
          fontSize={12}
          fontWeight={500}
          color={COLORS.blackShade1}
          textAlign="center"
          mt={1}
        >
          Total Customer Reviews 25
        </Typography>
      </StyledInfoBox>

      <StyledInfoBox jc="flex-end" onClick={onWalletClick}>
        <StyledIconImg src="/./assets/images/wallet-grey.png" alt="icon" />

        <Typography
          variant="body2"
          fontSize={12}
          fontWeight={500}
          color={COLORS.blackShade1}
          textAlign="center"
          mt={1}
        >
          Wallet Balance
        </Typography>

        <Typography
          variant="body2"
          fontSize={13}
          fontWeight={600}
          color={COLORS.red}
          textAlign="center"
          mt={0.2}
        >
          250{" "}
          <AddRoundedIcon
            sx={{ fontSize: "20px", color: COLORS.black, mb: "-5px" }}
          />
        </Typography>

        <Typography
          variant="body2"
          fontSize={8}
          fontWeight={500}
          color={COLORS.red}
          textAlign="center"
        >
          RECHARGE NOW
        </Typography>
      </StyledInfoBox>
    </Box>
  );
}

export default BusinessInfoQuickLinks;
