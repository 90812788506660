import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ScrollToTop from "./components/ScrollToTop";
import SplashLogo from "./components/Splash/SplashLogo";
import ChooseLocation from "./pages/Intro/ChooseLocation";
import Welcome from "./pages/Intro/Welcome/Welcome";
import AddPersonalDetails from "./pages/Profile/AddPersonalDetails";
import EditProfile from "./pages/Profile/EditProfile";

import BusinessAddress from "./pages/Profile/BusinessAddress";
import BusinessDetails from "./pages/Profile/BusinessDetails";
import ServiceCatagory from "./pages/Profile/ServiceCatagory";
import TermandConditions from "./pages/Profile/Term&Conditions";
import AddReferDetails from "./pages/Refer/AddfReferDetails";
import AddDocuments from "./pages/Profile/AddDocuments";
import BusinessTiming from "./pages/Profile/BusinessTiming";
import OTPVerify from "./pages/Auth/OTPVerify";
import RegisterDetails from "./pages/Auth/RegisterDetails";
import Home from "./pages/Dashboard/Home";
import Wishlist from "./pages/Dashboard/Wishlist";
import ContactedPro from "./pages/Dashboard/ContactedPro";
import UserProfile from "./pages/Profile/UserProfile";
import MySubscriptions from "./pages/Subscription/MySubscriptions";
import ContactPlans from "./pages/Subscription/ContactPlans";
import HelpSupport from "./pages/Profile/HelpSupport";
import AccountIssue from "./pages/Profile/HelpSupport/AccountIssue";
import PaymentIssue from "./pages/Profile/HelpSupport/PaymentIssue";
import ProComplaints from "./pages/Profile/HelpSupport/ProComplaints";
import LocationIssue from "./pages/Profile/HelpSupport/LocationIssue";
import BusinessListingIssue from "./pages/Profile/HelpSupport/BusinessListingIssue";
import LoginIssue from "./pages/Profile/HelpSupport/LoginIssue";
import BusinessProfile from "./pages/BusinessProfile/BusinessProfile";
import Contacts from "./pages/BusinessProfile/Contacts";
import Reviews from "./pages/BusinessProfile/Reviews";
import MyServicePlan from "./pages/BusinessProfile/MyServicePlan";
import EnquiryPlans from "./pages/BusinessProfile/EnquiryPlans";
import Categories from "./pages/Dashboard/Categories";
import ProfessionalDetails from "./pages/ProfessionalDetails";
import AllMessages from "./pages/Profile/HelpSupport/AllMessages";
import ServiceDetails from "./pages/ServiceDetails";
import NotVerified from "./pages/ProfessionalDetails/NotVerified";
import AboutLocalPro from "./pages/Profile/AboutLocalPro";
import LocationSelection from "./components/UI/DashboardHeader/LocationSelection";
import AboutUs from "./components/UI/Footer/About";
import Services from "./components/UI/Footer/Services";
import Contact from "./components/UI/Footer/ContactUs";
import PrivacyPolicy from "./components/UI/Footer/Privacy";
import TermsAndConditions from "./components/UI/Footer/Terms";
import Support from "./components/UI/Footer/Support";
import ListYourBusiness from "./pages/Dashboard/Home/ListYourBusiness";
import BlogPage from "./pages/Blog";
import BlogDetail from "./pages/Blog/BlogDetail";
import CategoryPosts from "./pages/Blog/BlogDetialall";
function App() {
  return (
    <Router forceRefresh={true}>
      <ScrollToTop>
        <Routes>
          {/* <Route path="/" exact element={<SplashLogo />} /> */}
          <Route path="/welcome" exact element={<Welcome />} />
          <Route path="/chooseLocation" exact element={<ChooseLocation />} />
          <Route path="/location" exact element={<LocationSelection />} />
          {/* Auth */}

          <Route path="/otpVerify" exact element={<OTPVerify />} />
          <Route path="/registerDetails" exact element={<RegisterDetails />} />

          {/* Profile */}
          <Route path="/profile" exact element={<UserProfile />} />
          <Route path="list-business" element={<UserProfile />} />
          <Route path="/list-your-business" element={<ListYourBusiness />} />
          <Route path="/editProfile" exact element={<EditProfile />} />
          <Route
            path="/addPersonalDetails"
            exact
            element={<AddPersonalDetails />}
          />
          <Route path="/addReferDetails" exact element={<AddReferDetails />} />
          <Route path="/serviceCatagory" exact element={<ServiceCatagory />} />
          <Route path="/addDocuments" exact element={<AddDocuments />} />
          <Route path="/businessDetails" exact element={<BusinessDetails />} />
          <Route
            path="/termAndConditions"
            exact
            element={<TermandConditions />}
          />
          <Route path="/businessAddress" exact element={<BusinessAddress />} />
          <Route path="/businessTiming" exact element={<BusinessTiming />} />
          <Route path="/about" exact element={<AboutLocalPro />} />

          {/* Business Profile */}
          <Route path="/businessProfile" exact element={<BusinessProfile />} />
          <Route
            path="/businessProfile/contacts"
            exact
            element={<Contacts />}
          />
          <Route path="/businessProfile/reviews" exact element={<Reviews />} />
          <Route
            path="/businessProfile/myServicePlan"
            exact
            element={<MyServicePlan />}
          />
          <Route
            path="/businessProfile/enquiryPlans"
            exact
            element={<EnquiryPlans />}
          />

          {/* Help Center */}
          <Route path="/helpCenter" exact element={<HelpSupport />} />
          <Route path="/helpCenter/account" exact element={<AccountIssue />} />
          <Route path="/helpCenter/payments" exact element={<PaymentIssue />} />
          <Route
            path="/helpCenter/proComplaints"
            exact
            element={<ProComplaints />}
          />
          <Route
            path="/helpCenter/location"
            exact
            element={<LocationIssue />}
          />
          <Route
            path="/helpCenter/businessListing"
            exact
            element={<BusinessListingIssue />}
          />
          <Route path="/helpCenter/loginIssue" exact element={<LoginIssue />} />
          <Route
            path="/helpCenter/allMessages"
            exact
            element={<AllMessages />}
          />

          {/* Dashboard */}
          {/* home */}
          <Route path="/" exact element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/support" element={<Support />} />
          <Route path="/wishlist" exact element={<Wishlist />} />
          <Route path="/contactedPro" exact element={<ContactedPro />} />
          <Route path="/categories" exact element={<Categories />} />
          <Route path="/:citySlug" element={<Home />} />
          <Route path="/:citySlug/:serviceSlug" element={<ServiceDetails />} />
          <Route
            path="/:citySlug/:serviceSlug/:locationSlug"
            element={<ServiceDetails />}
          />
          <Route
            path="/:citySlug/:serviceSlug/business/:businessSlug"
            element={<ProfessionalDetails />}
          />
          <Route
            path="/:citySlug/:serviceSlug/business/:businessSlug/preview"
            element={<NotVerified />}
          />
          <Route
            path="/:citySlug/:serviceSlug/:locationSlug/business/:businessSlug"
            element={<ProfessionalDetails />}
          />
          <Route
            path="/:citySlug/:serviceSlug/:locationSlug/business/:businessSlug/preview"
            element={<NotVerified />}
          />
          {/* <Route path="/categories" exact element={<Categories />} />
          <Route path="/:serviceSlug/:businessSlug" exact element={<ProfessionalDetails />} />
          <Route path="/professionalDetails" exact element={<ProfessionalDetails />} />
          <Route path="/:serviceSlug/:businessSlug/preview" exact element={<NotVerified />} />
          <Route path="/:serviceName" element={<ServiceDetails />} />
          <Route path="/serviceDetails" exact element={<ServiceDetails />} /> */}

          {/* Subscriptions */}
          <Route path="/mySubscriptions" exact element={<MySubscriptions />} />
          <Route path="/contactPlans" exact element={<ContactPlans />} />

          {/*Blogs */}
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:blogName" element={<BlogDetail />} />
          <Route path="/blog/category/:categoryName" element={<CategoryPosts />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
