import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React from "react";
import Header from "../../../components/UI/Header";
import { COLORS } from "../../../styles/Theme";
import CallRoundedIcon from "@mui/icons-material/CallRounded";

function Contacts() {
  return (
    <Box>
      <Header title="Contacted List" />

      <Box m={2}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 11, 12, 13].map((item, index) => (
          <ContactCard
            key={"contact_" + index}
            name="Sharath N"
            date="Dec 12, 2022"
            time="7:05pm"
            onClick={() => {}}
          />
        ))}
      </Box>
    </Box>
  );
}

export default Contacts;

const ContactCard = ({ name, date, time, onClick }) => (
  <StyledContactCard onClick={onClick}>
    <Box>
      <Typography
        variant="h4"
        fontSize={14}
        fontWeight={500}
        color={COLORS.black}
        textAlign="left"
      >
        {name}
      </Typography>

      <Typography
        variant="boby1"
        fontSize={10}
        fontWeight={400}
        color={COLORS.blackShade1}
        textAlign="left"
      >
        {date} at {time}
      </Typography>
    </Box>

    <StyledPhoneIcon>
      <CallRoundedIcon />
    </StyledPhoneIcon>
  </StyledContactCard>
);

const StyledContactCard = styled.button({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: "none",
  borderRadius: "6px",
  backgroundColor: COLORS.white,
  boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.1)",
  padding: "12px",
  marginBottom: "10px",
  cursor:'pointer'
});

const StyledPhoneIcon = styled(Box)({
  width: 30,
  height: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: COLORS.white,
  borderRadius: "3px",
  backgroundColor: COLORS.primaryColor,
});
