import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTestDataApi } from "../../services/api_calls/home/HomeAPIs";

const initialState = {
  homeTextContent: null,
  homeCategary: null,

  // loader
  homeTextContentLoader: false,
  homeCategaryLooader: false,
};

export const getHomeTestData = createAsyncThunk(
  "getHomeTestData",
  async (thunkAPI) => {
    const responseData = await getTestDataApi();
    return responseData;
  }
);

export const getHomeCategoryData = createAsyncThunk(
  "getHomeCategoryData",
  async (thunkAPI) => {
    const responseData = await getTestDataApi();
    return responseData;
  }
);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: {
    // just for testing
    [getHomeTestData.pending]: (state) => {
      state.homeTextContentLoader = true;
    },
    [getHomeTestData.fulfilled]: (state, { payload }) => {
      state.homeTextContentLoader = false;
      state.homeTextContent = payload;
    },
    [getHomeTestData.rejected]: (state) => {
      state.homeTextContentLoader = false;
    },

    // category data
    [getHomeCategoryData.pending]: (state) => {
      state.homeCategaryLooader = true;
    },
    [getHomeCategoryData.fulfilled]: (state, { payload }) => {
      state.homeCategaryLooader = false;
      state.homeCategary = payload;
    },
    [getHomeCategoryData.rejected]: (state) => {
      state.homeCategaryLooader = false;
    },
  },
});

export const homeReducer = homeSlice.reducer;
