import styled from "@emotion/styled";
import React from "react";

const LogoFullWidth = ({ width, height }) => (
  <LogoIcon
    src="/./assets/images/lpro-full-logo.png"
    alt="local pro"
    width={width}
    height={height}
  />
);

const LogoIcon = styled.img`
  width: ${({ width }) => width || "280px"};
  height: ${({ height }) => height || "100px"};
  object-fit: contain;
`;

export default LogoFullWidth;
