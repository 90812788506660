import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  createTheme,
  useMediaQuery,
  ThemeProvider,
} from "@mui/material";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import BusinessIcon from "@mui/icons-material/Business";
import CategoryIcon from "@mui/icons-material/Category";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BottomTabs from "../../../components/UI/BottomTabs";

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: "1200px",
        },
      },
    },
  },
});

const StepCard = ({ icon, title, description, index }) => {
  const theme = useTheme();
  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        height: "80%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-10px)",
          boxShadow: theme.shadows[10],
        },
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 20,
          left: 20,
          width: 40,
          height: 40,
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.main,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          fontWeight: "bold",
          fontSize: "1.2rem",
        }}
      >
        {index}
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          borderRadius: "50%",
          p: 3,
          mb: 3,
        }}
      >
        {icon}
      </Box>
      <Typography
        variant="h5"
        sx={{ mb: 3, fontWeight: "bold", textAlign: "center" }}
      >
        {title}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "center" }}>
        {description}
      </Typography>
    </Paper>
  );
};

const FAQSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqs = [
    {
      question: "What are the benefits of listing a business on Local Pro?",
      answer:
        "Local Pro offers increased online visibility, access to a large customer base, improved local SEO, and tools to manage your online reputation.",
    },
    {
      question: "Is listing my business on Local Pro free?",
      answer:
        "Yes, you can list your business on Local Pro for free. Premium features are available for enhanced promotion.",
    },
    {
      question: "How does Local Pro differ from other business directories?",
      answer:
        "Local Pro focuses on connecting local businesses with nearby customers, offers advanced analytics, and provides unique features for service-based businesses.",
    },
    {
      question: "Can I manage my Local Pro listing from a mobile app?",
      answer:
        "Yes, Local Pro offers a mobile app for easy management of your business listing on-the-go.",
    },
    {
      question: "How can Local Pro help me get more customer reviews?",
      answer:
        "Local Pro provides tools to encourage satisfied customers to leave reviews and helps you manage and respond to all reviews efficiently.",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ mt: 10 }}>
      <Typography
        variant="h4"
        sx={{ mb: 5, fontWeight: "bold", textAlign: "center" }}
      >
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{ mb: 2, "&:before": { display: "none" } }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              "& .MuiAccordionSummary-content": {
                margin: "16px 0",
              },
            }}
          >
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
    {isMobile && <BottomTabs activeTab="list-your-business" />}
    </ThemeProvider>
  );
};

const BusinessListingSteps = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const steps = [
    {
      icon: (
        <PhoneIphoneIcon
          sx={{ fontSize: 50, color: theme.palette.primary.main }}
        />
      ),
      title: "Quick Sign Up",
      description:
        "Create your account in seconds with just your mobile number",
    },
    {
      icon: (
        <BusinessIcon
          sx={{ fontSize: 50, color: theme.palette.primary.main }}
        />
      ),
      title: "Showcase Your Business",
      description:
        "Add your business details, services, and eye-catching photos",
    },
    {
      icon: (
        <CategoryIcon
          sx={{ fontSize: 50, color: theme.palette.primary.main }}
        />
      ),
      title: "Boost Your Visibility",
      description:
        "Optimize your listing with relevant categories and keywords",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Box sx={{ my: 10 }}>
          <Typography
            variant="h4"
            sx={{
              mb: 8,
              fontWeight: "bold",
              textAlign: "center",
              color: theme.palette.primary.main,
            }}
          >
            Launch Your Online Presence in 3 Easy Steps
          </Typography>
          <Grid container spacing={6}>
            {steps.map((step, index) => (
              <Grid item xs={12} md={4} key={index}>
                <StepCard {...step} index={index + 1} />
              </Grid>
            ))}
          </Grid>
          <FAQSection />
        </Box>
      </Container>
      {isMobile && <BottomTabs activeTab="list-your-business" />}
    </ThemeProvider>
  );
};

export default BusinessListingSteps;
