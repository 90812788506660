import { Box } from "@mui/material";
import React, { useState } from "react";
import Header from "../../../components/UI/Header";
import {
    FormStyled,
} from "./AddReferDetails.style";
import InputText from "../../../components/UI/Input/InputText";
import SelectInputStyled from "../../../components/UI/Select/SelectInputStyled";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import { useNavigate } from "react-router-dom";
import { SERVICE_LIST } from "../../../utils/Data";

function AddReferDetails() {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [service, setService] = useState("Service Type");

    return (
        <Box>
            <Header title="Refer Services" />

            <FormStyled>


                <InputText
                    value={name}
                    onChange={({ target }) => {
                        setName(target.value);
                    }}
                    placeholder="Referance Name"
                />

                <InputText
                    value={mobileNumber}
                    onChange={({ target }) => {
                        setMobileNumber(target.value);
                    }}
                    placeholder="Referance Mobile Number"
                />



                <SelectInputStyled
                    value={service}
                    onValueChange={(text) => {
                        setService(text);
                    }}
                    menuList={SERVICE_LIST}
                />

                <StyledFormButton
                    onClick={() => {
                        navigate("/serviceCatagory");
                    }}
                    sx={{ mt: 2 }}
                    type="submit"
                >
                    Submit
                </StyledFormButton>
            </FormStyled>
        </Box>
    );
}

export default AddReferDetails;
