import styled from "@emotion/styled";
import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { COLORS } from "../../../styles/Theme";

export const StyledTableContainer = styled(TableContainer)({
  borderRadius: "5px",
  border: "1px solid #707070",
  boxShadow: "0px 10px 18px -10px rgba(0,0,0,0.13)",
});

export const StyledTable = styled(Table)({
  fontSize: 12,
  fontWeight: 500,
});

export const StyledTableHead = styled(TableHead)({
  backgroundColor: COLORS.greyShade6,
});

export const StyledTableRow = styled(TableRow)({
  "& > *:not(:last-child)": {
    borderRight: "1px solid #707070",
  },
});

export const StyledTableCell = styled(TableCell)({
  fontSize: 12,
  fontWeight: 500,
  borderBottom: "1px solid #707070",
  padding: "12px",
});

export const WalletBalanceBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "8px",
  backgroundColor: COLORS.white,
  boxShadow: "0px 0 38px 0px rgba(0,0,0,0.1)",
  margin: '16px',
  padding:'14px'
});
