// Footer.styles.js
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const MainFooter = styled(Box)`
  width: 100%;
  background-color: #1d3557;
  padding: 18px 0;
`;

export const FooterContainer = styled(Box)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const LogoContainer = styled(Box)`
  margin-bottom: 46px;
`;

export const FooterLogo = styled('img')`
  height: 52px;
  width: auto;
`;

export const SectionHeading = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
`;

export const FooterLink = styled(Link)`
  display: block;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 13px;
  margin-bottom: 10px;
  transition: color 0.2s;
  
  &:hover {
    color: #ffffff;
  }
`;

export const SocialIconsContainer = styled(Box)`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

export const CircularIconButton = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  svg {
    color: #1d3557;
    font-size: 20px;
  }
`;

export const AppStoreContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 20px;

  img {
    height: 40px;
    width: auto;
    object-fit: contain;

  }
`;

export const CopyrightContainer = styled(Box)`
  margin-top: 28px;
  padding-top: 18px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

export const CopyrightText = styled(Typography)`
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
`;