import axios from "axios";
import { BASE_URL } from "./Constant";

export const getApiInstance = (config) => {
  const headerData = config ? config : {};

  // console.log("head", JSON.stringify(headerData));

  const header = {
    ...headerData,
    "Content-Type": headerData?.["Content-Type"] || "application/json",
  };

  const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
    headers: header,
  });
  return instance;
};
