// import styled from "@emotion/styled";
// import { Box } from "@mui/material";
// import { COLORS } from "../../../styles/Theme";

// export const StylesIconBg = styled(Box)({
//   width: 50,
//   height: 50,
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   color: COLORS.white,
//   backgroundColor: COLORS.primaryColor,
//   borderRadius: 25,
// });

// export const StyledViewAllButton = styled.button({
//   width: "100%",
//   height: "100%",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center",
//   backgroundColor: COLORS.white,
//   border: "none",
//   cursor: "pointer",
// });

// export const BannerImg = styled.img({
//   width: "100%",
//   height: "auto",
// });


import { COLORS } from "../../../styles/Theme";
import styled from "@emotion/styled";
import { Box, Grid,Button,Typography, Card,
  CardMedia,
  CardContent,
   Dialog, 
   DialogTitle, 
   DialogContent,
    DialogActions,
     IconButton, 
     TextField} from "@mui/material";

  export const CustomGrid = styled(Grid)(({ theme }) => ({
  
  [theme.breakpoints.down('md')]: {
    padding: '80px',
  },
}));
export const StylesIconBg = styled(Box)({
  width: 50,
  height: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: COLORS.white,
  backgroundColor: COLORS.primaryColor,
  borderRadius: 25,
  '@media (max-width: 600px)': {
    width: 40,
    height: 40,
  },
});

export const StyledViewAllButton = styled.button({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: COLORS.white,
  border: "none",
  cursor: "pointer",
  '@media (max-width: 600px)': {
    padding: '8px 16px',
    fontSize: '14px',
  },
});

export const BannerImg = styled.img({
  width: "100%",
  height: "auto",
});

export const StyledDownloadButton = styled.button({
  backgroundColor: COLORS.primaryColor,
  color: COLORS.white,
  border: "none",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  fontWeight: "600",
  marginTop: "10px",

  "&:hover": {
    backgroundColor: COLORS.secondaryColor,
  },
  
  '@media (max-width: 600px)': {
    padding: '8px 16px',
    fontSize: '14px',
  },
});

export const ServicesGrid = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  marginTop: '20px',
  gap: '0',
  backgroundColor: '#fbfbfc',

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}));

export const ServiceItem = styled('div')(({ theme }) => ({
  border: '1px solid #ebebeb',
  boxSizing: 'border-box',
  padding: '20px',
  textAlign: 'center',

  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  },
}));

export const ServiceImage = styled('img')(({ theme }) => ({
  width: '40px',
  height: '40px',
  marginBottom: '5px',

  [theme.breakpoints.down('sm')]: {
    width: '30px',
    height: '30px',
  },
}));

export const ServiceText = styled('p')(({ theme }) => ({
  margin: '0',
  // padding: '0',
  lineHeight: '1.2',
  textAlign: 'center',
  color: '#000000',
  font: 'normal normal normal Poppins',

  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
  },
}));

export const ProfessionalCardContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '20px',

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
        height: '80px'
  },

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    height: '80px'
  },
}));

export const ProfessionalCard = styled('div')({
  position: 'relative',
  border: '1px solid #ddd',
  borderRadius: '5px',
  textAlign: 'center',
  overflow: 'hidden',
});

export const ImageOverlay = styled('div')({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '4px',
  height: '100%',
});

export const OverlayImage = styled('img')({
  maxWidth: '100%',
  height: '100%',
});

export const OverlayGradient = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '50%',
  background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1))',
});

export const ProfessionalCardTitle = styled('p')({
  position: 'absolute',
  bottom: '0px',
  left: '50%',
  transform: 'translateX(-50%)',
  fontSize: '14px',
  display: "flex",
  color: '#fff',
  zIndex: 1,

  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
});

export const SliderCard = styled(Box)({
  padding: '0px',
  margin: '0px',
});
export const LeftSectionContainer = styled(Box)({
  width: '100%', 
  '@media (max-width: 960px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%', 
  },
});

export const PhoneAndAppsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  // marginBottom: theme.spacing(3), 
  flexDirection: 'row', 

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column', 
    alignItems: 'flex-start', 
  },
}));

export const PhoneImageContainer = styled(Box)(({ theme }) => ({

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2), 
  },
}));

export const AppImageContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',  

  
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',  
    alignItems: 'flex-start', 
  },
}));
export const RightSectionContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: '#f5f5f5',
  padding: '40px 20px',
  borderRadius: '8px',
  position: 'sticky', 
  top: '133px',
  // right: '0px', 
  width: '635px', 
  height: '480px',
  // zIndex: 20,

  '@media (max-width: 960px)': {
    padding: '30px 15px',
    height: 'auto',
    position: 'static',
    width: '100%', 
    top: 'auto',
  },
}));

export const StyledButton = styled(Button)({
  background: 'transparent radial-gradient(closest-side at 50% 50%, #20608F 0%, #103048 100%) 0% 0% no-repeat padding-box;',
  color: 'white',
  padding: '10px 20px',
  borderRadius: '50px',
  textTransform: 'none',
  marginBottom: '20px',
  width:'300px',
  height:'45px',
  
  '@media (max-width: 600px)': {
    width: '200px',
    padding: '8px 16px',
  },
});

// Styled Dialog Container
export const StyledDialog = styled(Dialog)({
  '.MuiPaper-root': {
    borderRadius: '10px',
    padding: '20px',
  },
});

// Styled DialogTitle
export const StyledDialogTitle = styled(DialogTitle)({
  fontSize: '20px',
  fontWeight: '600',
  textAlign: 'center',
});

// Styled IconButton (Close button)
export const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
  color: '#000',
});

// Styled TextField (For inputs)
export const StyledTextField = styled(TextField)({
  marginBottom: '20px',
  '& .MuiInputBase-input': {
    padding: '12px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});
export const OTPDialogBox = styled(Box)({
  padding: '20px',
  textAlign: 'center',
});

export const OTPInput = styled('input')({
  width: '100%',
  padding: '10px',
  fontSize: '16px',
  margin: '10px 0',
  borderRadius: '4px',
  border: '1px solid #ccc',
});

export const OTPText = styled('p')({
  fontSize: '14px',
  margin: '10px 0',
});

export const SubmitButton = styled(Button)({
  background: '#21608F',
  color: 'white',
  textTransform: 'none',
  padding: '10px 20px',
  fontWeight: 'bold',
  width: '100%',
  marginLeft:'12px',
  marginRight:'12px',
  height: '40px',

  // Disable hover effect
  '&:hover': {
    background: '#21608F',
  },
});
// Typography Styles for Section Titles
export const SectionTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize:'20px',
  marginBottom: '20px',
  // font:'bold',
  font:'Poppins',
});

// Image Container for App Links
export const AppImageContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
});

export const AppImage = styled('img')({
  width: '150px',
  height: 'auto',
});

export const StyledCard = styled(Card)`
position: relative;
overflow: hidden;
border-radius: 10px;
box-shadow: none;
height: 100%; 
`;

export const StyledCardMedia = styled(CardMedia)`
   height: 150px;
  filter: brightness(0.8); 
`;

export const FullHeightCardMedia = styled(CardMedia)`
height: 100%; 
filter: brightness(0.8); 
`;
export const StyledCardContent = styled(CardContent)`
  position: absolute;
  height: 100%;
  bottom: 0;
  left: -8px;
  color: white;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  padding: 10px;
  width: 100%;
  z-index: 1;
  text-align: center;
  display: flex;               
  justify-content: center;  
  align-items: center;        
`;

// Styled CardMedia for the image with custom height and filter
export const BorewellCardMedia = styled(CardMedia)`
  height: 180px;
  filter: brightness(0.8); /* Add slight darkening to the image */
  object-fit: cover; /* Ensure the image covers the entire card */
  width: 100%;

  @media (max-width: 768px) { /* Target devices with a max width of 768px */
    height: 140px; /* Decrease height for mobile view */
  }

  @media (max-width: 480px) { /* Target smaller mobile devices */
    height: 120px;
  }
`;


// Box container for individual cards
export const BorewellCard = styled(Box)`
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  // transition: transform 0.3s ease;
`;

// Styled Typography for the service name overlay
export const BorewellServiceName = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
  color: white;
  text-align: center;
  padding: 10px 0;
   @media (max-width: 768px) { 
 font-weight:100;
 font-size:10px
  }

  @media (max-width: 480px) { 
   font-weight: 100;
   font-size:10px
  }
`;
// Box container for individual cards
export const ServiceCard = styled(Box)`
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  // transition: transform 0.3s ease, box-shadow 0.3s ease;
`;

// Styled Typography for the service name overlay
export const ServiceNameOverlay = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  z-index: 1;
`;

// You can also have a hover effect if needed
// export const ImageOverlay = styled('div')`
//   position: relative;
//   img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     transition: transform 0.3s ease;
//   }
// `;
