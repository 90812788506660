import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Card,
  CardContent,
} from '@mui/material';
import { getMostReadPosts } from '../../services/api_calls/auth/AuthApi';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-root': {
    padding: theme.spacing(1),
    fontSize: '0.875rem',
  },
  '& .MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  '& .MuiTableRow-root:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: theme.palette.action.selected,
    cursor: 'pointer',
  },
}));

const TruncatedText = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '140px',
});

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)} !important`,
  },
}));
const MostReadPosts = ({ onPostClick }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMostReadPosts = async () => {
      try {
        setLoading(true);
        const response = await getMostReadPosts();
        if (response.success) {
          setPosts(response.posts);
        }
      } catch (error) {
        console.error('Error fetching most read posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMostReadPosts();
  }, []);

  if (loading) {
    return (
      <StyledCard elevation={3}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Most Read Posts
          </Typography>
          <Box sx={{ p: 2, textAlign: 'center' }}>Loading...</Box>
        </CardContent>
      </StyledCard>
    );
  }

  return (
    <StyledCard elevation={3}>
      <CardContent>
        <Typography variant="h6" sx={{ 
          borderBottom: '2px solid',
          borderColor: 'primary.main',
          pb: 1,
          mb: 2,
          fontWeight: 600
        }}>
          Most Read Posts
        </Typography>
        <StyledTableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="center">Category</TableCell>
                <TableCell align="right">Reads</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {posts.slice(0, 5).map((post) => (
                <TableRow
                  key={post._id}
                  onClick={() => onPostClick(post)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <TruncatedText variant="body2">{post.title}</TruncatedText>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" color="primary.main">
                      {post.category?.name || 'Uncategorized'}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" fontWeight="500">
                      {post.readCount}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </CardContent>
    </StyledCard>
  );
};

export default MostReadPosts;