import { Box, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";
import {
  BusinessLogo,
  CheckIconStyled,
  StyledBusinessInfoCard,
  StyledEditButton,
} from "./BusinessProfile.style";

function BusinessInfoCard({ logo, title, number, email, onEdit }) {
  return (
    <StyledBusinessInfoCard>
      <BusinessLogo alt="Business Logo" src={logo} />

      <Box>
        <Box display="flex" alignItems="center" gap={0.7} mb={0.5}>
          <Typography
            variant="body1"
            fontSize={9}
            fontWeight={500}
            color={COLORS.redShade1}
          >
            <CheckIconStyled iconcolor={COLORS.redShade1} /> Rejected
          </Typography>

          <Typography
            variant="body1"
            fontSize={9}
            fontWeight={500}
            color={COLORS.primaryColor}
          >
            <CheckIconStyled iconcolor={COLORS.primaryColor} /> Approval Pending
          </Typography>

          <Typography
            variant="body1"
            fontSize={9}
            fontWeight={500}
            color={COLORS.green}
          >
            <CheckIconStyled iconcolor={COLORS.green} /> Verified
          </Typography>
        </Box>

        <Typography variant="h3" fontSize={16} fontWeight={500}>
          {title}
        </Typography>

        <Typography variant="body2" fontSize={13} color={COLORS.grey} mt={0.3}>
          {number}
        </Typography>

        <Typography variant="body2" fontSize={13} color={COLORS.grey}>
          {email}
        </Typography>

        <StyledEditButton variant="contained" disableElevation onClick={onEdit}>
          Edit
        </StyledEditButton>
      </Box>
    </StyledBusinessInfoCard>
  );
}

export default BusinessInfoCard;
