import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCityDataApi } from "../../services/api_calls/auth/AuthApi";

const initialState = {
  cityContent: null,
//   homeCategary: null,

  // loader
  cityContentLoader: false,
//   homeCategaryLooader: false,
};

export const getCityData = createAsyncThunk(
  "getCityData",
  async (thunkAPI) => {
    const responseData = await getCityDataApi();
    return responseData;
  }
);

// export const getHomeCategoryData = createAsyncThunk(
//   "getHomeCategoryData",
//   async (thunkAPI) => {
//     const responseData = await getTestDataApi();
//     return responseData;
//   }
// );

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    // just for testing
    [getCityData.pending]: (state) => {
      state.cityContentLoader = true;
    },
    [getCityData.fulfilled]: (state, { payload }) => {
      state.cityContentLoader = false;
      state.cityContent = payload;
    },
    [getCityData.rejected]: (state) => {
      state.cityContentLoader = false;
    },

    // // category data
    // [getHomeCategoryData.pending]: (state) => {
    //   state.homeCategaryLooader = true;
    // },
    // [getHomeCategoryData.fulfilled]: (state, { payload }) => {
    //   state.homeCategaryLooader = false;
    //   state.homeCategary = payload;
    // },
    // [getHomeCategoryData.rejected]: (state) => {
    //   state.homeCategaryLooader = false;
    // },
  },
});

export const authReducer = authSlice.reducer;
