import { getApiInstance } from "../../ApiInstance";
import { GET_CITY, GET_TEST_DATA } from "../../Constant";

export const getTestDataApi = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_CITY);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const postTestApi = async () => {
  try {
    const instance = getApiInstance({
      header: {

      }
    });

    const response = await instance.post(GET_TEST_DATA, {

    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
