import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import OTPInput from "../../../components/UI/Input/OTPInput";
import PhoneNumberInput from "../../../components/UI/Input/PhoneNumberInput";
import {
  userSentOtp,
  userVerifyOtp,
} from "../../../services/api_calls/auth/AuthApi";
import { COLORS } from "../../../styles/Theme";

function OTPVerify() {
  const navigate = useNavigate();

  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");

  const [seconds, setSeconds] = useState(0);
  const [startTimer, setStartTimer] = useState(false);

  const [numberDisabled, setNumberDisabled] = useState(false);
  const [otpDisabled, setOtpDisabled] = useState(true);

  // const handleSendOTP = () => {
  //   setOtpDisabled(false);
  //   setNumberDisabled(true)
  // };

  const handleSendOTP = async (event) => {
    event.preventDefault();
    if (mobileNumber) {
      const response = await userSentOtp({ mobileNumber });
      console.log(response);

      setOtpDisabled(false);
      setNumberDisabled(true);
      setSeconds(30);
      setStartTimer(true);
    }
  };

  const handleReSendOTP = async (event) => {
    const response = await userSentOtp({ mobileNumber });

    setSeconds(30);
    setStartTimer(true);

    // if (otp && mobileNumber) {
    //   const response = await userVerifyOtp({ otp, mobileNumber });
    //   console.log(response.access_token);
    // }
  };

  const handleEditNumber = () => {
    setNumberDisabled(false);
    setOtpDisabled(true);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    if (otp && mobileNumber) {
      const response = await userVerifyOtp({ otp, mobileNumber });
      console.log(response.data.is_registered);
      if (response.access_token) {
        if (response.data.is_registered) {
          navigate("/home");
        } else {
          navigate("/registerDetails");
        }
      } else {
        navigate("/");
      }
    }
  };

  const handleSkip = () => {
    navigate("/chooseLocation");
  };

  useEffect(() => {
    let intervalId;

    if (startTimer) {
      intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          clearInterval(intervalId);
          setStartTimer(false);
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startTimer, seconds]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <StyledImg component="img" src="/./assets/images/auth-img.png" />

        <Typography
          variant="h2"
          fontSize={18}
          fontWeight={600}
          color={COLORS.blackShade1}
          textAlign="center"
        >
          WELCOME TO LOCAL PRO
        </Typography>

        <Typography
          variant="body1"
          fontSize={13}
          fontWeight={500}
          color={COLORS.grey}
          textAlign="center"
        >
          Enter OTP To Continue
        </Typography>
      </Box>

      <FormStyled>
        <PhoneNumberInput
          placeholder="Enter Mobile Number"
          value={mobileNumber}
          onChange={({ target }) => {
            setMobileNumber(target.value);
          }}
          handleClick={handleSendOTP}
          handleEditClick={handleEditNumber}
          disabled={numberDisabled}
          btnDisabled={mobileNumber.length !== 10}
        />

        <OTPInput
          placeholder="* * * * * *"
          value={otp}
          onChange={({ target }) => {
            setOtp(target.value);
          }}
          maxLength={6}
          handleClick={handleReSendOTP}
          disabled={otpDisabled}
          countdownTimer={seconds}
        />

        <StyledFormButton
          //   disabled
          onClick={handleLogin}
          sx={{ mt: 2 }}
          type="submit"
        >
          LOGIN
        </StyledFormButton>

        <StyledFormButton
          textColor={COLORS.black}
          bgColor={COLORS.white}
          hoverBGColor={COLORS.primaryLight}
          onClick={handleSkip}
          sx={{ mt: 1 }}
        >
          SKIP
        </StyledFormButton>
      </FormStyled>
    </Box>
  );
}

export default OTPVerify;

const StyledImg = styled.img({
  width: 300,
  height: 300,
  objectFit: "contain",
  margin: "10px 0",
});

const FormStyled = styled.form({
  padding: "25px",
});
