import {
  Box,
  Button,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { COLORS } from "../../styles/Theme";
import {
  // ActionBtnBox,
  // BackButton,
  // DistanceCard,
  // DistanceContent,
  // HeaderContainer,
  // MapBox,
  ServiceItem,
  SideHeading,
  StyledContainer,
  StyledSection,
  // WishlistButton,
} from "./ProfessionalDetails.style";
import ProfessionalInfoTile from "./ProfessionalInfoTile";
import EditIcon from "@mui/icons-material/Edit";
import ReviewCard from "../../components/Cards/ReviewCard";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
// import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { useNavigate, useLocation, useParams } from "react-router-dom";
// import LogoSmall from "../../components/Logo/LogoSmall";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
import Header from "../../components/UI/DashboardHeader/Header";
import RightSection from "../Dashboard/Home/RightSection";
// import IMAGES from "../../../utils/Data";
import ReviewModal from "./ReviewModal";
import { styled } from "@mui/system";
import EnquiryModal from "./EnquiryModal";
import CallNowModal from "./CallNowModal";
import { useMediaQuery } from "@mui/material";
import Footer from "../../components/UI/Footer/Footer";
import BottomTabs from "../../components/UI/BottomTabs";
import {
  getVendorDetailsApi,
  getCityDataApi,
  getCategoryDataApi,
  getVendorListApi,
  getWishlistApi,
  addToWishlistApi,
  createCallApi,
} from "../../services/api_calls/auth/AuthApi";
import Loader from "../../components/Loader/Loader";
import { createCitySlug, createUrlSlug } from "../../utils/urlUtils";
// const generateServiceList = (city, categories, selectedService) => {
//   if (!city || !city.subCity || !categories || !selectedService) {
//     return [];
//   }

//   const serviceList = [];
//   // Add main city service
//   serviceList.push(`${selectedService} in ${city.name}`);

//   // Add services for each subcity
//   city.subCity.forEach((subcity) => {
//     if (subcity.name) {
//       serviceList.push(`${selectedService} in ${subcity.name}`);
//     }
//   });

//   return serviceList;
// };
function ProfessionalDetails() {
  // const images = [
  //   "./assets/images/washing_machine.png",
  //   "./assets/images/plumber-img.jpg",
  //   "./assets/images/home-banner.png",
  // ];
  const navigate = useNavigate();
  const { serviceSlug, businessSlug, citySlug } = useParams();
  const [wishlist, setWishlist] = useState([]);
  const location = useLocation();
  const city = citySlug || location.state?.citySlug;
  const handleAddReview = () => {};
  const [vendorDetails, setVendorDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const isDesktop = useMediaQuery("(min-width:960px)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [cities, setCities] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [cityError, setCityError] = useState(null);
  const [isEnquiryPopupOpen, setIsEnquiryPopupOpen] = useState(false);
  // const [isCallNowPopupOpen, setIsCallNowPopupOpen] = useState(false);
  // const [businessName, setBusinessName] = useState("SLN Enterprises");
  // const [phoneNumber, setPhoneNumber] = useState("+91 99090 98980");
  const { serviceName, businessName } = location.state || {};
  const [serviceslist, setServicesList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentCity, setCurrentCity] = useState(() => {
    return localStorage.getItem("selectedCity") || "Bangalore";
  });
  const getBusinessPhotos = () => {
    if (!vendorDetails) return [];
    return vendorDetails.bphotos || [];
  };
  const [languages, setLanguages] = useState([]);
  const slugToServiceName = (slug) => {
    return slug
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    const fetchVendorDetails = async () => {
      setLoading(true);
      setError(null);

      try {
        let vendorId = location.state?.vendor_id;

        if (!vendorId) {
          console.log("No vendor_id in state, fetching service categories...");
          // First get all categories to find the service ID
          const categoriesResponse = await getCategoryDataApi();

          if (!categoriesResponse?.categories) {
            throw new Error("Failed to fetch categories");
          }

          // Find the service in categories
          const serviceName = slugToServiceName(serviceSlug);
          console.log("Looking for service:", serviceName);

          let serviceId = null;
          for (const category of categoriesResponse.categories) {
            const subCategory = category.subCategory?.find(
              (sub) => sub.name.toLowerCase() === serviceName.toLowerCase()
            );
            if (subCategory) {
              serviceId = subCategory.id;
              break;
            }
          }

          if (!serviceId) {
            throw new Error("Service not found");
          }

          console.log("Found service ID:", serviceId);

          // Now fetch vendors for this service
          const vendorResponse = await getVendorListApi({
            service_id: serviceId,
          });

          if (!vendorResponse?.vendors) {
            throw new Error("No vendors found for this service");
          }

          console.log("Searching for business slug:", businessSlug);
          const vendor = vendorResponse.vendors.find((v) => {
            if (!v.bname) return false;
            try {
              return createUrlSlug(v.bname) === businessSlug;
            } catch (err) {
              console.error("Error creating URL slug for business name:", err);
              return false;
            }
          });

          if (!vendor) {
            throw new Error("Vendor not found for the given business name");
          }

          vendorId = vendor.id;
          console.log("Found vendor ID:", vendorId);
        }

        // Now fetch the full vendor details
        const response = await getVendorDetailsApi({
          vendor_id: vendorId,
        });

        if (!response?.status || !response?.data) {
          throw new Error("Failed to fetch vendor details");
        }

        setVendorDetails(response.data);

        // Update document title
        const businessName = response.data.bname;
        const serviceName = slugToServiceName(serviceSlug);
        document.title = `${businessName} - ${serviceName}`;
      } catch (err) {
        console.error("Error in fetchVendorDetails:", err);
        setError(
          err.message || "An error occurred while fetching vendor details"
        );
      } finally {
        setLoading(false);
      }
    };

    if (serviceSlug && businessSlug) {
      fetchVendorDetails();
    }
  }, [serviceSlug, businessSlug, location.state]);

  useEffect(() => {
    const fetchCities = async () => {
      setCityLoading(true);
      setCityError(null);
      try {
        const response = await getCityDataApi();

        if (!response) {
          throw new Error("No response received from the server");
        }

        const cityData = response.data || response.cities;

        if (!cityData || !Array.isArray(cityData)) {
          throw new Error("Invalid city data format received");
        }

        const sortedCities = cityData.sort((a, b) => {
          const posA = a.position || 0;
          const posB = b.position || 0;
          return posA - posB;
        });

        setCities(sortedCities);
      } catch (err) {
        setCityError(
          err.message || "Failed to fetch cities. Please try again later."
        );
      } finally {
        setCityLoading(false);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const handleCityChange = (event) => {
      const { city } = event.detail;
      setCurrentCity(city);
    };

    window.addEventListener("cityChanged", handleCityChange);
    return () => window.removeEventListener("cityChanged", handleCityChange);
  }, []);
  const handleCityClick = (city) => {
    const citySlug = createCitySlug(city.name);
    localStorage.setItem("selectedCity", city.name);
    navigate(`/${citySlug}`);
  };
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategoryDataApi();
        if (response?.status && response?.categories) {
          setCategories(response.categories);
        }
      } catch (err) {
        console.error("Error fetching categories:", err);
      }
    };

    fetchCategories();
  }, []);

  // useEffect(() => {
  //   if (cities.length > 0) {
  //     const selectedCity = cities.find(
  //       (city) => city.name?.toLowerCase() === currentCity?.toLowerCase()
  //     );

  //     if (selectedCity && vendorDetails?.categories?.length > 0) {
  //       const serviceName = vendorDetails.categories[0].name || "Services";
  //       const servicesList = generateServiceList(
  //         selectedCity,
  //         categories,
  //         serviceName
  //       );
  //       setServicesList(servicesList);
  //     }
  //   }
  // }, [cities, categories, currentCity, vendorDetails]);

  useEffect(() => {
    if (serviceName && businessName) {
      document.title = `${businessName} - ${serviceName}`; // Combine both names
    } else if (businessName) {
      document.title = businessName;
    } else if (serviceName) {
      document.title = serviceName;
    }
  }, [serviceName, businessName]);
  const fetchWishlist = async () => {
    try {
      const response = await getWishlistApi();
      if (response.status) {
        setWishlist(response.data);
      }
    } catch (error) {
      console.error("Error fetching wishlist:", error);
    }
  };

  // Add useEffect to fetch wishlist on component mount
  useEffect(() => {
    fetchWishlist();
  }, []);
  const handleRefreshVendorDetails = async () => {
    setLoading(true);
    try {
      const response = await getVendorDetailsApi({
        vendor_id: location.state.vendor_id,
      });
      if (response && response.status) {
        setVendorDetails(response.data);
        setLanguages(
          response.data.languages || ["English", "Hindi", "Kannada"]
        );
      }
    } catch (err) {
      console.error("Error refreshing vendor details:", err);
    } finally {
      setLoading(false);
    }
  };
  const handleOpenEnquiryPopup = () => setIsEnquiryPopupOpen(true);
  const handleCloseEnquiryPopup = () => setIsEnquiryPopupOpen(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCall = async () => {
    try {
      const response = await createCallApi({
        vendor_id: location.state?.vendor_id,
        service_id: location.state?.selectedServiceId
      });
      
      if (response?.success) {
        window.location.href = `tel:${vendorDetails?.bmobile}`;
      }
    } catch (error) {
      console.error('Error making call:', error);
    }
  };

  // const handleCloseCallNow = () => {
  //   setIsCallNowPopupOpen(false);
  // };

  const handlePrevImage = () => {
    const allImages = getBusinessPhotos();
    const newIndex =
      currentIndex === 0 ? allImages.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const handleNextImage = () => {
    const allImages = getBusinessPhotos();
    const newIndex =
      currentIndex === allImages.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }
  const theme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            maxWidth: "1200px",
          },
        },
      },
    },
  });
  // Find and replace the existing formatTimings function with this updated version
  const formatTimings = (timings) => {
    try {
      if (!timings?.default || !timings.default["1"]) {
        return "Not available";
      }

      const dayTiming = timings.default["1"];

      // Validate that start and end times exist and are in correct format
      if (
        !dayTiming.start ||
        !dayTiming.end ||
        !dayTiming.start.includes(":") ||
        !dayTiming.end.includes(":")
      ) {
        return "Not available";
      }

      const formatTime = (timeStr) => {
        try {
          const [hoursStr, minutesStr] = timeStr.split(":");

          const hours = parseInt(hoursStr, 10);
          const minutes = parseInt(minutesStr, 10);

          if (
            isNaN(hours) ||
            isNaN(minutes) ||
            hours < 0 ||
            hours > 23 ||
            minutes < 0 ||
            minutes > 59
          ) {
            throw new Error("Invalid time format");
          }
          const period = hours >= 12 ? "PM" : "AM";
          let hours12 = hours % 12;
          hours12 = hours12 === 0 ? 12 : hours12;

          const paddedMinutes = minutes.toString().padStart(2, "0");

          return `${hours12}:${paddedMinutes} ${period}`;
        } catch (error) {
          return "N/A";
        }
      };

      const startTime = formatTime(dayTiming.start);
      const endTime = formatTime(dayTiming.end);
      if (startTime === "N/A" || endTime === "N/A") {
        return "Not available";
      }

      return `${startTime} to ${endTime}`;
    } catch (error) {
      return "Not available";
    }
  };

  const formatAddress = (addressArray) => {
    if (!addressArray || addressArray.length === 0) return "";
    const address = addressArray[0];

    // Construct full address with all available fields
    const addressParts = [
      address.buildingName,
      address.baddress,
      address.bcity,
      address.bstate,
      address.pincode,
    ].filter(Boolean); // Remove any undefined/null/empty values

    return addressParts.join(", ");
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Grid
        container
        spacing={3}
        sx={{
          marginTop: { xs: "0", md: "30px" },
          padding: { xs: "0", md: "80px" },
        }}
      >
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <StyledContainer>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {/* Only show navigation buttons if there are multiple business photos */}
              {getBusinessPhotos().length > 1 && (
                <IconButton
                  onClick={handlePrevImage}
                  sx={{
                    position: "absolute",
                    left: 10,
                    zIndex: 1,
                    backgroundColor: "#f0f0f0",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10%",
                    color: "black",
                    "&:hover": {
                      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  <ArrowBackIosNewRoundedIcon />
                </IconButton>
              )}

              {getBusinessPhotos().length > 0 ? (
                <Box
                  sx={{
                    width: "100%",
                    height: {
                      xs: "250px", // Mobile height
                      sm: "350px", // Tablet height
                      md: "450px", // Desktop height
                    },
                    overflow: "hidden",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <img
                    src={getBusinessPhotos()[currentIndex]}
                    alt="Business"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: {
                      xs: "250px",
                      sm: "350px",
                      md: "450px",
                    },
                    backgroundColor: "#f5f5f5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography color="textSecondary">
                    No business photos available
                  </Typography>
                </Box>
              )}

              {getBusinessPhotos().length > 1 && (
                <IconButton
                  onClick={handleNextImage}
                  sx={{
                    position: "absolute",
                    right: 10,
                    zIndex: 1,
                    backgroundColor: "#f0f0f0",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10%",
                    color: "black",
                    "&:hover": {
                      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  <ArrowForwardIosRoundedIcon />
                </IconButton>
              )}
            </Box>
            <Box m={1}>
              <ProfessionalInfoTile
                imgUrl={vendorDetails?.profileImages?.[0]}
                name={vendorDetails?.bname}
                title={vendorDetails?.bname}
                rating={vendorDetails?.averageRating}
                reviewCount={vendorDetails?.totalReviews}
                address={vendorDetails?.address?.[0].baddress}
                distance={vendorDetails?.distance || ""}
                isVerified={true}
                handleEnquiryClick={handleOpenEnquiryPopup}
                handleCall={handleCall}
                isSubscribed={vendorDetails?.subscription}
                businessName={vendorDetails?.bname}
                phoneNumber={vendorDetails?.bmobile}
                vendor_id={location.state?.vendor_id}
                service_id={location.state?.selectedServiceId}
                addToWishlistApi={addToWishlistApi}
                createCallApi={createCallApi}
                isWishlisted={wishlist.some(
                  (item) => item.id === location.state?.vendor_id
                )}
                onWishlistUpdate={fetchWishlist}
              />
              {/* Address */}
              <StyledSection>
                <Box display="flex" alignItems="center" gap="8px">
                  <Box flex={1}>
                    <Typography
                      variant="body2"
                      fontSize={16}
                      fontWeight={400}
                      lineHeight="20px"
                      color="#5B5B5B"
                      fontFamily={"Poppins, Regular"}
                    >
                      {formatAddress(vendorDetails?.address)}
                    </Typography>
                  </Box>
                </Box>
              </StyledSection>
              {/* About US */}
              <StyledSection>
                <SideHeading variant="h6">About Us</SideHeading>

                <Typography
                  variant="body2"
                  fontSize={12}
                  fontWeight={400}
                  lineHeight="20px"
                  color={COLORS.greyShade9}
                >
                  {vendorDetails?.babout}
                </Typography>
              </StyledSection>
              {/*Timings*/}
              <StyledSection>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "#333",
                          marginBottom: "8px",
                        }}
                      >
                        Working Hours
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          color: "#666",
                          fontWeight: 400,
                        }}
                      >
                        {formatTimings(vendorDetails?.timings)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item marginLeft={"120px"}>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "#333",
                          marginBottom: "8px",
                        }}
                      >
                        Holiday
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          color: "#666",
                          fontWeight: 400,
                        }}
                      >
                        {vendorDetails?.holiday?.join(", ")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </StyledSection>
              {/* Services */}
              <StyledSection>
                <SideHeading variant="h6">Services</SideHeading>
                <Box m={1}>
                  <Grid container spacing={2}>
                    {vendorDetails?.categories?.map((service, index) => (
                      <Grid item xs={6} key={service.id}>
                        <ServiceItem variant="body2">
                          {service.name}
                        </ServiceItem>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </StyledSection>
              <Box mt={2}>
                <Typography variant="h6" mb={1}>
                  Languages Known
                </Typography>
                <LanguageBox>
                  {languages.map((language, index) => (
                    <LanguageText key={index} variant="body2">
                      {language}
                      {index < languages.length - 1 && ", "}
                    </LanguageText>
                  ))}
                </LanguageBox>
              </Box>
              {/* Reviews Section */}
              <Box m="16px 0">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  m={1}
                >
                  <SideHeading variant="h6">Reviews</SideHeading>
                  <Button
                    variant="contained"
                    size="small"
                    disableElevation
                    sx={{
                      fontWeight: 500,
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                    startIcon={<EditIcon />}
                    onClick={handleOpen}
                  >
                    Write a Review
                  </Button>
                </Box>

                <ReviewModal open={open} handleClose={handleClose} />
                {vendorDetails?.reviews?.map((review, index) => (
                  <ReviewCard
                    key={index}
                    imgUrl={review.profilePicture}
                    name={review.name}
                    date={new Date(review.createdAt).toLocaleDateString()}
                    rating={review.rating}
                    message={review.review}
                  />
                ))}
              </Box>
            </Box>
          </StyledContainer>
        </Grid>
        {isDesktop && (
          <RightSection
            showSuccessPopup={showSuccessPopup}
            setShowSuccessPopup={setShowSuccessPopup}
            showAllPopups={false}
          />
        )}
        <EnquiryModal
          open={isEnquiryPopupOpen}
          handleClose={handleCloseEnquiryPopup}
          vendorId={location.state?.vendor_id}
          serviceId={location.state?.selectedServiceId}
          businessName={vendorDetails?.bname}
        />
        {/* <CallNowModal
          open={isCallNowPopupOpen}
          handleClose={handleCloseCallNow}
          businessName={vendorDetails?.bname}
          phoneNumber={vendorDetails?.bmobile}
        /> */}
        <ReviewModal
          open={open}
          handleClose={handleClose}
          vendorId={location.state?.vendor_id}
          serviceId={location.state?.selectedServiceId}
          onReviewSubmitted={handleRefreshVendorDetails}
        />
      </Grid>
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          marginTop: "20px",
          padding: { xs: "15px", md: "20px 100px" },
          backgroundColor: "#f7f7f7",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ marginTop: "40px", marginBottom: "20px", fontWeight: "bold" }}
        >
          Popular Cities
        </Typography>

        {cityLoading ? (
          <CircularProgress />
        ) : cityError ? (
          <Typography color="error">{cityError}</Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              color: "#666666",
            }}
          >
            {cities.map((city, index) => (
              <React.Fragment key={city.id || index}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "13px",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#21608F",
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => handleCityClick(city)}
                >
                  {city.name}
                </Typography>
                {index < cities.length - 1 && (
                  <Typography
                    component="span"
                    sx={{ fontSize: "13px", color: "#666666", marginX: "4px" }}
                  >
                    |
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </Box>
        )}

        {/* <Typography
          variant="h6"
          component="div"
          sx={{ marginBottom: "20px", fontWeight: "bold", marginTop: "40px" }}
        >
          Our Services in {currentCity}
        </Typography> */}

        {/* <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            color: "#666666",
          }}
        >
          {serviceslist.map((service, index) => (
            <React.Fragment key={index}>
              <Typography
                component="span"
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#21608F",
                    textDecoration: "underline",
                  },
                }}
              >
                {service}
              </Typography>
              {index < serviceslist.length - 1 && (
                <Typography
                  component="span"
                  sx={{ fontSize: "13px", color: "#666666", marginX: "4px" }}
                >
                  |
                </Typography>
              )}
            </React.Fragment>
          ))}
        </Box> */}
      </Box>
      <Footer></Footer>
      {isMobile && <BottomTabs activeTab="home" />}
    </ThemeProvider>
  );
}

export default ProfessionalDetails;

const LanguageBox = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",
  marginTop: "4px",
});

const LanguageText = styled(Typography)({
  color: "#6B7280", // Light gray color for languages
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline", // Optional: add underline on hover
  },
});
