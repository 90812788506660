import React from 'react';
import { useMediaQuery } from '@mui/material';
import DashboardHeader from './index';  
import HeaderD from './HeaderD'

const HeaderComponent = () => {
  const isMobile = useMediaQuery('(max-width:600px)'); 

  return (
    <>
      {isMobile ? <DashboardHeader /> : <HeaderD />}
    </>
  );
};

export default HeaderComponent;
