import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BusinessLocationCard from "../../../components/Cards/BusinessLocationCard";
import Header from "../../../components/UI/Header";
import BusinessInfoCard from "./BusinessInfoCard";
import BusinessInfoQuickLinks from "./BusinessInfoQuickLinks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { COLORS } from "../../../styles/Theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import { StyledInfoCard } from "./BusinessProfile.style";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../../redux/profile/profileSlice";
function BusinessProfile() {
   const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleEnquiry = () => {
    navigate("/businessProfile/contacts");
  };

  const handleReview = () => {
    navigate("/businessProfile/reviews");
  };

  const handleWalletBalance = () => {
    navigate("/businessProfile/myServicePlan");
  };

  const {
    profileContent,
    profileContentLoader,
  } = useSelector((state) => state.profile);



  useEffect(() => {
    dispatch(getProfileData());
    return () => { };
  }, []);


  return (
    <Box>
      <Header title="Business Profile" />
      {/* {JSON.stringify(profileContent.cities[0])} */}
      <Box m="13px">
        <BusinessInfoCard
          logo="https://images.pexels.com/photos/2119903/pexels-photo-2119903.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          title={profileContent ?  profileContent.user[0].name : ''}
          number= {profileContent ? profileContent.user[0].mobile : ''}
          email={profileContent ?  profileContent.user[0].email : ''}
          onEdit={() => {
            navigate("/editProfile");
          }}
        />

        <BusinessInfoQuickLinks
          onEnquiredClick={handleEnquiry}
          onReviewsClick={handleReview}
          onWalletClick={handleWalletBalance}
        />
      </Box>

      <Box m={2}>
        <Typography variant="h4" fontSize={16} fontWeight={500} m="30px 0 16px">
        {profileContent ?  profileContent.cities.length : 0}  Locations Listed
        </Typography>
        {profileContent ? (<>
          {profileContent.cities.map((item, index) => {
          return(
            <BusinessLocationCard
            imgUrl="https://images.pexels.com/photos/2119903/pexels-photo-2119903.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            title={item.baddress}
            subtitle="Washing Machine Service"
            location="Magadi Road"
            timing="08:00 AM - 09:00 PM"
            status="Online"
          />
          )
      
        })}
        </>) : (<></>) }
       

        {/* <BusinessLocationCard
          imgUrl="https://images.pexels.com/photos/2119903/pexels-photo-2119903.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          title="S N Enterprises"
          subtitle="Washing Machine Service"
          location="Magadi Road"
          timing="08:00 AM - 09:00 PM"
          status="Offline"
        /> */}

        <Box display="flex" justifyContent="flex-end">
          <Button
            endIcon={<ExpandMoreIcon />}
            onClick={() => {}}
            sx={{ textTransform: "none", textDecoration: "underline" }}
          >
            Show All
          </Button>
        </Box>

        <StyledInfoCard>
          <Typography
            variant="boby2"
            fontSize={12}
            fontWeight={500}
            color={COLORS.black}
          >
            List Your Business More Locations on Local Pro app
          </Typography>

          <InfoText text="In which locations do you provide Business." />
          <InfoText text="To increase your business." />
          <InfoText text="Customer Should Be Search Nearby Locations." />

          <StyledFormButton
            onClick={() => {}}
            sx={{ fontSize: 12, fontWeight: 400, mt: 2, borderRadius: "8px" }}
          >
            Create A More Locations For Your Business
          </StyledFormButton>
        </StyledInfoCard>
      </Box>

      <StyledFormButton
        onClick={() => {}}
        sx={{
          borderRadius: "0px",
          padding: "16px !important",
        }}
      >
        To Access All Enquiries Upgrade Now
      </StyledFormButton>
    </Box>
  );
}

export default BusinessProfile;

const InfoText = ({ text }) => {
  return (
    <Typography
      variant="boby1"
      fontSize={11}
      fontWeight={400}
      color={COLORS.blackShade1}
      display="block"
      m="4px 0 0"
    >
      <CheckCircleIcon
        sx={{
          fontSize: 14,
          color: COLORS.green,
          m: "0 3px -3px 0",
        }}
      />
      {text}
    </Typography>
  );
};
