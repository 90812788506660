import styled from "@emotion/styled";
import { Box, keyframes, Typography } from "@mui/material";
import React from "react";
import { textOneLine } from "../../styles/Global";
import { COLORS } from "../../styles/Theme";

function ServiceTileMinimal({ imgUrl, name, isSelected, onClick }) {
  return (
    <ServiceCard active={isSelected} onClick={onClick}>
      <ServiceIcon src={imgUrl} alt="local pro service" />

      <Typography
        variant="body2"
        fontSize={11}
        fontWeight={500}
        textAlign="center"
        sx={{ ...textOneLine }}
      >
        {name}
      </Typography>

      {isSelected && <PulseDot />}
    </ServiceCard>
  );
}

export default ServiceTileMinimal;

const ServiceCard = styled(Box)(({ active }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px 0",
  borderBottom: active
    ? "2px solid " + COLORS.primaryColor
    : "1px solid " + COLORS.greyShade3,
  borderRight: active
    ? "0.1px solid " + COLORS.primaryColor
    : "0.5px solid " + COLORS.greyShade3,
  borderLeft: active
    ? "0.1px solid " + COLORS.primaryColor
    : "0.5px solid " + COLORS.greyShade3,
  position: 'relative',
  
}));

const ServiceIcon = styled.img({
  width: 28,
  height: 28,
  objectFit: "contain",
});

const pulse = keyframes`
  0% {
    transform: scale(0.3);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const PulseDot = styled(Box)({
  width: 6,
  height: 6,
  borderRadius: "10px",
  backgroundColor: COLORS.primaryColor,
  position: "absolute",
  top: 6,
  right: 6,

  "&::before": {
    content: '" "',
    width: 8,
    height: 8,
    borderRadius: "50%",
    border: "0.5px solid " + COLORS.primaryColor,
    animation: `${pulse} 1s ease-in-out infinite`,
    position: "absolute",
    top: -2,
    right:-2
  },
});
