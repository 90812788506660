import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";

export const AllMessageBtn = styled(Button)({
  textTransform: "none",
  textDecoration: "underline",
});

export const InfoBoxContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "6px",
  overflow: "hidden",
});

export const InfoBox = styled(Box)({
  flex: 1,
  height: "123px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});
