import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import PermContactCalendarRoundedIcon from "@mui/icons-material/PermContactCalendarRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import MenuIcon from '@mui/icons-material/Menu';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

function BottomTabs({ activeTab }) {
  const navigate = useNavigate();

  const handleTabChange = (event, tab) => {
    if (tab === "home") {
      navigate("/home");
      return;
    }
    if (tab === "favorites") {
      navigate("/wishlist");
      return;
    }
    if (tab === "contacted") {
      navigate("/contactedPro");
      return;
    }
    if (tab === "menu") {
      navigate("/profile");
      return;
    }
  };

  return (
    <StyledBox>
      <BottomNavigation
        sx={{ width: { xs: "100%", md: "450px" } }}
        value={activeTab}
        onChange={handleTabChange}
      >
        <BottomNavigationAction
          sx={{ p: 0 }}
          label={<LabelText variant="body2">Home</LabelText>}
          value="home"
          showLabel
          icon={<HomeRoundedIcon />}
        />

        <BottomNavigationAction
          sx={{ p: 0 }}
          label={<LabelText variant="body2">Favorites</LabelText>}
          value="favorites"
          showLabel
          icon={<FavoriteRoundedIcon />}
        />

        <BottomNavigationAction
          sx={{ p: 0 }}
          label={<LabelText variant="body2">Contacted Pro</LabelText>}
          value="contacted"
          showLabel
          icon={<PermContactCalendarRoundedIcon />}
        />

        {/* <BottomNavigationAction
          sx={{ p: 0 }}
          label={<LabelText variant="body2">Pro Listing</LabelText>}
          value="businessProfile"
          showLabel
          icon={<PersonRoundedIcon />}
        />
      </BottomNavigation> */}
      <BottomNavigationAction
          sx={{ p: 0 }}
          label={<LabelText variant="body2">Menu</LabelText>}
          value="menu"
          showLabel
          icon={< MenuIcon />}
        />
      </BottomNavigation>
    </StyledBox>
  );
}

export default BottomTabs;

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
});

const LabelText = styled(Typography)({
  fontSize: 11,
  fontWeight: 500,
});
