import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { COLORS } from "../../../styles/Theme";

export const StyledTextWithUnderline = styled(Typography)({
  fontSize: 14,
  fontWeight: "600",
  color: COLORS.black,
  marginTop: "16px",
  position: "relative",

  "&:before": {
    content: '" "',
    width: "140px",
    height: "2px",
    backgroundColor: COLORS.secondaryColor,
    position: "absolute",
    bottom: -8,
  },
});
