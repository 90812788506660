import { createTheme } from "@mui/material";

export const theme = {
  colors: {
    primary: "#077BC1",
    secondary: "#EEAE00",
    tertiary: "#303030",
    background: "#ffffff",
    text: "#000000",
    primaryDark: "#06629a",
    primaryTextColor: "#594F43",
    secondaryTextColor: "#777777",
    inputPlaceholder: "#C7C7C7",
    darkGrayText: "#303030",
    darkText: "#1A1A1A",
    black: "#000000",
    white: "#ffffff",
    dark: "",
    medium: "",
    light: "",
    danger: "",
    success: "#66A15A",
  },
  paddings: {
    container: "15px",
    pageTop: "30px",
  },
  margins: {
    pageTop: "30px",
  },
};

export const COLORS = {
  primaryColor: "#077BC1",
  secondaryColor: "#EEAE00",
  primaryDark: "#06629a",
  primaryLight: "#cde5f3",
  secondaryLight: "#f5ce66",
  white: "#ffffff",
  black: "#000000",
  blackShade1: "#232323",
  grey: "#656565",
  greyShade1: "#A5A5A5",
  greyShade2: "#EFEEEE",
  greyShade3: "#C2C2C2",
  greyShade4: "#DCD8D8",
  greyShade5: "#9A9797",
  greyShade6: "#DBDBDB",
  greyShade7: "#5B5B5B",
  greyShade8: "#F9F9F9",
  greyShade9: "#8492A7",
  greyShade10: "#EFEFEF",
  greyShade11: "#707070",
  greyShade12: "#E8E8E8",
  ratingFill: '#11A349',
  pinkishRed: '#FC3232',
  red:'#FC2121',
  redShade1:'#FF4A4A',
  green:'#11A349',
  lightOrange:'#FEF0CB', 
  lightBlue:'#D2EAF9'
};

export const muitheme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    allVariants: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
    h1: {
      fontWeight: 600,
      fontSize: "23px",
    },

    h2: {
      fontSize: "22px",
      fontWeight: 600,
    },

    h3: {
      fontSize: "18px",
      fontWeight: 600,
    },

    h4: {
      fontSize: "17px",
      fontWeight: 600,
    },

    h5: {
      fontSize: "16px",
      fontWeight: 600,
    },
    h6: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "26px",
    },
    subtitle1: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: "normal",
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "normal",
    },
    body1: {
      fontSize: "13px",
      lineHeight: "20px",
    },
    body2: {
      fontSize: "12px",
      lineHeight: "normal",
    },
    caption: {
      fontSize: "16px",
    },
  },
  palette: {
    primary: {
      main: COLORS.primaryColor,
    },
    secondary: {
      main: COLORS.secondaryColor,
    },
  },
});
