import styled from "@emotion/styled";
import { Box, Switch, Typography } from "@mui/material";
import React from "react";
import { textOneLine } from "../../styles/Global";
import { COLORS } from "../../styles/Theme";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

function BusinessLocationCard({
  imgUrl,
  title,
  subtitle,
  location,
  timing,
  status,
}) {
  return (
    <StyledCard>
      <CardContentBox>
        <BusinessImg src={imgUrl} alt="business Image" />

        <Box flex={1}>
          <Typography
            variant="boby1"
            fontSize={14}
            fontWeight={500}
            color={COLORS.black}
            sx={{ ...textOneLine }}
          >
            {title}
          </Typography>

          <Typography
            variant="boby2"
            fontSize={12}
            fontWeight={400}
            color={COLORS.grey}
            sx={{ ...textOneLine }}
          >
            {subtitle}
          </Typography>

          <Typography
            variant="boby2"
            fontSize={12}
            fontWeight={400}
            color={COLORS.grey}
            display="inline"
          >
            <Typography
              fontWeight={500}
              color={COLORS.blackShade1}
              display="inline"
            >
              Location:
            </Typography>{" "}
            {location}
          </Typography>

          <Typography
            variant="boby1"
            fontSize={11}
            fontWeight={500}
            color={COLORS.blackShade1}
            display="inline"
            sx={{ ...textOneLine, m: "4px 0 0" }}
          >
            <TimerOutlinedIcon
              sx={{
                fontSize: 14,
                color: COLORS.primaryColor,
                m: "0 3px -3px 0",
              }}
            />
            {timing}
          </Typography>

          <Typography
            variant="boby2"
            fontSize={12}
            fontWeight={400}
            color={COLORS.grey}
            display="inline"
          >
            <Typography
              fontWeight={500}
              color={COLORS.blackShade1}
              display="inline"
            >
              Status:
            </Typography>{" "}
            {status}
          </Typography>
        </Box>

        <Box>
          <RatingBox>
            <StarRoundedIcon fontSize="small" />

            <Typography
              variant="boby2"
              fontSize={11}
              fontWeight={400}
              color={COLORS.white}
              lineHeight="6px"
            >
              4.5
            </Typography>
          </RatingBox>

          <Typography
            variant="boby2"
            fontSize={8}
            fontWeight={400}
            color={COLORS.black}
            sx={{ textDecoration: "underline" }}
          >
            25 Reviews
          </Typography>
        </Box>
      </CardContentBox>

      <CardFooter>
        <Typography
          variant="boby1"
          fontSize={12}
          fontWeight={500}
          color={COLORS.blackShade1}
          display="inline"
          sx={{ ...textOneLine }}
        >
          25 Customers Contacted
          <ExpandCircleDownIcon
            sx={{
              fontSize: 16,
              color: COLORS.primaryColor,
              m: "0 0 -4px 5px",
              rotate: "-90deg",
            }}
          />
        </Typography>

        <Box>
          <Typography
            variant="boby1"
            fontSize={12}
            fontWeight={500}
            color={status === "Online" ? COLORS.green : COLORS?.red}
          >
            {status === "Online" ? 'Post Active' : 'Post Inactive'}
          </Typography>

          <StyledSwitch size="small" checked={status === "Online"} />
        </Box>
      </CardFooter>
    </StyledCard>
  );
}

export default BusinessLocationCard;

export const StyledCard = styled(Box)({
  backgroundColor: COLORS.white,
  borderRadius: "6px",
  boxShadow: "0px 2px 20px -0px rgba(0,0,0,0.13)",
  cursor: "pointer",
  marginBottom: "16px",
});

export const CardContentBox = styled(Box)({
  display: "flex",
  gap: "8px",
  padding: "8px",
  borderBottom: "1px solid #EFEEEE",
});

export const CardFooter = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
  padding: "8px",
});

const BusinessImg = styled.img({
  width: 97,
  height: 97,
  borderRadius: "8px",
  objectFit: "cover",
});

const RatingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "2px",
  color: COLORS.white,
  borderRadius: "3px",
  backgroundColor: COLORS.green,
  padding: "2px 6px 2px 4px",
});

const StyledSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase": {
    color: COLORS.red,
    "&.Mui-checked": {
      color: COLORS.green,

      "& + .MuiSwitch-track": {
        backgroundColor: COLORS.green,
      },
    },
  },
});
