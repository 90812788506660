import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import MessageCard from "../../../../components/Cards/MessageCard";
import Header from "../../../../components/UI/Header";
import { getAllComplaintsApi } from "../../../../services/api_calls/auth/AuthApi";
import CircularProgress from "@mui/material/CircularProgress";

function AllMessages() {
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllComplaints();
  }, []);

  const fetchAllComplaints = async () => {
    try {
      const response = await getAllComplaintsApi();
      if (response.success) {
        setComplaints(response.data);
      } else {
        console.error("Failed to fetch complaints:", response.message);
      }
    } catch (error) {
      console.error("Error fetching complaints:", error);
    } finally {
      setLoading(false);
    }
  };

  const getComplaintTitle = (type) => {
    const titles = {
      PAYMENT_CREDITS: "Payment Issue",
      CUSTOMER_COMPLAINT: "Customer Issue",
      ENQUIRY: "Enquiry Issue",
      PRO_COMPLAINTS: "Pro Complaint Issue",
      LOCATION: "Location Issue",
      BUSINESS_LISTING: "Business Listing Issue",
      // Add more types as needed
      DEFAULT: "General Issue",
    };
    return titles[type] || titles.DEFAULT;
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Header title="All Messages" />

      <Box m={2}>
        {complaints.map((complaint, index) => (
          <MessageCard
            key={complaint.id || index}
            title={getComplaintTitle(complaint.type)}
            subtitle={complaint.status}
            text={complaint.message}
            datetime={complaint.created_at}
            replyMessage={complaint.replies?.[0]?.message} // Get first reply if exists
            replyDate={complaint.replies?.[0]?.replied_on}
            hasReply={Boolean(complaint.replies?.length)}
          />
        ))}
      </Box>
    </Box>
  );
}

export default AllMessages;
