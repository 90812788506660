import React, { useState,useEffect } from 'react';
import { Box, Button, Dialog, TextField, Typography, DialogActions,CircularProgress } from '@mui/material';
import SuccessModal from './SuccessModal';
import { createVendorEnquiryApi,getCategoryDataApi  } from '../../services/api_calls/auth/AuthApi';
function EnquiryModal({ open, handleClose, vendorId, serviceId, businessName }) {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [serviceName, setServiceName] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
    });
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchServiceName = async () => {
            if (serviceId) {
                try {
                    const response = await getCategoryDataApi();
                    if (response?.status && response?.categories) {
                        const allServices = response.categories.flatMap(category => 
                            category.subCategory || []
                        );
                        const service = allServices.find(s => s.id === serviceId);
                        if (service) {
                            setServiceName(service.name);
                        }
                    }
                } catch (err) {
                    console.error('Error fetching service name:', err);
                }
            }
        };
        fetchServiceName();
    }, [serviceId]);

    // Load user data from token on mount
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const tokenPayload = JSON.parse(atob(token.split('.')[1]));
                if (tokenPayload.user && tokenPayload.user[0]) {
                    const user = tokenPayload.user[0];
                    setFormData({
                        name: user.name || '',
                        mobile: user.mobile || '',
                    });
                }
            } catch (error) {
                console.error('Error parsing token:', error);
            }
        }
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        // For mobile number, only allow numbers and max 10 digits
        if (name === 'mobile') {
            const numericValue = value.replace(/\D/g, '');
            if (numericValue.length <= 10) {
                setFormData(prev => ({
                    ...prev,
                    [name]: numericValue
                }));
            }
            return;
        }

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const validateForm = () => {
        if (!formData.name.trim()) {
            setError('Name is required');
            return false;
        }
        if (!formData.mobile || formData.mobile.length !== 10) {
            setError('Please enter a valid 10-digit mobile number');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!validateForm()) {
            return;
        }

        setLoading(true);
        try {
            const response = await createVendorEnquiryApi({
                name: formData.name,
                mobile: formData.mobile,
                vendor_id: vendorId,
                service_id: serviceId,
            });

            if (response && response.status) {
                handleClose();
                setTimeout(() => {
                    setIsSubmitted(true);
                }, 300);
            } else {
                setError('Failed to submit enquiry. Please try again.');
            }
        } catch (err) {
            setError('An error occurred. Please try again later.');
            console.error('Enquiry submission error:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSuccessModal = () => {
        setIsSubmitted(false);
    };

    return (
        <>
            <Dialog 
                open={open} 
                onClose={handleClose} 
                maxWidth="xs" 
                fullWidth
                PaperProps={{
                    sx: {
                        margin: { xs: '10px', sm: '20px auto' },
                        width: '100%',
                        maxWidth: '400px',
                        overflowX: 'hidden',
                    },
                }}
            >
                <Box 
                    p={{ xs: 2, sm: 3 }}
                    sx={{ 
                        width: '100%',
                        textAlign: 'center',
                        boxSizing: 'border-box'
                    }}
                >
                    <Typography 
                        mb={2} 
                        sx={{ 
                            color: '#000', 
                            fontSize: { xs: '14px', sm: '16px' }, 
                            fontWeight: '500'
                        }}
                    >
                        {`Looking for ${serviceName || 'services'} from ${businessName || 'this professional'}? Submit your details, and we will get back to you shortly.`}
                    </Typography>

                    {error && (
                        <Typography 
                            color="error" 
                            sx={{ mb: 2, fontSize: '14px' }}
                        >
                            {error}
                        </Typography>
                    )}

                    <TextField
                        name="name"
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={formData.name}
                        onChange={handleInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            fontSize: { xs: '14px', sm: '16px' }
                        }}
                    />

                    <TextField
                        name="mobile"
                        label="Mobile Number"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            maxLength: 10,
                        }}
                        sx={{
                            fontSize: { xs: '14px', sm: '16px' }
                        }}
                    />

                    <DialogActions sx={{ justifyContent: 'center', mt: 2 }}>
                        <Button 
                            variant="contained" 
                            fullWidth 
                            onClick={handleSubmit}
                            disabled={loading}
                            sx={{ 
                                backgroundColor: '#21608F',
                                '&:hover': {
                                    backgroundColor: '#21608F', 
                                },
                                fontSize: { xs: '14px', sm: '16px' },
                                padding: { xs: '8px', sm: '10px 16px' },
                            }}
                        >
                            {loading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Submit'
                            )}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <SuccessModal
                open={isSubmitted}
                handleClose={handleCloseSuccessModal}
                
                serviceName={serviceName}
            />
        </>
    );
}


export default EnquiryModal;
