import styled from "@emotion/styled";
import { Box, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";

function LocationSearchInput({
  placeholder,
  name,
  value,
  onChange,
  startIcon,
  endIcon,
  maxLength,
  inputType,
  disabled,
  onlyBorderBottom,
  inputId
}) {
  return (
    <Box sx={{ mb: 2 }}>
      <StyledTextField
        borderbottom={onlyBorderBottom}
        variant="standard"
        required={true}
        fullWidth
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              {startIcon}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start" sx={{ cursor: "pointer" }}>
              {endIcon}
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        inputProps={{ maxLength: maxLength, id: inputId }}
        type={inputType}
      />
    </Box>
  );
}

export default LocationSearchInput;

const StyledTextField = styled(TextField)(({ borderbottom }) => ({
  borderRadius: borderbottom ? "0px" : "8px",
  border: borderbottom ? "none" : "1px solid #707070",
  borderBottom: borderbottom ? "2px solid #DCDCDC" : "",
  padding: "4px 0",

  "& .MuiInputBase-input": {
    color: COLORS.black,
    fontSize: 14,
    fontWeight: 500,
    padding: "8px",
  },
}));
