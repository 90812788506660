import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Typography from "@mui/material/Typography";
import Check from "@mui/icons-material/Check";
import styled from "@emotion/styled";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";

const StepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  width: 22,
  height: 22,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...(ownerState.active && {}),
  ...(ownerState.completed && {
    backgroundColor: "green",
    color: "white",
  }),

  "& .QontoStepIcon-completedIcon": {
    color: "#ffffff",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 22,
    height: 22,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function StepIcon(props) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot ownerState={{ active, completed }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <Check className="QontoStepIcon-circle" />
      )}
    </StepIconRoot>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  marginLeft: "10px",
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      width: "3px",
      height: "40px",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      width: "3px",
      height: "40px",
      backgroundColor: "green",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: "3px",
    height: "40px",
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function VerticalStepper({ steps, activeStep, setActiveStep }) {
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<ColorlibConnector />}
      >
        {steps.map((step, index) => {
          if (activeStep < index) return null;
          return (
            <Step key={step.label + index}>
              <StepLabel sx={{ p: 0 }} StepIconComponent={StepIcon}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    if (activeStep >= index) {
                      handleClick(step?.link);
                    }
                  }}
                >
                  <Typography variant="body1">{step.label}</Typography>
                  {activeStep > index ? (
                    <Box
                      component="img"
                      sx={{
                        width: "18px",
                        height: "18px",
                        objectFit: "contain",
                      }}
                      src="/./assets/images/edit-icon.png"
                      alt="Edit Icon"
                    />
                  ) : activeStep === index ? (
                    <ChevronRightIcon />
                  ) : (
                    <></>
                  )}
                </Box>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default VerticalStepper;
