import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import BottomTabs from '../../../components/UI/BottomTabs';
import DashboardHeader from '../../../components/UI/DashboardHeader';
import { getContactedVendorsApi,createCallApi  } from '../../../services/api_calls/auth/AuthApi';

const SimpleContactCard = ({ vendor_name, service, contacted_on, vendor_phoneNumber, vendor_id, service_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }).replace(' at', ',');
  };
  const handleCall = async () => {

    if (!vendor_id || !service_id) {
      console.error('Missing required IDs:', { vendor_id, service_id });
      alert('Missing required vendor or service information');
      return;
    }

    try {
      const callData = {
        vendor_id: vendor_id,
        service_id: service_id
      };

      
      const response = await createCallApi(callData);
      
      if (response?.success) {
        window.location.href = `tel:${vendor_phoneNumber}`;
      } else {
        alert(response?.message || 'Failed to connect call');
      }
    } catch (error) {
      console.error('Error making call:', error);
      alert('Error connecting call. Please try again.');
    }
  };


  return (
    <Box 
      sx={{
        backgroundColor: '#ffffff',
        padding: '16px 20px',
        marginBottom: '12px',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.06)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '12px',
          boxShadow: '0 1px 3px rgba(0,0,0,0.08)',
          pointerEvents: 'none'
        }
      }}
    >
      <Box>
        <Typography 
          sx={{ 
            fontSize: '15px',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '500',
            color: '#343638',
            lineHeight: 1.3,
            marginBottom: '2px'
          }}
        >
          {vendor_name}
        </Typography>
        <Typography 
          sx={{ 
            fontSize: '13px',
            fontFamily: 'Poppins, Arial, sans-serif',
            color: '#232323',
            marginBottom: '2px',
            lineHeight: 1.2
          }}
        >
          {service}
        </Typography>
        <Typography 
          sx={{ 
            fontSize: '12px',
            fontFamily: 'Poppins, Arial, sans-serif',
            color: '#232323',
            lineHeight: 1.2
          }}
        >
          {formatDate(contacted_on)}
        </Typography>
      </Box>
      <IconButton
        sx={{
          width: '32px',
          height: '32px',
          marginTop:'8px',
          padding: 0,
          minWidth: '32px',
          borderRadius: '8px',
          overflow: 'hidden'
        }}
        onClick={handleCall}
        disabled={isLoading}
      >
        <img 
          src="/assets/images/icons8_call_squared.svg" 
          alt="Call"
          style={{ width: '100%', height: '100%' }}
        />
      </IconButton>
    </Box>
  );
};

const ContactedPro = () => {
  const [contactedList, setContactedList] = useState([]);
  const [totalContacts, setTotalContacts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getContactedVendorsApi();
        
        if (response?.status && response?.data) {
          setContactedList(response.data);
          setTotalContacts(response.total_contacts || response.data.length);
        } else {
          setError('No data available');
        }
      } catch (error) {
        console.error('Error fetching vendors:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const LoadingOverlay = () => (
    <Box 
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
      }}
    >
      <CircularProgress 
        size={40}
        sx={{
          color: '#21608F'
        }}
      />
    </Box>
  );
  

  if (error) {
    return (
      <Box className="min-h-screen bg-gray-50 flex items-center justify-center">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box pb={10} sx={{ backgroundColor: '#f8f8f8', minHeight: '100vh' }}>
      
      <DashboardHeader />

      <Box sx={{ px: '20px', py: '16px' }}>
        <Typography 
          sx={{
            fontSize: '14pt',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'Regular',
            color: '#343638',
            marginBottom: '16px'
          }}
        >
          {totalContacts} Contacted Pro History
        </Typography>

        <Box>
          {contactedList.map((item, index) => {
            return (
              <SimpleContactCard
                key={index}
                vendor_name={item.vendor_name}
                service={item.service}
                contacted_on={item.contacted_on}
                vendor_phoneNumber={item.vendor_phoneNumber}
                contact_type={item.contact_type}
                vendor_id={item.vendor_id}
                service_id={item.categoryId || item.service_id}
              />
            );
          })}
        </Box>
      </Box>

      <BottomTabs activeTab='contacted' />
    </Box>
  );
};

export default ContactedPro;