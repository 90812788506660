import styled from "@emotion/styled";
import { Box, IconButton, Typography } from "@mui/material";
import { COLORS } from "../../styles/Theme";

export const StyledContainer = styled(Box)({
  backgroundColor: COLORS.greyShade8,
});

export const HeaderContainer = styled(Box)({
  height: "268px",
  backgroundColor: COLORS.white,
});

export const StyledSection = styled(Box)({
  padding: "12px 6px",
  borderBottom: "1px dashed " + COLORS.greyShade3,
});

export const SideHeading = styled(Typography)({
  marginBottom: "3px",
});

export const ServiceItem = styled(Typography)({
  fontSize: 12,
  fontWeight: "500",
  color: COLORS.grey,
  position: "relative",
  paddingLeft: "14px",

  "&:before": {
    content: '" "',
    width: "8px",
    height: "8px",
    borderRadius: "8px",
    backgroundColor: COLORS.primaryColor,
    position: "absolute",
    top: 5,
    left: 0,
  },
});

export const ActionBtnBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "16px",
});

export const BackButton = styled(Box)({
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  backgroundColor: "#efeeee",
  boxShadow: "0px 0px 8px 0px rgb(0 0 0 / 23%)",
});

export const WishlistButton = styled(IconButton)({
  width: 40,
  height: 40,
  color: COLORS.secondaryColor,
  backgroundColor: COLORS.white,
  boxShadow: "0px 0px 8px 0px rgb(0 0 0 / 13%)",
});

export const DistanceCard = styled(Box)({
  width: "61px",
  borderRadius: "5px",
  backgroundColor: COLORS.greyShade8,
  overflow: "hidden",
  boxShadow: "0px 0px 8px 0px rgb(0 0 0 / 13%)",
});

export const DistanceContent = styled(Box)({
  padding: "0 3px",
  backgroundColor: COLORS.primaryColor,
});

export const MapBox = styled(Box)({
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
