import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  Button,
  IconButton,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BlogCard from "./Blogcard";
import Header from "../../components/UI/DashboardHeader/Header";
import Footer from "../../components/UI/Footer/Footer";
import { styled } from "@mui/material/styles";
import Loader from "../../components/Loader/Loader";
import BottomTabs from "../../components/UI/BottomTabs";
import {
  getBlogCategories,
  getBlogPosts,
} from "../../services/api_calls/auth/AuthApi";
import {
  ArrowBackIos as LeftIcon,
  ArrowForwardIos as RightIcon,
} from "@mui/icons-material";
import MostReadPosts from "./MostReadPosts";

// Styled Components
const PageContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 40px;

  @media (min-width: 600px) {
    padding-top: 110px;
  }
`;

const SliderContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  marginBottom: theme.spacing(6),
  "& .slider-content": {
    display: "flex",
    overflowX: "hidden",
    scrollBehavior: "smooth",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0),
  },
}));

const SliderCard = styled(Card)(({ theme }) => ({
  minWidth: 300,
  maxWidth: 300,
  cursor: "pointer",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const SliderButton = styled(IconButton)(({ theme, direction }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  ...(direction === "left" ? { left: -20 } : { right: -20 }),
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
}));

const CategoryTitle = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 600,
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(4),
}));

const ViewAllButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 600,
  marginBottom: theme.spacing(3),
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: -8,
    left: 0,
    width: 60,
    height: 3,
    backgroundColor: theme.palette.primary.main,
  },
}));

// Theme configuration
const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: "1200px",
        },
      },
    },
  },
});

const Slider = ({ items, onItemClick, itemType }) => {
  const containerRef = React.useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const updateButtonVisibility = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft < scrollWidth - clientWidth - 10);
    }
  };

  useEffect(() => {
    updateButtonVisibility();
  }, [items]);

  const scroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = direction === "left" ? -320 : 320;
      containerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
      
      // Update button visibility after scroll
      setTimeout(updateButtonVisibility, 300);
    }
  };

  // Enhanced styled components
  const EnhancedSliderContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
    padding: theme.spacing(1),
    '&:hover .navigation-button': {
      opacity: 1,
    },
  }));

  const SliderContent = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    overflow: "hidden",
    scrollBehavior: "smooth",
    WebkitOverflowScrolling: "touch",
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    padding: theme.spacing(1),
  }));

  const NavigationButton = styled(IconButton)(({ theme, direction }) => ({
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[3],
    opacity: 0,
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      transform: 'translateY(-50%) scale(1.1)',
      boxShadow: theme.shadows[5],
    },
    ...(direction === "left" ? { left: -16 } : { right: -16 }),
  }));

  const EnhancedSliderCard = styled(Card)(({ theme }) => ({
    minWidth: 280,
    maxWidth: 280,
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-8px)',
      boxShadow: theme.shadows[8],
      '& .card-media': {
        transform: 'scale(1.05)',
      },
    },
    overflow: 'hidden',
  }));

  const CardMediaWrapper = styled(Box)({
    overflow: 'hidden',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 50%)',
      transition: 'opacity 0.3s ease',
      opacity: 0,
    },
    '&:hover::after': {
      opacity: 1,
    },
  });

  const EnhancedCardMedia = styled(CardMedia)({
    height: 180,
    transition: 'transform 0.3s ease',
  });

  const CardContentWrapper = styled(CardContent)(({ theme }) => ({
    background: theme.palette.background.paper,
    transition: 'background 0.3s ease',
    '&:hover': {
      background: theme.palette.action.hover,
    },
  }));

  return (
    <EnhancedSliderContainer>
      {showLeftButton && (
        <NavigationButton
          className="navigation-button"
          direction="left"
          onClick={() => scroll("left")}
          size="small"
        >
          <LeftIcon />
        </NavigationButton>
      )}
      
      <SliderContent ref={containerRef} onScroll={updateButtonVisibility}>
        {items.map((item) => (
          <EnhancedSliderCard key={item._id} onClick={() => onItemClick(item)}>
            <CardMediaWrapper>
              <EnhancedCardMedia
                className="card-media"
                component="img"
                image={item.image || "/assets/images/home-banner.png"}
                alt={item.title || item.name}
                onError={(e) => {
                  e.target.src = "/assets/images/home-banner.png";
                }}
              />
            </CardMediaWrapper>
            <CardContentWrapper>
              <Typography 
                variant="h6" 
                noWrap 
                sx={{ 
                  mb: 1,
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}
              >
                {item.title || item.name}
              </Typography>
              {itemType === "post" && item.category && (
                <Typography 
                  variant="caption" 
                  color="primary"
                  sx={{
                    display: 'inline-block',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    bgcolor: 'primary.light',
                    color: 'primary.dark',
                    fontWeight: 500
                  }}
                >
                  {item.category.name}
                </Typography>
              )}
            </CardContentWrapper>
          </EnhancedSliderCard>
        ))}
      </SliderContent>

      {showRightButton && (
        <NavigationButton
          className="navigation-button"
          direction="right"
          onClick={() => scroll("right")}
          size="small"
        >
          <RightIcon />
        </NavigationButton>
      )}
    </EnhancedSliderContainer>
  );
};
const AutoPlaySlider = ({ items, onItemClick, itemType }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const totalSlides = items.length;

  useEffect(() => {
    let interval;
    if (!isHovered) {
      interval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % totalSlides);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [isHovered, totalSlides]);

  const handleSlideChange = (index) => setCurrentSlide(index);
  const handlePrevSlide = () => setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  const handleNextSlide = () => setCurrentSlide((prev) => (prev + 1) % totalSlides);

  // Styled Navigation Button - Made smaller and more subtle
  const NavigationButton = styled(IconButton)(({ theme, direction }) => ({
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 10,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    color: theme.palette.primary.main,
    transition: "all 0.3s ease",
    width: 36,
    height: 36,
    padding: 6,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      transform: "translateY(-50%) scale(1.1)",
    },
    ...(direction === "left"
      ? {
          left: 8,
          borderRadius: "0 4px 4px 0",
        }
      : {
          right: 8,
          borderRadius: "4px 0 0 4px",
        }),
    [theme.breakpoints.down("sm")]: {
      width: 30,
      height: 30,
      padding: 4,
    },
  }));

  // Adjusted SliderContainer for more compact height
  const SliderContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
    paddingTop: "40%", // Reduced height ratio
    overflow: "hidden",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2], // Reduced shadow
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50%",
    },
  }));

  const SlideContainer = styled(Box)({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    transition: "opacity 0.5s ease-in-out",
    "&.active": {
      opacity: 1,
    },
  });

  const ImageWrapper = styled(Box)({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
    },
  });

  // Adjusted SlideContent for more compact layout
  const SlideContent = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end", // Aligned to bottom
    padding: theme.spacing(3),
    background:
      "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)", // Bottom-up gradient
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  }));

  // Adjusted ContentText for more compact layout
  const ContentText = styled(Box)(({ theme }) => ({
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "80%",
    },
  }));

  // More compact indicators
  const Indicators = styled(Box)(({ theme }) => ({
    position: "absolute",
    bottom: 12,
    right: 12, // Moved to right
    display: "flex",
    gap: 6,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      bottom: 8,
      right: 8,
    },
  }));

  const Indicator = styled("div")(({ theme, active }) => ({
    width: 6,
    height: 6,
    borderRadius: "50%",
    backgroundColor: active
      ? theme.palette.primary.main
      : "rgba(255, 255, 255, 0.5)",
    cursor: "pointer",
    transition: "all 0.3s ease",
  }));

  return (
    <SliderContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <NavigationButton
        direction="left"
        onClick={handlePrevSlide}
        aria-label="Previous Slide"
      >
        <LeftIcon fontSize="small" />
      </NavigationButton>
      <NavigationButton
        direction="right"
        onClick={handleNextSlide}
        aria-label="Next Slide"
      >
        <RightIcon fontSize="small" />
      </NavigationButton>

      {items.map((item, index) => (
        <SlideContainer
          key={item._id}
          className={index === currentSlide ? "active" : ""}
          onClick={() => onItemClick(item)}
          sx={{ cursor: "pointer" }}
        >
          <ImageWrapper>
            <img
              src={item.image || "/assets/images/home-banner.png"}
              alt={item.title || item.name}
              onError={(e) => {
                e.target.src = "/assets/images/home-banner.png";
              }}
            />
          </ImageWrapper>
          <SlideContent>
            <ContentText>
              <Typography
                variant="h5" // Reduced from h4
                gutterBottom
                sx={{
                  fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.8rem" },
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                {item.title || item.name}
              </Typography>
              {itemType === "post" && item.description && (
                <Typography
                  variant="body2" // Reduced from body1
                  sx={{
                    mb: 2,
                    display: { xs: "none", sm: "block" },
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.description}
                </Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                size="small" // Changed to small
                sx={{
                  mt: { xs: 1, sm: 1 },
                  px: { xs: 2, sm: 2 },
                  py: { xs: 0.5, sm: 0.5 },
                }}
              >
                {itemType === "post" ? "Read More" : "View All"}
              </Button>
            </ContentText>
          </SlideContent>
        </SlideContainer>
      ))}

      <Indicators>
        {items.map((_, index) => (
          <Indicator
            key={index}
            active={index === currentSlide}
            onClick={() => handleSlideChange(index)}
          />
        ))}
      </Indicators>
    </SliderContainer>
  );
};
const BlogPage = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [posts, setPosts] = useState({});
  const [topRatedPosts, setTopRatedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mainCategories, setMainCategories] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Fetch categories
      const categoriesResponse = await getBlogCategories();
      const activeCategories = categoriesResponse.categories.filter(
        (category) => category.status === "active" && category.postsCount > 0
      );
      // Set all categories for the slider
      setCategories(activeCategories);

      // Set only first 3 categories for main display
      setMainCategories(activeCategories.slice(0, 3));

      // Fetch posts for each category
      const postsData = {};
      const allPosts = [];

      await Promise.all(
        activeCategories.map(async (category) => {
          try {
            const postsResponse = await getBlogPosts(category._id);
            const categoryPosts = postsResponse.posts.filter(
              (post) =>
                post.category?._id === category._id ||
                post.category === category._id
            );

            postsData[category._id] = categoryPosts
              .slice(0, Math.min(3, category.postsCount))
              .map((post) => ({
                ...post,
                category: {
                  _id: category._id,
                  name: category.name,
                },
              }));

            allPosts.push(...categoryPosts);
          } catch (err) {
            console.error(
              `Error fetching posts for category ${category.name}:`,
              err
            );
            postsData[category._id] = [];
          }
        })
      );

      setPosts(postsData);
      setTopRatedPosts(allPosts.filter((post) => post.top_rated).slice(0, 10));
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load blog content. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleViewAll = (categoryId, categoryName) => {
    navigate(
      `/blog/category/${categoryName.toLowerCase().replace(/\s+/g, "-")}`,
      {
        state: { categoryId, categoryName },
      }
    );
  };

  const handlePostClick = (post) => {
    const urlFriendlyTitle = post.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)/g, "");
    navigate(`/blog/${urlFriendlyTitle}`, { state: { postId: post._id } });
  };

  const handleCategoryClick = (category) => {
    handleViewAll(category._id, category.name);
  };

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Header />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <Loader />
        </Box>
      </ThemeProvider>
    );
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Header />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <Typography color="error">{error}</Typography>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <PageContainer maxWidth="lg">
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          align="center"
          sx={{ mb: 6 }}
        >
          Blog & Articles
        </Typography>

        {topRatedPosts.length > 0 && (
          <Grid container spacing={3} sx={{ mb: 6 }}>
            <Grid item xs={12} md={7}>
              <Box>
                <SectionTitle variant="h5" gutterBottom>
                  Top Rated Posts
                </SectionTitle>
                <AutoPlaySlider
                  items={topRatedPosts}
                  onItemClick={handlePostClick}
                  itemType="post"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box height="100%">
                <MostReadPosts onPostClick={handlePostClick} />
              </Box>
            </Grid>
          </Grid>
        )}

        {/* Category Sections */}
        {mainCategories.map((category) => (
          <Box key={category._id}>
            <CategoryTitle variant="h4">{category.name}</CategoryTitle>
            <Grid container spacing={4}>
              {posts[category._id]?.map((post) => (
                <Grid item xs={12} md={4} key={post._id}>
                  <BlogCard post={post} />
                </Grid>
              ))}
              {(!posts[category._id] || posts[category._id].length === 0) && (
                <Grid item xs={12}>
                  <Typography color="text.secondary" align="center">
                    No posts available in this category.
                  </Typography>
                </Grid>
              )}
            </Grid>
            {posts[category._id]?.length > 0 && category.postsCount > 3 && (
              <Box sx={{ textAlign: "center" }}>
                <ViewAllButton
                  variant="outlined"
                  color="primary"
                  onClick={() => handleViewAll(category._id, category.name)}
                >
                  View All {category.name} Posts ({category.postsCount})
                </ViewAllButton>
              </Box>
            )}
          </Box>
        ))}
        {/* Categories Slider */}
        {categories.length > 0 && (
          <Box sx={{ mb: 6 }}>
            <SectionTitle variant="h5" gutterBottom>
              Categories
            </SectionTitle>
            <Slider
              items={categories}
              onItemClick={handleCategoryClick}
              itemType="category"
            />
          </Box>
        )}
      </PageContainer>
      {isMobile && <BottomTabs activeTab="blog" />}
      <Footer />
    </ThemeProvider>
  );
};

export default BlogPage;
