import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, IconButton, Typography, Rating, TextField, Button, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ThanksModal from './ThanksModal';
import { createRatingApi } from '../../services/api_calls/auth/AuthApi';

const ReviewModal = ({ open, handleClose, vendorId, serviceId, onReviewSubmitted }) => {
  const [ratingValue, setRatingValue] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [showThanksModal, setShowThanksModal] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseUsername, setResponseUsername] = useState('');

  const resetForm = () => {
    setRatingValue(0);
    setReviewText('');
    setError('');
  };

  const handleSubmitReview = async () => {
    if (!ratingValue) {
      setError('Please provide a rating');
      return;
    }

    if (!vendorId || !serviceId) {
      setError('Missing required vendor or service information');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      setError('Please login to submit a review');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      const postData = {
        vendor_id: vendorId,
        service_id: serviceId,
        rating: ratingValue,
        review: reviewText.trim()
      };

      const response = await createRatingApi(postData);
      
      if (response && response.rating) {
        setResponseUsername(response.rating.user_name);
        handleClose();
        setShowThanksModal(true);
        resetForm();
        
        // Call the callback to refresh vendor details
        if (onReviewSubmitted) {
          onReviewSubmitted();
        }
      } else {
        setError('Failed to submit review. Please try again.');
      }
    } catch (err) {
      console.error('Error submitting review:', err);
      setError(err.response?.data?.message || 'Failed to submit review. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    resetForm();
    handleClose();
  };

  const handleThanksModalClose = () => {
    setShowThanksModal(false);
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={handleModalClose} 
        maxWidth="sm" 
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Review Local Pro</Typography>
            <IconButton onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2}>
            {error && (
              <Alert severity="error" onClose={() => setError('')}>
                {error}
              </Alert>
            )}

            <Rating
              name="review-rating"
              value={ratingValue}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
                if (error) setError('');
              }}
              size="large"
              precision={0.5}
            />

            <TextField
              label="Write your review"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
              inputProps={{ maxLength: 5000 }}
              helperText={`${reviewText.length}/5000`}
            />

            <Button
              variant="contained"
              size="large"
              sx={{ 
                fontWeight: 600, 
                borderRadius: "8px",
                backgroundColor: '#21608F',
                alignSelf: 'center',
                width: '50%',
                '&:disabled': {
                  backgroundColor: '#cccccc'
                }
              }}
              onClick={handleSubmitReview}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <ThanksModal 
        open={showThanksModal} 
        handleClose={handleThanksModalClose}
        username={responseUsername}
      />
    </>
  );
};

export default ReviewModal;