import styled from "@emotion/styled";
import { Box, IconButton } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { COLORS } from "../../../styles/Theme";

function SelectedFileImg({ imgURL, onDelete }) {
  return (
    <ImgContainer>
      <StyledImg src={imgURL} alt="Selected Upload Image" />

      <StyledIconButton aria-label="delete" onClick={onDelete}>
        <DeleteIcon />
      </StyledIconButton>
    </ImgContainer>
  );
}

export default SelectedFileImg;

const ImgContainer = styled(Box)({
  position: "relative",
});

const StyledImg = styled.img({
  width: "100%",
  height: "auto",
  borderRadius: "6px",
});

const StyledIconButton = styled(IconButton)({
  backgroundColor: COLORS.white,
  position: "absolute",
  top: 10,
  right: 10,
});
