import React from "react";
import { useMediaQuery } from "@mui/material";
import { Container, Typography, Grid, Paper, Box,ThemeProvider,
  createTheme } from "@mui/material";
import styled from "@emotion/styled";
import Header from "../DashboardHeader/Header";
import BottomTabs from "../BottomTabs";
import Footer from "./Footer";

// Styled components
const PageContainer = styled(Container)`
  padding-top: 20px; // This will be the mobile padding
  padding-bottom: 40px;

  @media (min-width: 600px) {
    padding-top: 80px; // This will be the desktop padding
  }
`;

const SectionTitle = styled(Typography)`
  color: #1a237e;
  margin-bottom: 24px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 60px;
    height: 3px;
    background-color: #1a237e;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 24px;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }
`;
const HeroSection = styled(Box)`
  margin-bottom: 48px;
  padding-top: 24px;

  @media (min-width: 600px) {
    padding-top: 48px;
  }
`;
const ContentWrapper = styled.div`
  padding-bottom: ${(props) => (props.isMobile ? "72px" : "0")};
`;

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: "1200px",
        },
      },
    },
  },
});
const AboutUs = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
<ThemeProvider theme={theme}>
      <Header />
      <ContentWrapper isMobile={isMobile}>
        <PageContainer maxWidth="lg">
          {/* Hero Section */}
          <HeroSection textAlign="center">
            <Typography variant="h3" component="h1" gutterBottom>
              About Local Pro
            </Typography>
            <Typography variant="h6" color="textSecondary" paragraph>
              Connecting Communities with Trusted Local Services Since 2020
            </Typography>
          </HeroSection>

          {/* Mission & Vision Section */}
          <Grid container spacing={4} mb={6}>
            <Grid item xs={12} md={6}>
              <StyledPaper elevation={3}>
                <SectionTitle variant="h5" gutterBottom>
                  Our Mission
                </SectionTitle>
                <Typography paragraph>
                  At Local Pro, our mission is to bridge the gap between quality
                  local service providers and community members seeking reliable
                  solutions. We strive to create a platform where trust,
                  professionalism, and excellence come together to serve our
                  communities better.
                </Typography>
              </StyledPaper>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledPaper elevation={3}>
                <SectionTitle variant="h5" gutterBottom>
                  Our Vision
                </SectionTitle>
                <Typography paragraph>
                  We envision a future where finding and connecting with trusted
                  local service providers is seamless and reliable. Our platform
                  aims to transform how communities access and interact with
                  local services, making quality service accessible to all.
                </Typography>
              </StyledPaper>
            </Grid>
          </Grid>

          {/* Values Section */}
          <Box mb={6}>
            <SectionTitle variant="h4" gutterBottom>
              Our Core Values
            </SectionTitle>
            <Grid container spacing={3}>
              {["Trust", "Quality", "Community", "Innovation"].map(
                (value, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <StyledPaper elevation={3}>
                      <Typography variant="h6" gutterBottom>
                        {value}
                      </Typography>
                      <Typography color="textSecondary">
                        {value === "Trust" &&
                          "Building and maintaining trust through verified service providers and transparent operations."}
                        {value === "Quality" &&
                          "Ensuring the highest standards of service quality through careful vetting and monitoring."}
                        {value === "Community" &&
                          "Fostering strong community relationships and supporting local businesses."}
                        {value === "Innovation" &&
                          "Continuously improving our platform to better serve our users and service providers."}
                      </Typography>
                    </StyledPaper>
                  </Grid>
                )
              )}
            </Grid>
          </Box>

          {/* Team Section */}
          <Box>
            <SectionTitle variant="h4" gutterBottom>
              Our Team
            </SectionTitle>
            <Typography paragraph>
              Local Pro is powered by a dedicated team of professionals who are
              passionate about connecting communities with quality services. Our
              team brings together expertise in technology, customer service,
              and community building to create the best possible platform for
              our users.
            </Typography>
          </Box>
        </PageContainer>
      </ContentWrapper>
      {isMobile && <BottomTabs activeTab="about" />}
      <Footer/>
 </ThemeProvider>
  );
};

export default AboutUs;
