export const createUrlSlug = (str) => {
  if (!str) return '';
  
  return str
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '_')       
    .replace(/[^\w\-]+/g, '')    
    .replace(/\-\-+/g, '_')      
    .replace(/^-+/, '')          
    .replace(/-+$/, '');         
};

export const createCitySlug = (cityName) => {
  return cityName
    .toLowerCase()
    .replace(/[^\w\s-]/g, "") 
    .replace(/\s+/g, "") 
    .replace(/-+/g, "_"); 
};


export const generateServiceUrl = (cityPath, serviceName) => {
  const serviceSlug = createUrlSlug(serviceName);
  return cityPath ? `/${cityPath}/${serviceSlug}` : `/${serviceSlug}`;
};

export const generateSlug = (text) => {
  if (!text) return "";
  return text
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "_")
    .replace(/-+/g, "_")
    .trim();
};

