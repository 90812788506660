import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import ContactPlanCard from "../../../components/Cards/ContactPlanCard";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import Header from "../../../components/UI/Header";
import { StyledTextWithUnderline } from "../../../components/UI/Typography/StyledTextWithUnderline";
import { COLORS } from "../../../styles/Theme";
import { CONTACT_PLANS } from "../../../utils/Data";

function ContactPlans() {
  const [selectedPlan, setselectedPlan] = useState("Basic Plan");

  const handlePlanSelect = (plan) => {
    setselectedPlan(plan);
  };

  return (
    <Box>
      <Header title="CONTACT PLANS" disableShadow />

      <Typography
        variant="h2"
        fontSize={18}
        fontWeight={600}
        color={COLORS.blackShade1}
        textAlign="center"
        mt="30px"
      >
        Select Pro Contact Plans
      </Typography>

      <Box m="30px 16px">
        {CONTACT_PLANS?.map((item, index) => (
          <ContactPlanCard
            key={"contact_plan_" + index}
            price={item.price}
            title={item.contacts}
            subtitle={item.info}
            plan={item.plan}
            isChecked={selectedPlan === item.plan}
            onClick={handlePlanSelect}
          />
        ))}
        <StyledFormButton onClick={() => {}}>Proceed</StyledFormButton>

        <StyledTextWithUnderline variant="h4">
          Terms & Conditions :
        </StyledTextWithUnderline>

        <Typography
          variant="h4"
          fontSize={10}
          fontWeight={500}
          color={COLORS.black}
          m="16px"
        >
          Amount will be not refundable.
        </Typography>
      </Box>
    </Box>
  );
}

export default ContactPlans;
