// import { Box, Grid, Typography } from "@mui/material";
// import React, { useEffect } from "react";
// import RecommendedService from "../../../components/Cards/RecommendedService";
// import ServiceCardMinimal from "../../../components/Cards/ServiceCardMinimal";
// import StatesInfoCard from "../../../components/Cards/StatesInfoCard";
// import DashboardHeader from "../../../components/UI/DashboardHeader";
// import {
//   NEARBY_PROFESSIONALS,
//   NEARBY_SERVICES,
//   SERVICE_LIST_HOME,
// } from "../../../utils/Data";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { BannerImg, StyledViewAllButton, StylesIconBg } from "./Home.style";
// import ProfessionalInfoCard from "../../../components/Cards/ProfessionalInfoCard";
// import PopularServiceCard from "../../../components/Cards/PopularServiceCard";
// import BottomTabs from "../../../components/UI/BottomTabs";
// import { useNavigate } from "react-router-dom";
// import Slider from "react-slick";
// import { homeSliderSettings } from "../../../utils/SlickConfigs";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getHomeCategoryData,
//   getHomeTestData,
// } from "../../../redux/home/HomeSlice";

// function Home() {
//   const MAX_SERVICE_COUNT = 8;

//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const {
//     homeTextContent,
//     homeTextContentLoader,
//     homeCategary,
//     homeCategaryLooader,
//   } = useSelector((state) => state.home);

//   const handleViewAllCategory = () => {
//     navigate("/categories", {
//       state: { categoryData: "hello" },
//     });
//   };

//   const handleViewProfessional = () => {
//     navigate("/professionalDetails");
//   };

// const handleServiceClick = () => {
//   navigate("/serviceDetails");
// };

//   useEffect(() => {
//     // dispatch(getHomeTestData());
//     dispatch(getHomeCategoryData());
//     return () => {};
//   }, []);

//   return (
//     <Box pb={10} width={717}>
//       <DashboardHeader />

//       {/* {JSON.stringify(homeTextContent)} */}

//       {/* Recommened Services */}
//       <Box>
//         <Typography
//           variant="h3"
//           fontSize={16}
//           fontWeight={500}
//           m="100px 15px 15px 15px"
//         >
//           Recommended Services
//         </Typography>

//         <Slider {...homeSliderSettings}>
//           {NEARBY_PROFESSIONALS.map((item, index) => {
//             return (
//               <Box key={"recommended_service_" + index}>
//                 <RecommendedService />
//               </Box>
//             );
//           })}
//         </Slider>
//       </Box>

//       {/* Service Stats */}
//       <Box
//         display="flex"
//         alignItems="center"
//         justifyContent="space-between"
//         m="15px 10px"
//       >
//         <StatesInfoCard
//           icon="/./assets/images/statsicons/services.png"
//           title="350+"
//           value="Services"
//         />
//         <StatesInfoCard
//           icon="/./assets/images/statsicons/verified-profile.png"
//           title="3500+"
//           value="Verified Pros"
//         />
//         <StatesInfoCard
//           icon="/./assets/images/statsicons/reviews.png"
//           title="10000+"
//           value="Reviews"
//         />
//         <StatesInfoCard
//           icon="/./assets/images/statsicons/cities.png"
//           title="15+"
//           value="Service Cities"
//         />
//       </Box>

//       {/* Recommened Services */}
//       <Box m={2}>
//         <Typography variant="h3" fontSize={16} fontWeight={500} mb="15px">
//           Services
//         </Typography>

//         <Grid container spacing={2}>
//           {SERVICE_LIST_HOME?.slice(0, MAX_SERVICE_COUNT + 1)?.map(
//             (item, index) => {
//               if (index > MAX_SERVICE_COUNT - 1) {
//                 return (
//                   <Grid item xs={4} key={"service_" + index}>
//                     <ViewAllButton onClick={handleViewAllCategory} />
//                   </Grid>
//                 );
//               }

//               return (
//                 <Grid item xs={4} key={"service_" + index}>
//                   <ServiceCardMinimal
//                     imgUrl={item.imgUrl}
//                     title={item.name}
//                     onClick={handleServiceClick}
//                   />
//                 </Grid>
//               );
//             }
//           )}
//         </Grid>
//       </Box>

//       {/* Nearby Professionals */}
//       <Box>
//         <Typography
//           variant="h3"
//           fontSize={16}
//           fontWeight={500}
//           m="16px 0 6px 16px"
//         >
//           Nearby Professionals
//         </Typography>

//         <Slider {...homeSliderSettings}>
//           {NEARBY_PROFESSIONALS.map((item, index) => {
//             return (
//               <Box key={"nearby_prof_" + index}>
//                 <ProfessionalInfoCard
//                   imgUrl={item?.profileImg}
//                   name={item?.name}
//                   title={item?.businessName}
//                   rating={item?.rating}
//                   reviewCount={item?.reviewCount}
//                   caterogy={item?.caterogy}
//                   timings={item?.timings}
//                   address={item?.address}
//                   distance={item?.distance}
//                   onContantClick={handleViewProfessional}
//                   spacing="10px 0px 10px 10px"
//                   cardWidth="310px"
//                 />
//               </Box>
//             );
//           })}
//         </Slider>
//       </Box>

//       {/* Nearby Popular Services */}
//       <Box>
//         <Typography
//           variant="h3"
//           fontSize={16}
//           fontWeight={500}
//           m="16px 0 6px 16px"
//         >
//           Nearby Popular Services
//         </Typography>

//         <Slider {...homeSliderSettings}>
//           {NEARBY_SERVICES.map((item, index) => (
//             <PopularServiceCard
//               key={"nearby_services_" + index}
//               imgUrl={item?.imgUrl}
//               title={item?.name}
//               spacing="10px 0px 10px 10px"
//               onClick={handleServiceClick}
//             />
//           ))}
//         </Slider>
//       </Box>

//       {/* Banner */}

//       <Box sx={{ margin: "0 15px 15px 15px" }}>
//         <BannerImg
//           src="/./assets/images/home-banner.png"
//           alt="local pro banner"
//         />
//       </Box>

//       <BottomTabs activeTab="home" />
//       <Box sx={{float:'right'}}><Typography>
//         Download</Typography></Box>
//     </Box>
//   );
// }

// export default Home;

// const ViewAllButton = ({ onClick }) => (
//   <StyledViewAllButton onClick={onClick}>
//     <StylesIconBg>
//       <ExpandMoreIcon fontSize="large" />
//     </StylesIconBg>

//     <Typography
//       variant="h4"
//       fontSize={13}
//       fontWeight={600}
//       color="primary"
//       lineHeight="20px"
//       textAlign="center"
//       mt={1}
//     >
//       View All
//     </Typography>
//   </StyledViewAllButton>
// );

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  Button,
  CircularProgress,
} from "@mui/material";
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import Header from "../../../components/UI/DashboardHeader/Header";
import {
  ServicesGrid,
  ServiceItem,
  ServiceImage,
  ServiceText,
  ProfessionalCardContainer,
  ProfessionalCard,
  ImageOverlay,
  OverlayImage,
  OverlayGradient,
  ProfessionalCardTitle,
  SliderCard,
  LeftSectionContainer,
  StyledCard,
  StyledCardContent,
  StyledCardMedia,
  FullHeightCardMedia,
  BorewellCardMedia,
  BorewellCard,
  BorewellServiceName,
} from "./Home.style";
import BottomTabs from "../../../components/UI/BottomTabs";
import {
  getCategoryDataApi,
  getCityDataApi,
} from "../../../services/api_calls/auth/AuthApi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RightSection from "./RightSection";
import HomeFooter from "../../../components/UI/Footer/Footer";
import ListNowModal from "./ListNowModal";
import Loader from "../../../components/Loader/Loader";
import { createUrlSlug, createCitySlug } from "../../../utils/urlUtils";
const Services = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:960px)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [categories, setCategories] = useState({});
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [otp, setOtp] = useState("");
  const [listNowPopup, setListNowPopup] = useState(false);
  const [cities, setCities] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [cityError, setCityError] = useState(null);
  const { citySlug } = useParams();
  const handleContactSubmit = (formValues) => {
    setShowContactPopup(false);
    setShowOTPPopup(true);
  };

  const handleOtpSubmit = () => {
    setShowOTPPopup(false);
    setShowSuccessPopup(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch both categories and cities simultaneously
        const [categoriesResponse, citiesResponse] = await Promise.all([
          getCategoryDataApi(),
          getCityDataApi(),
        ]);

        // Handle categories
        if (categoriesResponse?.status && categoriesResponse?.categories) {
          setCategories(categoriesResponse.categories);
        } else {
          throw new Error("Failed to fetch categories");
        }

        // Handle cities
        if (!citiesResponse) {
          throw new Error("No response received from the server");
        }
        if (citiesResponse.status !== true) {
          throw new Error(citiesResponse.message || "Failed to fetch cities");
        }
        if (!citiesResponse.cities || !Array.isArray(citiesResponse.cities)) {
          throw new Error("Invalid cities data received");
        }
        const sortedCities = citiesResponse.cities.sort(
          (a, b) => a.position - b.position
        );
        setCities(sortedCities);
      } catch (err) {
        setError(err.message || "Error fetching data");
        console.error("Error in fetchData:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCityClick = (city) => {
    const citySlug = createCitySlug(city.name);
    localStorage.setItem("selectedCity", city.name);
    const event = new CustomEvent("cityUpdated", {
      detail: { city: city.name },
    });
    window.dispatchEvent(event);

    navigate(`/${citySlug}`);
  };
  useEffect(() => {
    const storedCity = localStorage.getItem("selectedCity");
    if (citySlug && storedCity) {
      const event = new CustomEvent("cityChanged", {
        detail: { city: storedCity },
      });
      window.dispatchEvent(event);
    }
  }, [citySlug]);
  const handleServiceClick = (subCategory) => {
    if (!subCategory?.name) return;

    const serviceSlug = createUrlSlug(subCategory.name);
    const currentCity = localStorage.getItem("selectedCity");
    const currentCitySlug = currentCity ? createUrlSlug(currentCity) : citySlug;
    const navigationPath = currentCitySlug
      ? `/${currentCitySlug}/${serviceSlug}`
      : `/${serviceSlug}`;

    navigate(navigationPath, {
      state: {
        selectedService: subCategory.name,
        selectedServiceId: subCategory.id,
        cityName: currentCity,
      },
    });
  };

  const handleOpenListPopup = () => {
    setListNowPopup(true);
  };
  const handleCloseListPopup = () => {
    setListNowPopup(false);
  };
  const theme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            maxWidth: "1200px",
          },
        },
      },
    },
  });
  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Header />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh"
          }}
        >
          <Loader />
        </Box>
      </ThemeProvider>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    // isMobile ? 1.5 : isTablet ? 2.5 : 3.5,
    slidesToScroll: 1,
  };
  const slide = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    // isMobile ? 1.5 : isTablet ? 2.5 : 3.5,
    slidesToScroll: 1,
  };
  return (
    <ThemeProvider theme={theme}>
      <Header />
      {isMobile && (
        <Box
          sx={{
            backgroundColor: "#EAF6FF",
            padding: "10px",
            marginTop: "20px",
            border: "1px solid #448EC6",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ color: "#21608F", fontSize: "15px", fontWeight: "bold" }}
          >
            List your Business with Local Pro
          </Typography>

          <div>
            <Button
              variant="contained"
              color="primary"
              sx={{ padding: "5px 10px", backgroundColor: "#21608F" }}
              onClick={handleOpenListPopup}
            >
              List Now
            </Button>
            <ListNowModal
              open={listNowPopup}
              handleClose={handleCloseListPopup}
            />
          </div>
        </Box>
      )}
      <Grid
        container
        spacing={3}
        sx={{
          marginTop: { md: "20px" },
          padding: { xs: "15px", md: "80px" },
        }}
      >
        {" "}
        {/* Left Section */}
        <Grid item xs={12} md={6} sx={{ paddingTop: "0px" }}>
          <LeftSectionContainer>
              <>
                {/* On Demand Services*/}
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: "#000000",
                    marginBottom: "20px",
                    fontSize: "20px",
                  }}
                >
                  {Array.isArray(categories) && categories.length > 0
                    ? categories.find((category) => category.position === 1)
                        ?.name || "No category name available"
                    : "No category name available"}
                </Typography>
                <ProfessionalCardContainer
                  container
                  onClick={handleServiceClick}
                >
                  {Array.isArray(categories) && categories.length > 0 ? (
                    categories
                      .filter((category) => category.position === 1)
                      .map((category, index) => (
                        <React.Fragment key={index}>
                          {Array.isArray(category.subCategory) &&
                          category.subCategory.length > 0 ? (
                            category.subCategory
                              .slice(0, 4)
                              .map((subCategory, subIndex) => (
                                <ProfessionalCard
                                  key={subIndex}
                                  onClick={() =>
                                    handleServiceClick(subCategory)
                                  }
                                >
                                  <ImageOverlay>
                                    <OverlayImage
                                      src={subCategory.image}
                                      alt={subCategory.name}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          "./assets/images/home-banner.png";
                                      }}
                                    />
                                    <OverlayGradient />
                                  </ImageOverlay>
                                  <ProfessionalCardTitle>
                                    {subCategory.name}
                                  </ProfessionalCardTitle>
                                </ProfessionalCard>
                              ))
                          ) : (
                            <Typography>No subcategories available.</Typography>
                          )}
                        </React.Fragment>
                      ))
                  ) : (
                    <Typography>No categories available.</Typography>
                  )}
                </ProfessionalCardContainer>
                {/*  Repair & Service*/}
                <Box sx={{ marginTop: "20px" }}>
                  <Typography
                    sx={{
                      fontSize: "42px",
                      font: "normal normal medium Poppins",
                      fontWeight: "600",
                      color: "#000000",
                      marginBottom: "20px",
                    }}
                  >
                    {Array.isArray(categories) && categories.length > 0
                      ? categories.find((category) => category.position === 2)
                          ?.name || "No category name available"
                      : "No category name available"}
                  </Typography>
                  <ServicesGrid container onClick={handleServiceClick}>
                    {Array.isArray(categories) && categories.length > 0 ? (
                      categories
                        .filter((category) => category.position === 2)
                        .map((category, index) => (
                          <React.Fragment key={index}>
                            {Array.isArray(category.subCategory) &&
                            category.subCategory.length > 0 ? (
                              category.subCategory.map(
                                (subCategory, subIndex) => (
                                  <ServiceItem
                                    key={subIndex}
                                    onClick={() =>
                                      handleServiceClick(subCategory)
                                    }
                                  >
                                    <ServiceImage
                                      src={subCategory.image}
                                      alt={subCategory.name}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          "./assets/images/home-banner.png";
                                      }}
                                    />
                                    <ServiceText>
                                      {subCategory.name}
                                    </ServiceText>{" "}
                                    {/* Display subCategory's name */}
                                  </ServiceItem>
                                )
                              )
                            ) : (
                              <Typography>
                                No subcategories available.
                              </Typography>
                            )}
                          </React.Fragment>
                        ))
                    ) : (
                      <Typography>No categories available.</Typography>
                    )}
                  </ServicesGrid>
                </Box>
                {/*  Cleaning Service*/}
                <Box sx={{ marginTop: "20px" }}>
                  <Typography
                    sx={{
                      fontSize: "42px",
                      font: "normal normal medium Poppins",
                      fontWeight: "600",
                      color: "#000000",
                      marginBottom: "20px",
                    }}
                  >
                    {Array.isArray(categories) && categories.length > 0
                      ? categories.find((category) => category.position === 4)
                          ?.name || "No category name available"
                      : "No category name available"}
                  </Typography>

                  <Box>
                    <Slider {...settings}>
                      {Array.isArray(categories) && categories.length > 0 ? (
                        categories
                          .filter((category) => category.position === 4) // Filter based on position
                          .map((category) =>
                            category.subCategory.map((subcategory) => (
                              <SliderCard key={subcategory.id}>
                                <Card
                                  style={{
                                    backgroundColor: "#F6F7F7",
                                    marginRight: "16px",
                                    height: "200px",
                                  }}
                                  onClick={() =>
                                    handleServiceClick(subcategory)
                                  }
                                >
                                  <CardMedia
                                    component="img"
                                    sx={{ height: "140px" }}
                                    image={subcategory.image}
                                    alt={subcategory.name}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "./assets/images/home-banner.png";
                                    }}
                                  />
                                  <CardContent>
                                    <ServiceText>
                                      {subcategory.name}
                                    </ServiceText>
                                  </CardContent>
                                </Card>
                              </SliderCard>
                            ))
                          )
                      ) : (
                        <div>No categories available</div>
                      )}
                    </Slider>
                  </Box>
                </Box>
                {/*  Health & Fitness*/}
                <Box sx={{ marginTop: "20px" }}>
                  <Typography
                    sx={{
                      fontSize: "42px",
                      font: "normal normal medium Poppins",
                      fontWeight: "600",
                      color: "#000000",
                      marginBottom: "20px",
                    }}
                  >
                    {Array.isArray(categories) && categories.length > 2
                      ? categories[2].name
                      : "No category name available"}
                  </Typography>
                  <Grid container spacing={3}>
                    {Array.isArray(categories) && categories.length > 0 ? (
                      categories
                        .filter((category) => category.position === 3)
                        .map((category) =>
                          category.subCategory &&
                          category.subCategory.length > 0 ? (
                            category.subCategory.map((subCategory) => (
                              <Grid
                                item
                                xs={3}
                                sm={6}
                                md={3}
                                key={subCategory.id}
                              >
                                <Card
                                  sx={{
                                    textAlign: "center",
                                    boxShadow: "none",
                                  }}
                                  onClick={() =>
                                    handleServiceClick(subCategory)
                                  }
                                >
                                  <CardMedia
                                    component="img"
                                    image={subCategory.image}
                                    alt={subCategory.name}
                                    sx={{
                                      height: "100px",
                                      borderRadius: "8px",
                                    }}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "./assets/images/home-banner.png";
                                    }}
                                  />
                                  <Typography
                                    sx={{ marginTop: "10px", fontSize: "12px" }}
                                  >
                                    {subCategory.name}{" "}
                                  </Typography>
                                </Card>
                              </Grid>
                            ))
                          ) : (
                            <Typography key={category.id}>
                              No subcategories available.
                            </Typography> // If no subcategories
                          )
                        )
                    ) : (
                      <Typography>No categories available.</Typography> // If no categories
                    )}
                  </Grid>
                </Box>
                {/*  Beauty and Lifestyle */}
                <Box sx={{ marginTop: "20px" }}>
                  <Typography
                    sx={{
                      fontSize: "42px",
                      font: "normal normal medium Poppins",
                      fontWeight: "600",
                      color: "#000000",
                      marginBottom: "20px",
                    }}
                  >
                    {Array.isArray(categories) && categories.length > 0
                      ? categories.find((category) => category.position === 5)
                          ?.name || "No category name available"
                      : "No category name available"}
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid container item xs={12} md={9} spacing={3}>
                      {/* First row - One large image and one smaller image */}
                      <Grid item xs={8} sm={8} md={8}>
                        {categories.length > 0 &&
                          categories.find((category) => category.position === 5)
                            ?.subCategory?.[0] && (
                            <StyledCard
                              onClick={() =>
                                handleServiceClick(
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[0]
                                )
                              }
                            >
                              <StyledCardMedia
                                component="img"
                                image={
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[0]?.image
                                } // Subcategory image
                                alt={
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[0]?.name
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "./assets/images/home-banner.png";
                                }}
                              />
                              <StyledCardContent>
                                <Typography variant="h6" component="div">
                                  {
                                    categories.find(
                                      (category) => category.position === 5
                                    )?.subCategory?.[0]?.name
                                  }
                                </Typography>
                              </StyledCardContent>
                            </StyledCard>
                          )}
                      </Grid>

                      <Grid item xs={4} sm={4} md={4}>
                        {categories.length > 0 &&
                          categories.find((category) => category.position === 5)
                            ?.subCategory?.[1] && (
                            <StyledCard
                              onClick={() =>
                                handleServiceClick(
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[1]
                                )
                              }
                            >
                              <StyledCardMedia
                                component="img"
                                image={
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[1]?.image
                                } // Subcategory image
                                alt={
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[1]?.name
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "./assets/images/home-banner.png";
                                }}
                              />
                              <StyledCardContent>
                                <Typography variant="h6" component="div">
                                  {
                                    categories.find(
                                      (category) => category.position === 5
                                    )?.subCategory?.[1]?.name
                                  }
                                </Typography>
                              </StyledCardContent>
                            </StyledCard>
                          )}
                      </Grid>

                      {/* Row 2 - Two smaller images */}
                      <Grid item xs={4} sm={4} md={4}>
                        {categories.length > 0 &&
                          categories.find((category) => category.position === 5)
                            ?.subCategory?.[3] && (
                            <StyledCard
                              onClick={() =>
                                handleServiceClick(
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[3]
                                )
                              }
                            >
                              <StyledCardMedia
                                component="img"
                                image={
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[3]?.image
                                }
                                alt={
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[3]?.name
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "./assets/images/home-banner.png";
                                }}
                              />
                              <StyledCardContent>
                                <Typography variant="h6" component="div">
                                  {
                                    categories.find(
                                      (category) => category.position === 5
                                    )?.subCategory?.[3]?.name
                                  }
                                </Typography>
                              </StyledCardContent>
                            </StyledCard>
                          )}
                      </Grid>

                      <Grid item xs={8} sm={8} md={8}>
                        {categories.length > 0 &&
                          categories.find((category) => category.position === 5)
                            ?.subCategory?.[4] && (
                            <StyledCard
                              onClick={() =>
                                handleServiceClick(
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[4]
                                )
                              }
                            >
                              <StyledCardMedia
                                component="img"
                                image={
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[4]?.image
                                }
                                alt={
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[4]?.name
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "./assets/images/home-banner.png";
                                }}
                              />
                              <StyledCardContent>
                                <Typography variant="h6" component="div">
                                  {
                                    categories.find(
                                      (category) => category.position === 5
                                    )?.subCategory?.[4]?.name
                                  }
                                </Typography>
                              </StyledCardContent>
                            </StyledCard>
                          )}
                      </Grid>
                    </Grid>

                    {/* Full height image on the right */}
                    <Grid item xs={12} md={3}>
                      {categories.length > 0 &&
                        categories.find((category) => category.position === 5)
                          ?.subCategory?.[2] && (
                          <StyledCard
                            sx={{ height: "100%" }}
                            onClick={() =>
                              handleServiceClick(
                                categories.find(
                                  (category) => category.position === 5
                                )?.subCategory?.[2]
                              )
                            }
                          >
                            <FullHeightCardMedia
                              component="img"
                              image={
                                categories.find(
                                  (category) => category.position === 5
                                )?.subCategory?.[2]?.image
                              }
                              alt={
                                categories.find(
                                  (category) => category.position === 5
                                )?.subCategory?.[2]?.name
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "./assets/images/home-banner.png";
                              }}
                            />
                            <StyledCardContent>
                              <Typography variant="h6" component="div">
                                {
                                  categories.find(
                                    (category) => category.position === 5
                                  )?.subCategory?.[2]?.name
                                }
                              </Typography>
                            </StyledCardContent>
                          </StyledCard>
                        )}
                    </Grid>
                  </Grid>
                </Box>
                {/*  Construction Material Supplier */}
                <Box sx={{ marginTop: "20px" }}>
                  <Typography
                    sx={{
                      fontSize: "42px",
                      font: "normal normal medium Poppins",
                      fontWeight: "600",
                      color: "#000000",
                      marginBottom: "20px",
                    }}
                  >
                    {Array.isArray(categories) && categories.length > 0
                      ? categories.find((category) => category.position === 7)
                          ?.name || "No category name available"
                      : "No category name available"}
                  </Typography>
                  <Box>
                    <Slider {...slide}>
                      {Array.isArray(categories) && categories.length > 0 ? (
                        categories
                          .filter((category) => category.position === 7)
                          .map((category) =>
                            category.subCategory.map((subcategory) => (
                              <SliderCard key={subcategory.id}>
                                <Card
                                  style={{
                                    marginRight: "16px",
                                    height: "200px",
                                    boxShadow: "none",
                                    border: "none",
                                  }}
                                  onClick={() =>
                                    handleServiceClick(subcategory)
                                  }
                                >
                                  <CardMedia
                                    component="img"
                                    sx={{
                                      height: "140px",
                                      objectFit: "cover",
                                      border: "none",
                                    }}
                                    image={subcategory.image}
                                    alt={subcategory.name}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "./assets/images/home-banner.png";
                                    }}
                                  />
                                  <CardContent>
                                    <ServiceText>
                                      {subcategory.name}
                                    </ServiceText>
                                  </CardContent>
                                </Card>
                              </SliderCard>
                            ))
                          )
                      ) : (
                        <div>No categories available</div>
                      )}
                    </Slider>
                  </Box>
                </Box>
                {/*  Borewell & Pump Repair*/}
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography
                    sx={{
                      fontSize: "42px",
                      font: "normal normal medium Poppins",
                      fontWeight: "600",
                      color: "#000000",
                      marginBottom: "20px",
                    }}
                  >
                    {Array.isArray(categories) && categories.length > 0
                      ? categories.find((category) => category.position === 6)
                          ?.name || "No category name available"
                      : "No category name available"}
                  </Typography>
                  <Grid container spacing={3} className="borewell-grid">
                    {Array.isArray(categories) && categories.length > 0 ? (
                      categories
                        .filter((category) => category.position === 6) // Filter by position 6
                        .map((category) =>
                          category.subCategory.map((subcategory) => (
                            <Grid
                              item
                              xs={4}
                              sm={6}
                              md={4}
                              key={subcategory.id}
                            >
                              <BorewellCard
                                onClick={() => handleServiceClick(subcategory)}
                              >
                                <BorewellCardMedia
                                  component="img"
                                  image={subcategory.image}
                                  alt={subcategory.name}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "./assets/images/home-banner.png";
                                  }}
                                />
                                <BorewellServiceName variant="body1">
                                  {subcategory.name}
                                </BorewellServiceName>
                              </BorewellCard>
                            </Grid>
                          ))
                        )
                    ) : (
                      <div>No categories available</div>
                    )}
                  </Grid>
                </Box>
              </>
            {/* <Box className="borewell-container">
              <Typography
                sx={{
                  fontSize: "42px",
                  font: "normal normal medium Poppins",
                  fontWeight: "600",
                  color: "#000000",
                  marginBottom: "20px",
                }}
              >
                Event Management
              </Typography>
              <ProfessionalCardContainer container>
                {professionals.map((professional, index) => (
                  <ProfessionalCard key={index}>
                    <ImageOverlay>
                      <OverlayImage
                        src={professional.image}
                        alt={professional.name}
                      />
                      <OverlayGradient />
                    </ImageOverlay>
                    <ProfessionalCardTitle>
                      {professional.name}
                    </ProfessionalCardTitle>
                  </ProfessionalCard>
                ))}
              </ProfessionalCardContainer>
            </Box> */}
          </LeftSectionContainer>
        </Grid>
        {isDesktop && (
          <RightSection
            showContactPopup={showContactPopup}
            setShowContactPopup={setShowContactPopup}
            handleContactSubmit={handleContactSubmit}
            showOTPPopup={showOTPPopup}
            setShowOTPPopup={setShowOTPPopup}
            otp={otp}
            setOtp={setOtp}
            handleOtpSubmit={handleOtpSubmit}
            showSuccessPopup={showSuccessPopup}
            setShowSuccessPopup={setShowSuccessPopup}
          />
        )}
      </Grid>

      <Box
        sx={{
          position: "relative",
          padding: { xs: "15px", md: "20px 100px" },
          backgroundColor: "#f7f7f7",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ marginTop: "40px", marginBottom: "20px", fontWeight: "bold" }}
        >
          Popular Cities
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            color: "#666666",
          }}
        >
          {cities.map((city, index) => (
            <React.Fragment key={city.id || index}>
              <Typography
                component="span"
                sx={{
                  fontSize: "13px",
                  cursor: "pointer",
                  "&:hover": { color: "#21608F", textDecoration: "underline" },
                }}
                onClick={() => handleCityClick(city)}
              >
                {city.name}
              </Typography>
              {index < cities.length - 1 && (
                <Typography
                  component="span"
                  sx={{ fontSize: "13px", color: "#666666", marginX: "4px" }}
                >
                  |
                </Typography>
              )}
            </React.Fragment>
          ))}
        </Box>
      </Box>
      <HomeFooter />
      {isMobile && <BottomTabs activeTab="home" />}
    </ThemeProvider>
  );
};

export default Services;
