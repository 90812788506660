import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../components/UI/Header";
import { COLORS } from "../../../styles/Theme";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { AllMessageBtn, InfoBox, InfoBoxContainer } from "./HelpSupport.style";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import { useNavigate } from "react-router-dom";
import { getAllComplaintsApi } from "../../../services/api_calls/auth/AuthApi";

function HelpSupport() {
  const navigate = useNavigate();
  const [messageCount, setMessageCount] = useState(0);
  useEffect(() => {
    fetchMessageCount();
  }, []);

  const fetchMessageCount = async () => {
    try {
      const response = await getAllComplaintsApi();
      if (response.success) {
        setMessageCount(response.data.length);
      } else {
        console.error("Failed to fetch messages:", response.message);
      }
    } catch (error) {
      console.error("Error fetching message count:", error);
    }
  };
  const handleClick = (route) => {
    navigate(route);
  };
  const handleEmailClick = () => {
    window.location.href = "mailto:info@localpro.online";
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:+919876543210";
  };
  return (
    <Box>
      <Header title="Support" />

      <Box m="40px 16px 16px 16px">
        <Typography
          variant="h3"
          fontSize={16}
          fontWeight={500}
          textAlign="center"
        >
          How can we help you?
        </Typography>

        <Typography
          variant="body2"
          fontSize={12}
          fontWeight={500}
          lineHeight="27px"
          textAlign="center"
        >
          Complaints / Issues
        </Typography>
      </Box>

      <Box m={2}>
        <HelpMenuItem
          icon="/./assets/images/profile-icons/person.png"
          label="Customer Complaint"
          onClick={() => {
            handleClick("/helpCenter/account");
          }}
        />

        <HelpMenuItem
          icon="/./assets/images/profile-icons/person.png"
          label="Payment & Local Pro Credits"
          onClick={() => {
            handleClick("/helpCenter/payments");
          }}
        />
        <HelpMenuItem
          icon="/./assets/images/profile-icons/person.png"
          label="Enquiry"
          onClick={() => {
            handleClick("/helpCenter/loginIssue");
          }}
        />
        <HelpMenuItem
          icon="/./assets/images/profile-icons/person.png"
          label="Pro Complaints"
          onClick={() => {
            handleClick("/helpCenter/proComplaints");
          }}
        />
        <HelpMenuItem
          icon="/./assets/images/profile-icons/person.png"
          label="Location"
          onClick={() => {
            handleClick("/helpCenter/location");
          }}
        />
        <HelpMenuItem
          icon="/./assets/images/profile-icons/person.png"
          label="Business Listing"
          onClick={() => {
            handleClick("/helpCenter/businessListing");
          }}
        />
        {/* <HelpMenuItem
          icon="/./assets/images/profile-icons/person.png"
          label="Login Issue"
          onClick={() => {
            handleClick("/helpCenter/loginIssue");
          }}
        /> */}

        <Box display="flex" alignItems="center" justifyContent="center" m={1.5}>
          <AllMessageBtn
            onClick={() => {
              handleClick("/helpCenter/allMessages");
            }}
            endIcon={
              <KeyboardArrowRightRoundedIcon
                sx={{ color: COLORS.blackShade1 }}
              />
            }
          >
            My All Messages ({messageCount})
          </AllMessageBtn>
        </Box>

        <InfoBoxContainer>
          <InfoBox
            bgcolor={COLORS.lightOrange}
            onClick={handleEmailClick}
            sx={{ cursor: "pointer" }}
          >
            <EmailRoundedIcon fontSize="large" color="primary" />

            <Typography
              variant="body2"
              fontSize={13}
              fontWeight={500}
              lineHeight="27px"
              textAlign="center"
            >
              info@localpro.online
            </Typography>
          </InfoBox>

          <InfoBox
            bgcolor={COLORS.lightBlue}
            onClick={handlePhoneClick}
            sx={{ cursor: "pointer" }}
          >
            <PhoneRoundedIcon fontSize="large" color="secondary" />

            <Typography
              variant="body2"
              fontSize={13}
              fontWeight={500}
              lineHeight="27px"
              textAlign="center"
            >
              +91 9876543210
            </Typography>
          </InfoBox>
        </InfoBoxContainer>
      </Box>
    </Box>
  );
}

export default HelpSupport;

const HelpMenuItem = ({ icon, label, infoText, onClick }) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        borderBottom: "1px solid " + COLORS.greyShade3,
        p: { xs: "auto", md: "10px" },
      }}
    >
      <ListItemIcon>
        <Box
          component="img"
          src={icon}
          sx={{ width: 20, height: 20, objectFit: "contain" }}
        />
      </ListItemIcon>

      <ListItemText>
        <Typography variant="body2" fontSize={14}>
          {label}
        </Typography>
      </ListItemText>

      <Box display="flex" alignItems="center">
        {infoText && (
          <Typography
            variant="body2"
            fontSize={10}
            fontWeight={500}
            color={COLORS.primaryColor}
          >
            {infoText}
          </Typography>
        )}
        <KeyboardArrowRightRoundedIcon sx={{ color: COLORS.greyShade3 }} />
      </Box>
    </MenuItem>
  );
};
